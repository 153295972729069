import * as React from 'react'
import {
  Box, Button, FormControl, InputLabel, MenuItem,
  Paper, Select, TextField
} from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { useAxios } from '../../utils/hooks'
import { useCallback, useEffect, useState } from 'react'
import Resizer from 'react-image-file-resizer'
import { setErrorSnackbar, setIsLoading, setSuccessSnackbar } from '../mainReducer'
import Grid from '@material-ui/core/Grid'
import TemplateList from './TemplateList'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { CheckCircle } from '@material-ui/icons'

export default function AdvancedSettings ({ subscriberId, currentRole }) {

  const dispatch = useDispatch()
  const axiosInstance = useAxios()
  const [currentImage, setCurrentImage] = useState(null)
  const [image, setImage] = useState(null)
  const [content, setContent] = useState({})

  useEffect(() => {
    const getContent = async () => {
      dispatch(setIsLoading(true))
      if (!!axiosInstance.current) {
        axiosInstance.current.get(`/admin/subscriber/${subscriberId}/_settings`)
          .then((resp) => {
            if (resp) {
              const { data } = resp
              if (data && data.content) {
                setContent(data.content)
                if (data.content.data) {
                  setCurrentImage(data.content.data)
                }
              }
            }
          })
          .finally(() => {
            dispatch(setIsLoading(false))
          })
      }
    }

    if (subscriberId) getContent()
  }, [axiosInstance, dispatch, subscriberId])

  const postImage = useCallback(() => {
    dispatch(setIsLoading(true))
    if (!!axiosInstance.current) {
      axiosInstance.current.post(`/admin/subscriber/${subscriberId}/_settings`, { data: image })
        .then(() => {
          setCurrentImage(image)
          setImage(null)
          setContent({
            ...content,
            image: null
          })
          dispatch(setSuccessSnackbar('Logo je sačuvan'))
        })
        .catch(err => {
          console.log(err)
          dispatch(setErrorSnackbar('Greška'))
        })
        .finally(() => {
          dispatch(setIsLoading(false))
        })
    }
  }, [axiosInstance, dispatch, subscriberId, image])

  const updateValue = useCallback((property, value) => {
    dispatch(setIsLoading(true))
    if (!!axiosInstance.current) {
      axiosInstance.current.post(`/admin/subscriber/${subscriberId}/_settings`, { [property]: value })
        .then(() => {
          dispatch(setSuccessSnackbar('Izmjena je sačuvana'))
        })
        .catch(err => {
          console.log(err)
          dispatch(setErrorSnackbar('Greška'))
        })
        .finally(() => {
          dispatch(setIsLoading(false))
        })
    }
  }, [axiosInstance, dispatch, subscriberId])

  const deleteImage = useCallback(() => {
    dispatch(setIsLoading(true))
    if (!!axiosInstance.current) {
      axiosInstance.current.post(`/admin/subscriber/${subscriberId}/_settings`, { data: null })
        .then(() => {
          setCurrentImage(null)
          dispatch(setSuccessSnackbar('Logo je obrisan'))
        })
        .catch(err => {
          console.log(err)
          dispatch(setErrorSnackbar('Greška'))
        })
        .finally(() => {
          dispatch(setIsLoading(false))
        })
    }
  }, [axiosInstance, dispatch, subscriberId])

  const resizeFile = (file) => new Promise(resolve => {
    Resizer.imageFileResizer(file, 1600, 300, 'PNG', 100, 0,
      uri => {
        resolve(uri)
      },
      'base64'
    )
  })

  const onImageChange = async (event) => {
    if (event.target.files && event.target.files[0]) {
      const img = event.target.files[0]
      const resizedImg = await resizeFile(img)
      setImage(resizedImg)
    }
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={4}>
          <Box p={1}>
            <Paper elevation={3} m={2}>
              <Box p={2}>
                <fieldset>
                  <legend>Kreiranje fakture</legend>
                  <Grid item xs>
                    <FormControlLabel
                      control={<Checkbox
                        color="secondary"
                        name="useInvoiceItemNote"
                        checked={!!content.useInvoiceItemNote}
                        onChange={() => {
                          updateValue('useInvoiceItemNote', !content.useInvoiceItemNote)
                          setContent({
                            ...content,
                            useInvoiceItemNote: !content.useInvoiceItemNote
                          })
                        }}
                      />}
                      label="Koristi napomene na nivou stavke"
                    />
                  </Grid>
                  <Grid item xs>
                    <FormControlLabel
                      control={<Checkbox
                        color="secondary"
                        name="useExemptFromVAT"
                        checked={!!content.useExemptFromVAT}
                        onChange={() => {
                          updateValue('useExemptFromVAT', !content.useExemptFromVAT)
                          setContent({
                            ...content,
                            useExemptFromVAT: !content.useExemptFromVAT
                          })
                        }}
                      />}
                      label="Koristi oslobadjanje od PDV-a"
                    />
                  </Grid>
                  <Grid item xs>
                    <FormControlLabel
                      control={<Checkbox
                        color="secondary"
                        name="canChangeInvoiceItemTotal"
                        checked={!!content.canChangeInvoiceItemTotal}
                        onChange={() => {
                          updateValue('canChangeInvoiceItemTotal', !content.canChangeInvoiceItemTotal)
                          setContent({
                            ...content,
                            canChangeInvoiceItemTotal: !content.canChangeInvoiceItemTotal
                          })
                        }}
                      />}
                      label="Može da mijenja ukupni iznos na nivou stavke"
                    />
                  </Grid>
                  <Grid item xs>
                    <FormControlLabel
                      control={<Checkbox
                        color="secondary"
                        name="useInvoiceSupplyDate"
                        checked={!!content.useInvoiceSupplyDate}
                        onChange={() => {
                          updateValue('useInvoiceSupplyDate', !content.useInvoiceSupplyDate)
                          setContent({
                            ...content,
                            useInvoiceSupplyDate: !content.useInvoiceSupplyDate
                          })
                        }}
                      />}
                      label="Koristi datum prometa"
                    />
                  </Grid>
                </fieldset>
                <br/>
                <fieldset>
                  <legend>Štampa fakture</legend>
                  <fieldset>
                    <legend>Logo</legend>
                    <Grid item xs>
                      <FormControlLabel
                        control={<Checkbox
                          color="secondary"
                          name="excludeInvoiceHeader"
                          checked={!!content.excludeInvoiceHeader}
                          onChange={() => {
                            updateValue('excludeInvoiceHeader', !content.excludeInvoiceHeader)
                            setContent({
                              ...content,
                              excludeInvoiceHeader: !content.excludeInvoiceHeader
                            })
                          }}
                        />}
                        label="Ne štampaj zaglavlje fakture"
                      />
                    </Grid>
                    <Grid item xs>
                      <FormControlLabel
                        control={<Checkbox
                          color="secondary"
                          name="logoFullHeader"
                          checked={!!content.logoFullHeader}
                          onChange={() => {
                            updateValue('logoFullHeader', !content.logoFullHeader)
                            setContent({
                              ...content,
                              logoFullHeader: !content.logoFullHeader
                            })
                          }}
                        />}
                        label="Logo je header"
                      />
                    </Grid>
                  </fieldset>
                  <br/>
                  <fieldset>
                    <legend>Labele</legend>
                    <Grid item xs>
                      <TextField
                        id="standard-name"
                        label="Labela za referentno polje"
                        variant={'outlined'}
                        size={'small'}
                        style={{ marginTop: 20 }}
                        value={content.contractLabel || ''}
                        onChange={(event) => setContent({
                          ...content,
                          contractLabel: event.target.value
                        })}
                        InputProps={{
                          endAdornment: <CheckCircle
                            onClick={() => updateValue('contractLabel', content.contractLabel)}/>
                        }}
                      />
                    </Grid>
                    <Grid item xs>
                      <TextField
                        id="signTextLeft"
                        label="Lijevi potpis"
                        variant={'outlined'}
                        size={'small'}
                        style={{ marginTop: 20 }}
                        value={content.signTextLeft || ''}
                        onChange={(event) => setContent({
                          ...content,
                          signTextLeft: event.target.value
                        })}
                        InputProps={{
                          endAdornment: <CheckCircle onClick={() => updateValue('signTextLeft', content.signTextLeft)}/>
                        }}
                      />
                    </Grid>
                    <Grid item xs>
                      <TextField
                        id="signTextRight"
                        label="Desni potpis"
                        variant={'outlined'}
                        style={{ marginTop: 20 }}
                        size={'small'}
                        value={content.signTextRight || ''}
                        onChange={(event) => setContent({
                          ...content,
                          signTextRight: event.target.value
                        })}
                        InputProps={{
                          endAdornment: <CheckCircle
                            onClick={() => updateValue('signTextRight', content.signTextRight)}/>
                        }}
                      />
                    </Grid>
                  </fieldset>
                  <br/>
                  <fieldset>
                    <legend>Jezik</legend>
                    <Grid item xs>
                      <FormControlLabel
                        control={<Checkbox
                          color="secondary"
                          name="useMultiLanguageInvoices"
                          checked={!!content.useMultiLanguageInvoices}
                          onChange={() => {
                            updateValue('useMultiLanguageInvoices', !content.useMultiLanguageInvoices)
                            setContent({
                              ...content,
                              useMultiLanguageInvoices: !content.useMultiLanguageInvoices
                            })
                          }}
                        />}
                        label="Štampa višejezičnih faktura"
                      />
                    </Grid>
                    <Grid item xs>
                      <FormControl style={{ marginTop: 20, minWidth: 200 }} size="small" variant="outlined">
                        <InputLabel htmlFor="outlined-age-native-simple">Jezik</InputLabel>
                        <Select
                          labelId="locale"
                          name="locale"
                          id="locale"
                          value={content.locale || ''}
                          label="Jezik"
                          onChange={event => {
                            setContent({
                              ...content,
                              locale: event.target.value
                            })
                            updateValue('locale', event.target.value)
                          }}
                        >
                          <MenuItem value={''}/>
                          <MenuItem value={'me'}>Latinica</MenuItem>
                          <MenuItem value={'sr'}>Ћирилица</MenuItem>
                          <MenuItem value={'en'}>Engleski</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </fieldset>
                  <br/>
                  <fieldset>
                    <legend>Ostalo</legend>
                    <Grid item xs>
                      <FormControlLabel
                        control={<Checkbox
                          color="secondary"
                          name="priceWoVATTwoDecimals"
                          checked={!!content.priceWoVATTwoDecimals}
                          onChange={() => {
                            updateValue('priceWoVATTwoDecimals', !content.priceWoVATTwoDecimals)
                            setContent({
                              ...content,
                              priceWoVATTwoDecimals: !content.priceWoVATTwoDecimals
                            })
                          }}
                        />}
                        label="Cijena bez PDV-a na 2 decimale"
                      />
                    </Grid>
                  </fieldset>
                </fieldset>
              </Box>
            </Paper>
          </Box>
          <br/>
        </Grid>
        <Grid item xs={12} md={8}>

          <Box p={1}>
            <Paper elevation={3} m={2}>
              <Box p={2}>
                <Grid container>
                  {currentImage && <Grid item sm={12}>
                    <Box p={2}>
                      <img src={currentImage} alt={''}/>
                      <h3>Slika</h3>
                      <input type="file" name="image" onChange={onImageChange}/>
                    </Box>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => deleteImage()}
                    >
                      Obriši
                    </Button>
                  </Grid>}
                  <Grid item sm={12}>
                    <Box p={2}>
                      <img src={image} alt={''}/>
                      <h3>Nova slika</h3>
                      <input type="file" name="image" onChange={onImageChange}/>
                    </Box>
                    {image && <Button
                      variant="contained"
                      color="primary"
                      onClick={() => postImage()}
                    >
                      Sačuvaj
                    </Button>}
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Box>
          <br/>
        </Grid>
        {currentRole === 'ADMIN' && <Grid item xs={12}>
          <Paper elevation={3} m={2}>
            <TemplateList content={content} setContent={setContent} subscriberId={subscriberId}/>
          </Paper>
        </Grid>}
      </Grid>
    </>
  )
}
