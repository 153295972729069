import React, {useEffect, useState} from 'react';
import {Button, Grid} from "@material-ui/core";
import Tile from "./Tile";
import {Business, Cancel, CheckCircle, Store} from "@material-ui/icons";
import {getCurrentRole, getUser, setIsLoading, setSuccessSnackbar} from "../mainReducer";
import {useDispatch, useSelector} from "react-redux";
import {useAxios} from "../../utils/hooks";
import {useApi} from "../../api/api";
import {createTaxRateUpdateMessage} from "../../utils/helpers";

function DashboardSubscribersStats({theme, subscribersFilter}) {

    const dispatch = useDispatch()
    const axiosInstance = useAxios()
    const api = useApi()
    const currentRole = useSelector(getCurrentRole)
    const [subscribersData, setSubscribersData] = useState()
    const user = useSelector(getUser)
    const [updatingTaxRate, setUpdatingTaxRate] = useState(false)

    useEffect(() => {
        if (!axiosInstance.current || !['ADMIN', 'SELLER'].includes(currentRole)) {
            return;
        }

        dispatch(setIsLoading(true))

        let sellerId

        switch (currentRole) {
            case 'ADMIN':
                sellerId = subscribersFilter.seller
                break
            case 'SELLER':
                sellerId = user.sub
                break
            default:

        }

        const getAdminReport = async () => {
            if (!!axiosInstance.current) {
                await axiosInstance.current.get(`/report/subscribers_summary${sellerId ? '?sellerId=' + sellerId : ''}`)
                    .then(async ({ data }) => {
                        setSubscribersData(data)
                    })
            }
        }

        getAdminReport().finally(() => dispatch(setIsLoading(false)));

    }, [api, axiosInstance, currentRole, subscribersFilter.seller])

    const updateTaxRate = async () => {
        if (!!axiosInstance.current) {
            setUpdatingTaxRate(true);
            await axiosInstance.current.post(`/inventory/tcr/update-all-tax-rates`)
              .then(async ({ data }) => {
                  dispatch(setSuccessSnackbar(createTaxRateUpdateMessage(data)));
              })
              .catch((e) => {
                  console.error(e);
                  dispatch(setSuccessSnackbar("Greška prilikom ažuriranja stopa PDV-a"));
              })
              .finally(() => setUpdatingTaxRate(false));
        }
    }

    if (!subscribersData) return null;

    return (
        <Grid container spacing={3}>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
                <Tile text={'AKTIVNE FIRME'} value={subscribersData.subscriberCount}
                      color={theme.palette.primary.main} doNotRound
                      icon={<Business/>}
                />
            </Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
                <Tile text={'U sistemu PDV-a'}
                      value={subscribersData.aggResult.isIssuerInVAT.buckets.find(b => b.key_as_string === "true")?.doc_count || 0}
                      color={theme.palette.primary.main} doNotRound
                      icon={<CheckCircle/>}
                />
            </Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
                <Tile text={'Van sistema PDV-a'}
                      value={subscribersData.aggResult.isIssuerInVAT.buckets.find(b => b.key_as_string === "false")?.doc_count || 0}
                      color={theme.palette.primary.main} doNotRound
                      icon={<Cancel/>}
                />
            </Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
                <Tile text={'Aktivnih ENU'} value={subscribersData.tcrCount}
                      color={theme.palette.primary.main} doNotRound
                      icon={<Store/>}
                />
            </Grid>
            {user?.isSuperAdmin ? <Grid item lg={3} sm={6} xl={3} xs={12}>
                <Button
                  color="primary"
                  disabled={updatingTaxRate}
                  onClick={updateTaxRate}>
                    Primijeni novu stopu PDV-a
                </Button>
            </Grid> : null}
        </Grid>
    );
}

export default DashboardSubscribersStats;