import React, { useEffect, useState } from 'react'
import {
    Box,
    IconButton,
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import {
    getAdminDashboardData,
    getCurrentRole,
    getUser,
    setAdminDashboardData,
    setIsLoading,
} from '../mainReducer'
import {
    Replay
} from '@material-ui/icons'
import MUIDataTable from 'mui-datatables'
import Typography from '@material-ui/core/Typography'
import { Pagination } from '@material-ui/lab'
import moment from 'moment-timezone'
import {
    calculateDateFrom,
    useInvoicesListStyles,
} from '../../utils/helpers'
import clsx from 'clsx'
import { useApi } from "../../api/api"
import { useAxios } from "../../utils/hooks"
import DashboardDepositsSummary from "./DashboardDepositsSummary";
// import DashboardDepositsSummary from "./DashboardDepositsSummary"

const rowsPerPage = 10

const DashboardDeposits = ({ dashboardReportPeriod, subscribersFilter }) => {
    const dispatch = useDispatch()
    const api = useApi()
    const axiosInstance = useAxios()
    const adminDashboardData = useSelector(getAdminDashboardData)
    const currentRole = useSelector(getCurrentRole)
    const user = useSelector(getUser)
    const [currentDepositType, setCurrentDepositType] = useState()
    const [deposits, setDeposits] = useState([])
    const [depositsCount, setDepositsCount] = useState(0)
    const [from, setFrom] = useState(0)
    const classes = useInvoicesListStyles()

    const columns = [
        {
            name: 'tcr',
            label: 'Klijent',
            options: {
                setCellProps: () => ({ style: { width: 400 } }),
            },
        },
        {
            name: 'operation',
            label: 'Tip',
            options: {
                setCellProps: () => ({ style: { width: 100 } }),
            },
        },
        {
            name: 'cacheAmt',
            label: 'Iznos',
            options: {
                setCellProps: () => ({ style: { width: 100 } }),
                customBodyRender: value => value.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})
            },
        },
        {
            name: 'changeDateTime',
            label: 'Vrijeme',
            options: {
                customBodyRender: value => moment(value).format('DD/MM/YYYY HH:mm'),
                setCellProps: () => ({ style: { width: 150 } }),
            },
        },
        {
            name: 'errorCode',
            label: 'Code',
            options: {
                display: ["SUCCESS", "IN_PROGRESS"].includes(currentDepositType) ? 'excluded' : 'true',
                setCellProps: () => ({ style: { width: 60 } }),
            },
        },
        {
            name: 'error',
            label: 'Greška',
            options: {
                display: ["SUCCESS", "IN_PROGRESS"].includes(currentDepositType) ? 'excluded' : 'true',
                setCellProps: () => ({ style: { width: 400 } }),
                customBodyRender: (data) => {return <code style={{display: 'inline-block', overflowY: 'scroll', height:60}}>{data}</code>}
            },
        },
        {
            name: 'id',
            label: ' ',
            options: {
                filter: false,
                sort: false,
                empty: true,
                width: 50,
                customBodyRender: (dataIndex) => {
                    const deposit = deposits.find(d => d.fullDeposit.header.uuid === dataIndex)
                    if (deposit && deposit.status === 'STALLED') {
                        return (
                            <IconButton
                                color="primary"
                                size="small"
                                component="span"
                                onClick={() => {
                                    // setDepositToRetry logic will be added later
                                }}>
                                <Replay/>
                            </IconButton>
                        )
                    } else {
                        return null
                    }
                },
                setCellProps: () => ({ style: { width: 50 } }),
            }
        }
    ]

    const options = {
        filter: false,
        search: false,
        download: false,
        sort: false,
        pagination: false,
        print: false,
        viewColumns: false,
        responsive: 'standard',
        serverSide: true,
        count: depositsCount,
        rowsPerPage,
        rowsPerPageOptions: [],
        sortOrder: {},
        selectableRows: 'none',
        selectableRowsHeader: false,
        selectableRowsHideCheckboxes: true,
        setRowProps: (rowData) => {
            return {
                className: clsx({
                    [classes.SuccessRow]: rowData[5] === 'SUCCESS',
                    [classes.ErrorRow]: rowData[5] === 'ERROR',
                }),
            }
        },
        textLabels: {
            body: {
                noMatch: 'Nema podataka',
                toolTip: 'Sortiraj',
                columnHeaderTooltip: column => `Sort for ${column.label}`
            },
            pagination: {
                next: 'Sledeća strana',
                previous: 'Prethodna strana',
                rowsPerPage: 'Redova po strani:',
                displayRows: 'od',
            },
            toolbar: {
                search: 'Pretraga',
                downloadCsv: 'Download CSV',
                print: 'Print',
                viewColumns: 'View Columns',
                filterTable: 'Filter Table',
            },
            filter: {
                all: 'All',
                title: 'FILTERS',
                reset: 'RESET',
            },
            viewColumns: {
                title: 'Show Columns',
                titleAria: 'Show/Hide Table Columns',
            },
            selectedRows: {
                text: 'row(s) selected',
                delete: 'Delete',
                deleteAria: 'Delete Selected Rows',
            },
        },
        onTableChange: (action, tableState) => {
            switch (action) {
                case 'changePage':
                    setFrom(tableState.page * rowsPerPage)
                    break
                case 'search':
                    setFrom(tableState.page * rowsPerPage)
                    break
                default:
            }
        },
    }

    useEffect(() => {
        if (!axiosInstance.current || !['ADMIN', 'SELLER'].includes(currentRole)) {
            return;
        }

        dispatch(setIsLoading(true))

        const dateTo = moment().endOf('day').valueOf()
        const dateFrom = calculateDateFrom(dashboardReportPeriod)
        const dateFromUrl = encodeURIComponent(moment(dateFrom).tz('Europe/Podgorica').format())
        const dateToUrl = encodeURIComponent(moment(dateTo).tz('Europe/Podgorica').format())

        let sellerId

        switch (currentRole) {
            case 'ADMIN':
                sellerId = subscribersFilter.seller
                break
            case 'SELLER':
                sellerId = user.sub
                break
            default:
        }

        const getAdminReport = async () => {
            if (!!axiosInstance.current) {
                const adminDashData = {}
                await axiosInstance.current.get(`/report/deposit_summary?dateFrom=${dateFromUrl}&dateTo=${dateToUrl}${sellerId ? '&sellerId=' + sellerId : ''}`)
                    .then(({data}) => {
                        adminDashData.deposits = data.aggregations && data.aggregations.byStatus && data.aggregations.byStatus.buckets.length > 0 && data.aggregations.byStatus.buckets.reduce((map, obj) => {
                            map[obj.key] = obj.doc_count
                            return map
                        }, {})
                        if (data.aggregations && data.aggregations.totalAmount && data.aggregations.totalAmount.value !== undefined) {
                            adminDashData.totalAmount = Number(data.aggregations.totalAmount.value)
                        }
                        dispatch(setAdminDashboardData(adminDashData))
                    })
            }
        }

        getAdminReport().then(() => {
            api.getDeposits(from, 10, currentDepositType, dateFromUrl, dateToUrl, sellerId)
                .then(({ data }) => {
                    setDeposits(data.content.map(d => {
                        return {
                            id: d.header.uuid,
                            tcr: d.subscriberName + ' - ' + d.enuName,
                            issuerTIN: d.cacheDeposit.issuerTIN,
                            operation: d.cacheDeposit.operation,
                            cacheAmt: d.cacheDeposit.cacheAmt,
                            changeDateTime: d.cacheDeposit.changeDateTime,
                            fullDeposit: d,
                            errorCode: d.response && d.response.error ? d.response.error.code : '',
                            error: d.response && d.response.error ? (d.response.error.faultString || d.response.error.msg)  : ''
                        }
                    }))
                    setDepositsCount(data.header.totalItems)
                })
                .finally(() => {
                    dispatch(setIsLoading(false))
                })
        })

    }, [api, axiosInstance, from, currentDepositType, currentRole, subscribersFilter.seller, user, dashboardReportPeriod])


    return (
        <>
            <DashboardDepositsSummary
                adminDashboardData={adminDashboardData}
                setCurrentDepositType={setCurrentDepositType}
                currentDepositType={currentDepositType}
            />

            <br/>
            <MUIDataTable
                title={
                    <Typography variant="h6">
                        Lista depozita
                    </Typography>
                }
                data={deposits}
                columns={columns}
                options={options}
            />
            <Box display="flex" justifyContent="center" p={1}>
                <Box p={1}>
                    <Pagination
                        color="primary"
                        showFirstButton
                        showLastButton
                        count={Math.ceil(depositsCount / rowsPerPage)}
                        page={Math.floor(from / rowsPerPage + 1)}
                        onChange={(e, v) => {
                            setFrom(rowsPerPage * (v - 1))
                        }}
                    />
                </Box>
                <Box p={2}>
                    Ukupno: {depositsCount}
                </Box>
            </Box>
        </>
    )
}

export default DashboardDeposits