import React from 'react';
import { Grid } from "@material-ui/core";
import Tile from "./Tile";
import { EuroSymbol, AddCircleOutline, RemoveCircleOutline } from "@material-ui/icons";
import { dashboardDepositStatusIcon } from "../../utils/helpers";
import { useSelector } from "react-redux";
import { getTheme } from "../Setting/settingsReducer";

function DashboardDepositsSummary({ adminDashboardData, setCurrentDepositType, currentDepositType }) {
    const theme = useSelector(getTheme);

    const statusTiles = adminDashboardData?.deposits ? Object.keys(adminDashboardData.deposits).map(key => ({
        key,
        doc_count: adminDashboardData.deposits[key]
    })) : [];

    return (
        <Grid container spacing={3}>
            {statusTiles.map(status => (
                <Grid key={status.key} item lg={3} sm={6} xl={2} xs={12} md={4}>
                    <Tile
                        text={status.key}
                        value={status.doc_count}
                        color={theme.palette.primary.main}
                        decimalPlaces={0}
                        onClick={() => setCurrentDepositType(status.key)}
                        selected={status.key === currentDepositType}
                        icon={dashboardDepositStatusIcon(status.key)}
                    />
                </Grid>
            ))}
            {/*{operationTiles.map(operation => (
                <Grid key={operation.key} item lg={3} sm={6} xl={2} xs={12} md={4}>
                    <Tile
                        text={operation.key}
                        value={operation.doc_count}
                        color={theme.palette.secondary.main}
                        decimalPlaces={0}
                        icon={operation.key === 'INITIAL' ? <AddCircleOutline /> : <RemoveCircleOutline />}
                    />
                </Grid>
            ))}*/}
            {/*<Grid item lg={3} sm={6} xl={2} xs={12} md={4}>
                <Tile
                    text={'Total Amount'}
                    value={adminDashboardData?.deposits?.totalCacheAmt?.value || 0}
                    color={theme.palette.primary.main}
                    icon={<EuroSymbol/>}
                />
            </Grid>*/}
        </Grid>
    );
}

export default DashboardDepositsSummary;