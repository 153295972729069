import * as React from 'react';
import {Box, Button} from "@material-ui/core";
import MaterialTable from "material-table";
import {v4 as uuidv4} from 'uuid';
import {localization, tableIcons} from "../../utils/material-table";

export default function TcrClients({clients, addClient, postClient, updateClient}) {

    const columns =
        [
            {
                field: 'name', title: 'Naziv', flex: 1,
                validate: rowData => !rowData || (!rowData.name || rowData.name.trim().length === 0) ? {
                    isValid: false,
                    helperText: 'Ne smije biti prazno polje'
                } : true
            },
            {
                field: 'idNumber', title: 'PIB', width: 150, type: 'numeric',
                validate: rowData => !rowData || (!rowData.idNumber || !rowData.idNumber.toString().match(/^\d{8}(?:\d{5})?$/)) ? {
                    isValid: false,
                    helperText: 'Mora imati 8 ili 13 cifara'
                } : true
            },
            {field: 'vatNumber', title: 'PDV', width: 150},
            {field: 'bankAccount', title: 'ZR', width: 150},
            {field: 'address', title: 'Adresa', width: 150},
            {field: 'city', title: 'Grad', width: 150},
            {field: 'phone', title: 'Tel.', width: 150},
            {
                field: 'country', title: 'Država', width: 150,
                initialEditValue: "MNE",
                lookup: {'MNE': 'Crna Gora'},
                validate: rowData => !rowData || (!rowData.country || rowData.country.toString().trim().length === 0) ? {
                    isValid: false,
                    helperText: 'Ne smije biti prazno polje'
                } : true
            },
            {
                field: "",
                headerName: "",
                disableClickEventBubbling: true,
                renderCell: (params) => {
                    const onClick = () => {
                    };
                    return <Button variant="outlined" color="primary" size="small" onClick={onClick}>Izmijeni</Button>;
                }
            }
        ];

    return (
        <Box style={{minHeight: 650, marginTop: 10}}>
            <MaterialTable
                title=""
                icons={tableIcons}
                columns={columns}
                data={clients.sort((a, b) => (a.createdAt > b.createdAt) ? 1 : -1)}
                options={{
                    rowStyle: rowData => {
                        if (!rowData.deleted) {
                            return {};
                        }
                        return {backgroundColor: '#ffbaba'};
                    },
                    pageSize: 10,
                    emptyRowsWhenPaging: true,
                    pageSizeOptions: [5, 10, 25],
                    actionsColumnIndex: -1
                }}
                localization={localization}
                editable={{
                    onRowAdd: newData =>
                        new Promise((resolve, reject) => {
                            const newClient = {
                                ...newData,
                                createdAt: new Date().valueOf(),
                                id: uuidv4()
                            };
                            postClient(newClient)
                                .then(() => {
                                    addClient(newClient);
                                    resolve();
                                })
                                .catch(() => {
                                    reject();
                                })
                        }),
                    onRowUpdate: (newData) =>
                        new Promise((resolve, reject) => {
                            const updatedClient = {
                                ...newData,
                                updatedAt: new Date().valueOf()
                            }
                            postClient(updatedClient)
                                .then(() => {
                                    updateClient(updatedClient);
                                    resolve();
                                }).catch(() => {
                                reject();
                            })
                        }),
                    onRowDelete: oldData =>
                        new Promise((resolve, reject) => {
                            const deletedClient = {
                                ...oldData,
                                deleted: true,
                                updatedAt: new Date().valueOf()
                            }
                            postClient(deletedClient).then(() => {
                                updateClient(deletedClient);
                                resolve();
                            }).catch(() => {
                                reject();
                            })
                            resolve();
                        }),
                }}
            />
        </Box>
    )
}