import React, { useEffect, useRef } from 'react'
import { Box, Button, Grid } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import { GoodsReceiptItemModel } from '../../models/GoodsReceiptItemModel'
import { Autocomplete } from '@material-ui/lab'
import { Check, Delete } from '@material-ui/icons'
import { decode } from 'html-entities'

export default function GoodsReceiptItem (
  {
    idx,
    goodsReceiptItemsIds,
    editingItem,
    inventory,
    editGoodsReceiptItem,
    deleteGoodsReceiptItem,
    setItem,
    focusFirstGoodsReceiptItem,
    setFocusFirstGoodsReceiptItem
  }) {

  const originalItem = useRef(editingItem)
  const nameRef = useRef()
  const quantityRef = useRef()
  const editButtonRef = useRef()

  useEffect(() => {
    if (idx === 0 && focusFirstGoodsReceiptItem) {
      nameRef.current.focus()
      setFocusFirstGoodsReceiptItem(false)
    }
  }, [focusFirstGoodsReceiptItem, idx])

  useEffect(() => {
    goodsReceiptItemsIds && goodsReceiptItemsIds.length > 0 && nameRef && nameRef.current && nameRef.current.focus()
  }, [])

  return (
    <Box>
      <Grid container spacing={2} style={{ border: '1px solid grey', borderRadius: '5px', marginBottom: '15px' }}>
        <Grid item xs>
          <Autocomplete
            size="small"
            options={inventory.filter(i => goodsReceiptItemsIds && !goodsReceiptItemsIds.includes(i.id))}
            getOptionSelected={(option, value) => option.value === value.value}
            getOptionLabel={option => decode(`${option && option.code ? option.code + ' - ' + option.name : ''}`)}
            style={{ width: '100%' }}
            onKeyPress={(e) => {
              if (e.key === 'Enter') quantityRef.current.select()
            }}
            filterOptions={(options, state) => {
              return options.filter(o => o.sku.toString().toLowerCase().startsWith(state.inputValue.toLowerCase()) || o.name.toString().toLowerCase().includes(state.inputValue.toLowerCase()))
            }}
            value={editingItem}
            onChange={(event, value) => {
              const product = value
              if (product) {
                const newItem = new GoodsReceiptItemModel(editingItem)
                newItem.update({
                  id: product.id,
                  code: product.sku,
                  name: product.name,
                  quantity: product.quantity
                })
                setItem(newItem, idx)
              }
            }}
            renderOption={option => <span>{option.sku} - {option.name}</span>}
            renderInput={(params) =>
              <TextField {...params}
                         label="Naziv"
                         inputRef={nameRef}
                         variant="outlined"/>}/>

        </Grid>
        <Grid item xs style={{maxWidth: '150px'}}>
          <TextField
            inputRef={quantityRef}
            variant="outlined"
            autoComplete="off"
            id="quantity"
            name="quantity"
            label="Količina"
            fullWidth
            type="number"
            size="small"
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                editButtonRef.current && editButtonRef.current.focus()
              }
            }}
            value={editingItem.quantity}
            onChange={(event) => {
              const newItem = new GoodsReceiptItemModel(editingItem)
              newItem.update({
                quantity: event.target.value,
              })
              setItem(newItem, idx)
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2} lg={1}>
          {originalItem && originalItem.current &&
          (originalItem.current.quantity !== editingItem.quantity) ?
            <Button
              ref={editButtonRef}
              onClick={() => {
                editGoodsReceiptItem(editingItem, idx)
                originalItem.current = editingItem
              }} variant="contained" color="default">
              <Check/>
            </Button> : null}
          {editingItem && editingItem.id && !(originalItem.current.quantity !== editingItem.quantity) &&
          <Button onClick={() => {
            deleteGoodsReceiptItem(editingItem)
          }} variant="contained" color="default"><Delete/></Button>
          }
        </Grid>

      </Grid>
    </Box>
  )

}