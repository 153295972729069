import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useAxios } from '../../utils/hooks'
import { useHistory } from 'react-router'
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Paper
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import {
  setSuccessSnackbar,
  setInvoiceToPrint,
  setErrorSnackbar,
  setIsLoading,
  getCurrentTcrData,
  getCurrentTcr,
  getCurrentSubscriber, getCurrentStore
} from '../mainReducer'
import CreditNoteItem from './CreditNoteItem'
import moment from 'moment-timezone'
import TextField from '@material-ui/core/TextField'
import { Autocomplete } from '@material-ui/lab'
import 'moment/locale/me'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { decode } from 'html-entities'
import Form from '@rjsf/material-ui'
import JsxParser from 'react-jsx-parser'
import { PlusOne } from '@material-ui/icons'
import { CreditNoteModel } from '../../models/CreditNoteModel'
import CreditNoteInvoicesForClient from './CreditNoteInvoicesForClient'
import { CreditNoteItemModel } from '../../models/CreditNoteItemModel'
import CreditNoteIICInvoicesList from './CreditNoteIICInvoicesList'
import CreditNotePreview from './CreditNotePreview'
import packageJson from '../../../package.json'
import { sleep } from '../../utils/helpers'

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
})

export default function NewCreditNote () {

  const classes = useStyles()
  const history = useHistory()
  const currentSubscriber = useSelector(getCurrentSubscriber)
  const currentTcr = useSelector(getCurrentTcr)
  const currentStore = useSelector(getCurrentStore)
  const currentTcrData = useSelector(getCurrentTcrData)
  const [invoice, setInvoice] = useState(new CreditNoteModel())
  const [counter, setCounter] = useState(0)
  const operatorRef = useRef()
  const noteRef = useRef()
  const addItemButtonRef = useRef()
  const dispatch = useDispatch()
  const axiosInstance = useAxios()
  const [templates, setTemplates] = useState([])
  const [settings, setSettings] = useState({})
  const [template, setTemplate] = useState(null)
  const [formData, setFormData] = useState({
    today: new Date(),
    currentYear: new Date().getFullYear()
  })
  const [nextInvoiceNumber, setNextInvoiceNumber] = useState()
  const [showModalForIICRefs, setShowModalForIICRefs] = useState(false)

  useEffect(() => {
    const inv = new CreditNoteModel()

    updateInvoice({
      ...inv,
      operatorCode: currentTcr.users && currentTcr.users.length === 1 ? currentTcr.users[0].operatorCode : ''
    })
  }, [currentTcr])

  useEffect(() => {
    updateInvoice({
      IICRefs: []
    })
  }, [invoice.client])

  /*useEffect(() => {
    const getTemplates = () => {
      dispatch(setIsLoading(true))
      if (!!axiosInstance.current) {
        axiosInstance.current.get(`/admin/subscriber/${currentSubscriber.id}/_settings`)
          .then((response) => {
            if (response) {
              const { data } = response;
              if (data && data.content) {
                setTemplates(data.content.templates)
                setSettings(data.content)
              }
            }
          })
          .finally(() => {
            dispatch(setIsLoading(false))
          })
      }
    }

    if (currentSubscriber.id) getTemplates()
  }, [axiosInstance, dispatch, currentSubscriber.id])*/

  const updateInvoice = (invoiceAttribute) => {
    invoice.update({
      ...invoiceAttribute
    })
    setCounter(counter + 1)
    setInvoice(invoice)
  }

  const editInvoiceItem = (item, idx) => {
    const updatedInvoiceItems = invoice.approvals.map((i, index) => {
      if (index === idx) {
        return item
      } else {
        return i
      }
    })

    updateInvoice({ approvals: updatedInvoiceItems })

  }

  const addInvoiceItem = (item) => {
    if (item) {
      const existingInvoiceItem = invoice.approvals.find(i => item && item.id && i.id === item.id)
      if (!existingInvoiceItem) {
        const newInvoiceItem = new CreditNoteItemModel()
        const updatedInvoiceItems = [...invoice.approvals, newInvoiceItem]
        updateInvoice({ approvals: updatedInvoiceItems })
      }
    } else {
      const newInvoiceItem = new CreditNoteItemModel()
      updateInvoice({ approvals: [...invoice.approvals, newInvoiceItem] })
    }
  }

  const deleteInvoiceItem = (item) => {
    const updatedInvoiceItems = invoice.approvals.filter(i => i.id !== item.id)
    updateInvoice({ approvals: updatedInvoiceItems })
  }

  const setIICRefs = (IICRefs) => {
    updateInvoice({ IICRefs })
  }

  const getInvoicesCount = useCallback(async () => {
    let invNumber = 0
    const url = `report/subscriber/${currentTcr.subscriberId}/tcr/${currentTcr.id}/invoice/_max?status=SUCCESS,STALLED`

    if (!!axiosInstance.current) {
      dispatch(setIsLoading(true))
      await axiosInstance.current.get(url)
        .then(({ data }) => {
          if (data && data.maxOrdNum >= 0) {
            invNumber = Number(data.maxOrdNum) + 1
          }
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          dispatch(setIsLoading(false))
        })
    }

    return invNumber
  }, [axiosInstance, currentTcr.id, currentTcr.subscriberId, dispatch])

  const postInvoice = useCallback(async () => {

    const invOrdNum = await getInvoicesCount()
    if (!(invOrdNum > 0)) {
      return
    }
    const url = `request/subscriber/${currentTcr.subscriberId}/tcr/${currentTcr.id}/_invoice`
    invoice.update({
      invOrdNum,
      yearOfIssuance: (new Date()).getFullYear().toString(),
      businUnitCode: currentTcr.businUnitCode,
      isIssuerInVAT: currentSubscriber.isIssuerInVAT,
      softCode: process.env.REACT_APP_SOFT_CODE,
      issueDateTime: moment().tz('Europe/Podgorica').format(),
      dueDate: moment().tz('Europe/Podgorica').format(),
      tcrCode: currentTcr.TCR,
      payMethods: [{ amt: invoice.totPrice, type: 'ACCOUNT' }],
      approvals: invoice.approvals,
      invoiceType: 'CREDIT_NOTE',
      seller: {
        'idNum': currentSubscriber.taxIdentifier,
        'idType': currentSubscriber.taxIdentifierType,
        'name': currentSubscriber.name,
        'address': currentSubscriber.address,
        'vatNumber': currentSubscriber.vatNumber,
        'town': currentSubscriber.town,
        'country': currentSubscriber.country,
        'isIssuerInVAT': currentSubscriber.isIssuerInVAT,
        'active': currentSubscriber.active,
        'bankAccounts': currentSubscriber.bankAccounts
      },
      note: noteRef && noteRef.current ? noteRef.current.innerHTML : ''
    })

    const header = {
      'sendDateTime': moment().tz('Europe/Podgorica').format(),
      'subscriberID': currentTcr.subscriberId,
      'tcrId': currentTcr.id,
      'certificateID': invoice.typeOfInv === 'CASH' ? currentTcr.cacheInvoiceCert.id : currentTcr.noncacheInvoiceCert.id,
      'platform': 'web',
      'appVersion': packageJson.version
    }

    const body = { 'header': header, 'invoice': invoice.toJson() }

    const validateStatus = (status) => {
      return status >= 200 && status < 303
    }

    dispatch(setIsLoading(true))
    if (!!axiosInstance.current) {
      axiosInstance.current.post(url, body, { validateStatus })
        .then(async ({ data }) => {
          const invoiceId = data.id
          const urlInvoiceResponse = `response/subscriber/${currentTcr.subscriberId}/tcr/${currentTcr.id}/invoice/${invoiceId}`
          let i = 0
          while (i < 40) {
            await sleep(500).then(async () => {
              try {
                const response = await axiosInstance.current.get(urlInvoiceResponse)
                const rData = response.data

                if (rData && (rData.status === 'SUCCESS' || rData.status === 'STALLED' || rData.status === 'ERROR')) {
                  i = 40
                  if (rData.status === 'ERROR') {
                    dispatch(setErrorSnackbar(rData.response.error.faultString))
                  } else if (rData.status === 'SUCCESS') {
                    dispatch(setInvoiceToPrint({ invoiceToPrint: invoiceId }))
                    dispatch(setSuccessSnackbar('Račun je fiskalizovan'))
                    history.push(`/invoices`)
                  } else if (rData.status === 'STALLED') {
                    dispatch(setInvoiceToPrint({ invoiceToPrint: invoiceId }))
                    dispatch(setSuccessSnackbar('Račun je sačuvan, ali nije fiskalizovan'))
                    history.push(`/invoices`)
                  }
                }
              } catch (e) {
                console.log('error getting invoice', e)
              }
            })
            i++
          }
        })
        .catch(err => {
          dispatch(setErrorSnackbar('Greška: ' + err && err.response && err.response.data ? JSON.stringify(err.response.data) : ''))
          console.log('err', err)
        })
        .finally(() => {
          dispatch(setIsLoading(false))
        })
    }

  }, [axiosInstance, dispatch, history, currentTcr, invoice, currentSubscriber, getInvoicesCount])

  return (
    <Grid container>
      <Grid item xs={12} sm={9} md={10}>
        <Paper elevation={3} style={{ marginTop: 10, marginBottom: 10 }}>
          <Box p={3}>
            <Grid container justify="space-between" spacing={3}>
              <Grid item xs>
                {currentTcrData &&
                <Autocomplete
                  id="combo-box-owner"
                  size="small"
                  options={currentTcrData && currentTcrData.clients ? currentTcrData.clients.filter(c => !c.deleted).sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1) : []}
                  getOptionSelected={(option, value) => option.id === value.id}
                  getOptionLabel={(option) => decode(option.name)}
                  renderOption={option => <span
                    style={{ color: option.country !== 'MNE' ? '#1571bc' : 'black' }}>{option.name}</span>}
                  style={{ width: 240 }}
                  onChange={(event, value) => {
                    if (value) {
                      updateInvoice({ client: value })
                    } else {
                      updateInvoice({ client: null })
                    }
                  }}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter' && invoice && invoice.client) operatorRef.current.focus()
                  }}
                  renderInput={(params) =>
                    <TextField {...params}
                               label="Partner"
                               autoFocus
                               variant="outlined" required/>}/>

                }
              </Grid>
              <Grid item xs>
                {currentTcr && currentTcr.users &&
                <Autocomplete
                  id="combo-box-operator"
                  size="small"
                  style={{ width: 240 }}
                  options={currentTcr && currentTcr.users ? currentTcr.users.filter(u => !u.deleted) : []}
                  getOptionSelected={(option, value) => option.id === value.id}
                  getOptionLabel={(option) => option.name}
                  defaultValue={currentTcr.users && currentTcr.users.length === 1 ? currentTcr.users[0] : null}
                  onChange={(event, value) => {
                    if (value) {
                      updateInvoice({ operatorCode: value.operatorCode })
                    } else {
                      updateInvoice({ operatorCode: '' })
                    }
                  }}
                  renderInput={(params) =>
                    <TextField {...params}
                               label="Operater"
                               inputRef={operatorRef}
                               required
                               variant="outlined"/>}/>

                }
              </Grid>
              {settings && settings.contractLabel &&
              <Grid item xs>
                <TextField
                  id="standard-name"
                  label={settings.contractLabel}
                  variant={'outlined'}
                  size={'small'}
                  // style={{marginTop: 20}}
                  onChange={(event) => {
                    if (event.target.value && event.target.value.replace(/\s/g, '')) {
                      updateInvoice({ contractId: event.target.value.replace(/\s/g, '') })
                    } else {
                      updateInvoice({ contractId: '' })
                    }
                  }}
                />
              </Grid>
              }
            </Grid>
          </Box>
        </Paper>
        <Box style={{ textAlign: 'left' }}>
          <CreditNoteIICInvoicesList IICRefs={invoice.IICRefs} client={invoice.client}
                                     setShowModalForIICRefs={setShowModalForIICRefs}/>
        </Box>
        <Paper elevation={3} style={{ marginTop: 10, marginBottom: 10 }}>
          <Box pl={3} pt={1}>
            <Typography variant="h6" gutterBottom p={3}>
              Odobrenja
            </Typography>
          </Box>
          <Box p={3} style={{ textAlign: 'center' }}>
            {currentTcrData ? invoice.approvals.map((item, idx) => {
              return <CreditNoteItem
                key={item.id}
                idx={idx}
                editingItem={item}
                addInvoiceItem={addInvoiceItem}
                setItem={(item, idx) => {
                  let approvals = [...invoice.approvals]
                  approvals[idx] = item
                  setInvoice({
                      ...invoice,
                      approvals
                    }
                  )
                }}
                editInvoiceItem={editInvoiceItem}
                deleteInvoiceItem={deleteInvoiceItem}
                invoiceItemsIds={invoice.approvals.map(i => i.vatRate)}
                addItemButtonRef={addItemButtonRef}
                isIssuerInVAT={currentSubscriber.isIssuerInVAT}
                useAdditionalTaxRate={currentTcr.useAdditionalTaxRate}
              />
            }) : null
            }
          </Box>
          <Box p={3} style={{ textAlign: 'center' }}>
            <Button
              ref={addItemButtonRef}
              disabled={invoice.approvals.length > 0 && invoice.approvals.filter(a => a.totalAmt >= 0).length > 0}
              onClick={() => addInvoiceItem()}
              variant="contained"
              size="small"
              color="default">
              <PlusOne/>
            </Button>
          </Box>
        </Paper>

        <Paper elevation={3} style={{ marginTop: 10, marginBottom: 10 }}>
          <Box p={3}>
            {templates && templates.length > 0 ?
              <Grid container>
                <Grid item sm={3}>
                  <Autocomplete
                    id="selectTemplate"
                    value={template}
                    options={templates}
                    getOptionSelected={(option, value) => option.id === value.id}
                    getOptionLabel={(option) => option.name}
                    size="small"
                    onChange={(event, value) => {
                      setTemplate(value)
                    }}
                    renderInput={(params) =>
                      <TextField {...params}
                                 label="Šablon"
                                 placeholder="Izaberite šablon"
                                 variant="outlined"/>}/>

                  {template && template.jsonSchema &&
                  <Form
                    schema={JSON.parse(template.jsonSchema)}
                    formData={formData}
                    uiSchema={JSON.parse(template.uiSchema)}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        ...e.formData
                      })
                    }}
                  />}
                </Grid>
                <Grid item sm={9} style={{ paddingLeft: 15 }}>
                  {template && formData ? <Grid item sm={12}>
                      <div
                        contentEditable={'false'}
                        suppressContentEditableWarning={true}
                        ref={noteRef}
                        style={{
                          border: '1px solid grey',
                          borderRadius: 5,
                          padding: 5,
                          fontSize: 16,
                          float: 'left',
                          width: '100%',
                          lineHeight: '18px'
                        }}
                        onChange={(event) => {
                          updateInvoice({
                            note: event.target.value
                          })
                        }}
                      >
                        <JsxParser
                          jsx={template.template}
                          bindings={{
                            ...formData
                          }}
                        />
                      </div>
                    </Grid> :
                    <Grid item sm={12}>
                      <div
                        contentEditable={'true'}
                        ref={noteRef}
                        style={{
                          border: '1px solid grey',
                          borderRadius: 5,
                          padding: 5,
                          fontSize: 16
                        }}
                        onChange={(event) => {
                          updateInvoice({
                            note: event.target.value
                          })
                        }}
                      >
                      </div>
                    </Grid>
                  }
                </Grid>
              </Grid> :
              <Grid item sm={12}>
                <div
                  contentEditable={'true'}
                  ref={noteRef}
                  style={{
                    border: '1px solid grey',
                    borderRadius: 5,
                    padding: 5,
                    fontSize: 16
                  }}
                  onChange={(event) => {
                    updateInvoice({
                      note: event.target.value
                    })
                  }}
                >
                </div>
              </Grid>
            }
          </Box>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={3} md={2}>
        <Box p={2}>
          <Grid container>
            <Grid item xs={12} style={{ padding: 5 }}>
              <Card className={classes.root} variant="outlined">
                <CardContent>
                  <Typography variant="h4" component="h2">
                    {!isNaN(invoice.totPriceWoVAT) ? invoice.totPriceWoVAT.toFixed(2) : '0.00'}
                  </Typography>
                  <Typography className={classes.title} color="textSecondary" gutterBottom>
                    Ukupno bez poreza
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} style={{ padding: 5 }}>
              <Card className={classes.root} variant="outlined">
                <CardContent>
                  <Typography variant="h4" component="h2">
                    {!isNaN(invoice.totVATAmt) ? invoice.totVATAmt.toFixed(2) : '0.00'}
                  </Typography>
                  <Typography className={classes.title} color="textSecondary" gutterBottom>
                    Porez
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} style={{ padding: 5 }}>
              <Card className={classes.root} variant="outlined">
                <CardContent>
                  <Typography variant="h4" component="h2">
                    {!isNaN(invoice.totPrice) ? invoice.totPrice.toFixed(2) : '0.00'}
                  </Typography>
                  <Typography className={classes.title} color="textSecondary" gutterBottom>
                    Ukupno
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item sm={12}>
              <Grid container justify="center">
                <Box p={3} style={{ textAlign: 'center' }}>
                  <Button
                    disabled={
                      !invoice ||
                      !invoice.client ||
                      invoice.IICRefs.length === 0 ||
                      invoice.operatorCode === '' ||
                      invoice.approvals.length === 0 ||
                      (invoice.approvals.length > 0 && invoice.approvals.filter(a => a.totalAmt >= 0).length > 0) ||
                      !currentSubscriber.active ||
                      !currentTcr.noncacheInvoiceCert ||
                      currentStore.allowedInvoiceType === 'CASH' ||
                      (
                        currentSubscriber.activeDueDate &&
                        moment(currentSubscriber.activeDueDate).isValid() &&
                        moment().isAfter(moment(currentSubscriber.activeDueDate).endOf("day"))
                      )
                    }
                    onClick={async () => {
                      setNextInvoiceNumber(await getInvoicesCount())
                    }}
                    variant="contained"
                    size="large"
                    color="primary">
                    Fiskalizuj
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>

      {showModalForIICRefs && invoice && invoice.client &&
      <Dialog
        disableBackdropClick
        fullWidth
        maxWidth="md"
        open={!!showModalForIICRefs}
        onClose={() => {
          setShowModalForIICRefs(false)
        }}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogContent>
          <CreditNoteInvoicesForClient clientId={invoice.client.id} IICRefs={invoice.IICRefs} setIICRefs={setIICRefs}/>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowModalForIICRefs(false)} variant="outlined" color="primary">
            Zatvori
          </Button>
        </DialogActions>
      </Dialog>}

      {nextInvoiceNumber &&
      <Dialog
        disableBackdropClick
        fullWidth
        maxWidth="md"
        open={!!nextInvoiceNumber}
        onClose={() => {
          setNextInvoiceNumber(null)
        }}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogContent>
          {invoice &&
          <CreditNotePreview
            settings={settings}
            nextInvoiceNumber={nextInvoiceNumber}
            currentSubscriber={currentSubscriber}
            invoice={{
              invoice: {
                ...invoice,
                yearOfIssuance: (new Date()).getFullYear().toString(),
                businUnitCode: currentTcr.businUnitCode,
                isIssuerInVAT: currentSubscriber.isIssuerInVAT,
                softCode: process.env.REACT_APP_SOFT_CODE,
                issueDateTime: moment().tz('Europe/Podgorica').format(),
                tcrCode: currentTcr.TCR,
                payMethods: [{ amt: invoice.totPrice, type: 'ACCOUNT' }],
                approvals: invoice.approvals,
                invoiceType: 'CREDIT_NOTE',
                seller: {
                  'idNum': currentSubscriber.taxIdentifier,
                  'idType': currentSubscriber.taxIdentifierType,
                  'name': currentSubscriber.name,
                  'vatNumber': currentSubscriber.vatNumber,
                  'address': currentSubscriber.address,
                  'town': currentSubscriber.town,
                  'country': currentSubscriber.country,
                  'isIssuerInVAT': currentSubscriber.isIssuerInVAT,
                  'active': currentSubscriber.active,
                  'bankAccounts': currentSubscriber.bankAccounts
                },
                note: noteRef && noteRef.current ? noteRef.current.innerHTML : ''
              }
            }}/>}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setNextInvoiceNumber(false)} variant="outlined" color="primary">
            Zatvori
          </Button>
          <Button onClick={async () => {
            setNextInvoiceNumber(false)
            await postInvoice()
          }} variant="contained" color="primary">
            Potvrdi
          </Button>
        </DialogActions>
      </Dialog>}
    </Grid>

  )
};

