import * as React from 'react'
import {
  Box, Button,
  Paper,
  TextField
} from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { useEffect, useState } from 'react'
import TcrList from './TcrList'
import { useDispatch } from 'react-redux'
import { setErrorSnackbar, setIsLoading } from '../mainReducer'
import { useAxios } from '../../utils/hooks'
import { TcrModel } from '../../models/TcrModel'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { maxNumChars, removeLineBreaks } from '../../utils/helpers'

export default function Store ({
  initialStore,
  postStore,
  subscriberId,
  certificates,
  users,
  subscriberTIN,
  isIssuerInVAT
}) {

  const [mounted, setMounted] = useState(false)
  const [edit, setEdit] = useState(false)
  const [store, setStore] = useState(initialStore)
  const [tcrs, setTcrs] = useState([])
  const dispatch = useDispatch()
  const axiosInstance = useAxios()

  useEffect(() => {
    setMounted(true)

    return () => {
      setMounted(false)
    }
  }, [])

  const handleChange = (event) => {
    setStore({
      ...store,
      [event.target.name]: event.target.value
    })
  }

  const handleChangeOnBlur = (name, value) => {
    setStore({
      ...store,
      [name]: value.trim()
    })
  }

  const handleSetAllowedInvoiceType = (event) => {
    let allowedInvoiceType
    if (event.target.name === 'cash') {
      if (event.target.checked) {
        allowedInvoiceType = 'BOTH'
      } else {
        allowedInvoiceType = 'NONCASH'
      }
    } else {
      if (event.target.checked) {
        allowedInvoiceType = 'BOTH'
      } else {
        allowedInvoiceType = 'CASH'
      }
    }

    setStore({
      ...store,
      allowedInvoiceType
    })
  }

  const addTcr = (tcr) => {
    const tcrModel = new TcrModel()
    tcrModel.update(tcr)
    mounted && setTcrs([...tcrs, tcrModel])
  }

  const updateTcr = (tcr) => {
    const tcrModel = new TcrModel()
    tcrModel.update(tcr)

    const updatedTcrs = tcrs.map(t => {
      return t.id === tcrModel.id ? tcrModel : t
    })
    mounted && setTcrs(updatedTcrs)
  }

  const storeId = store.id

  useEffect(() => {

    const getTcrs = () => {
      dispatch(setIsLoading(true))
      if (!!axiosInstance.current) {
        axiosInstance.current.get(`/admin/subscriber/${subscriberId}/store/${storeId}/tcr?size=50`)
          .then(response => {
            if (mounted) setTcrs(response.data.pos)
          })
          .catch(err => {
            console.log('err', err)
            dispatch(setErrorSnackbar('Greška'))
          })
          .finally(() => {
            dispatch(setIsLoading(false))
          })
      }
    }

    mounted && storeId && getTcrs()
  }, [axiosInstance, dispatch, storeId, subscriberId, mounted])

  return (
    <Box>
      <h3>Osnovni podaci</h3>
      <Paper elevation={3}>
        <Box p={2}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                autoComplete="off"
                required
                autoFocus
                error={store.name.trim().length < 2}
                helperText={`${store.name.length}/100`}
                id="name"
                name="name"
                label="Naziv"
                fullWidth
                value={store.name}
                onChange={handleChange}
                onBlur={(event) => {
                  handleChangeOnBlur(event.target.name, event.target.value)
                }}
                onPaste={(e) => {
                  e.preventDefault()
                  setStore({
                    ...store,
                    name: maxNumChars(removeLineBreaks(e.clipboardData.getData('Text')), 100)
                  })
                }}
                disabled={!edit}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                autoComplete="off"
                error={!store.businUnitCode.match('^$|[a-z]{2}[0-9]{3}[a-z]{2}[0-9]{3}') || store.businUnitCode.trim().length !== 10}
                helperText={!store.businUnitCode.match('^$|[a-z]{2}[0-9]{3}[a-z]{2}[0-9]{3}') || store.businUnitCode.trim().length !== 10 ? 'Unesite ispravan code' : ''}
                required
                id="businUnitCode"
                name="businUnitCode"
                label="PU code"
                fullWidth
                value={store.businUnitCode}
                onChange={handleChange}
                onBlur={(event) => {
                  handleChangeOnBlur(event.target.name, event.target.value)
                }}
                onPaste={(e) => {
                  e.preventDefault()
                  setStore({
                    ...store,
                    businUnitCode: maxNumChars(removeLineBreaks(e.clipboardData.getData('Text')), 10)
                  })
                }}
                disabled={!edit}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControlLabel
                control={<Checkbox
                  color="secondary"
                  name="cash"
                  disabled={!edit || (store.allowedInvoiceType && store.allowedInvoiceType === 'CASH')}
                  checked={!store.allowedInvoiceType || (store.allowedInvoiceType && ['CASH', 'BOTH'].includes(store.allowedInvoiceType))}
                  onChange={handleSetAllowedInvoiceType}/>}
                label="Gotovinski računi"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControlLabel
                control={<Checkbox
                  color="secondary"
                  name="noncash"
                  disabled={!edit || (store.allowedInvoiceType && store.allowedInvoiceType === 'NONCASH')}
                  checked={!store.allowedInvoiceType || (store.allowedInvoiceType && ['NONCASH', 'BOTH'].includes(store.allowedInvoiceType))}
                  onChange={handleSetAllowedInvoiceType}/>}
                label="Bezgotovinski računi"
              />
            </Grid>
            {edit && <Grid item xs={12} sm={6} md={3}>
              <TextField
                autoComplete="off"
                required
                error={store.address.trim().length < 2}
                helperText={`${store.address.length}/100`}
                id="address"
                name="address"
                label="Adresa"
                fullWidth
                value={store.address}
                onChange={handleChange}
                onBlur={(event) => {
                  handleChangeOnBlur(event.target.name, event.target.value)
                }}
                onPaste={(e) => {
                  e.preventDefault()
                  setStore({
                    ...store,
                    address: maxNumChars(removeLineBreaks(e.clipboardData.getData('Text')), 100)
                  })
                }}
                disabled={!edit}
              />
            </Grid>}
            {edit && <Grid item xs={12} sm={6} md={3}>
              <TextField
                autoComplete="off"
                required
                error={!store.city || store.city?.trim().length < 2}
                helperText={`${store.city ? store.city?.length : 0}/50`}
                id="city"
                name="city"
                label="Grad"
                fullWidth
                value={store.city}
                onChange={handleChange}
                onBlur={(event) => {
                  handleChangeOnBlur(event.target.name, event.target.value)
                }}
                onPaste={(e) => {
                  e.preventDefault()
                  setStore({
                    ...store,
                    city: maxNumChars(removeLineBreaks(e.clipboardData.getData('Text')), 50)
                  })
                }}
                disabled={!edit}
              />
            </Grid>}
            {edit && <Grid item xs={12} sm={12} md={6}>
              <TextField
                autoComplete="off"
                id="note"
                name="note"
                label="Napomena"
                fullWidth
                value={store.note}
                onChange={handleChange}
                disabled={!edit}
              />
            </Grid>}

            {edit &&
            <Grid item xs={12} style={{ textAlign: 'right' }}>
              <Button
                onClick={() => {
                  mounted && setEdit(false)
                }} variant="outlined" color="primary">
                Zatvori
              </Button>
              <Button
                onClick={() => {
                  mounted && setEdit(false)
                  postStore(store)
                }}
                disabled={
                  store.name.trim().length < 2 ||
                  store.address.trim().length < 2 ||
                  !store.city ||
                  store.city?.trim().length < 2 ||
                  !store.businUnitCode.match('^$|[a-z]{2}[0-9]{3}[a-z]{2}[0-9]{3}') ||
                  store.businUnitCode.trim().length !== 10}
                variant="contained"
                color="primary"
                style={{ marginLeft: 15 }}>
                Sačuvaj
              </Button>
            </Grid>}

            {!edit &&
            <Grid item xs={12} style={{ textAlign: 'right' }}>
              <Button onClick={() => {
                mounted && setEdit(true)
              }} variant="outlined" color="primary">
                Izmijeni
              </Button>
            </Grid>}

          </Grid>
        </Box>
      </Paper>

      <h3>Lista ENU-a</h3>
      <Paper elevation={3}>
        <TcrList tcrs={tcrs} certificates={certificates} subscriberId={subscriberId} subscriberTIN={subscriberTIN}
                 storeId={store.id}
                 addTcr={addTcr} updateTcr={updateTcr} users={users} businUnitCode={store.businUnitCode}
                 isIssuerInVAT={isIssuerInVAT}/>
      </Paper>

    </Box>
  )
}
