import React, { useState } from 'react'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import {
  getCurrentRole,
  getCurrentStore, getCurrentStoreTcrs,
  getCurrentSubscriber, getCurrentTcr, getSubscribers,
  getUser,
  setCurrentRole, setCurrentStore, setCurrentSubscriber, setCurrentTcr
} from '../../containers/mainReducer'
import { useKeycloak } from '@react-keycloak/web'
import {
  Button,
  ClickAwayListener,
  Grid,
  Grow,
  Menu,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  TextField
} from '@material-ui/core'
import { AccountCircle } from '@material-ui/icons'
import { useHistory } from 'react-router'
import { Autocomplete } from '@material-ui/lab'
import VerifyIKOFDialog from '../Dialogs/VerifyIKOFDialog'
import packageJson from '../../../package.json'

const styles = () => ({
  root: {
    flexGrow: 1,
  },
  toolbarRoot: {
    paddingRight: 24,
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
    color: 'white'
  },
  menuButtonColor: {
    color: 'white'
  },
  title: {
    color: 'white',
  }
})

export const translateRole = (role) => {
  switch (role) {
    case 'OWNER':
      return 'Vlasnik'
    case 'SELLER':
      return 'Prodavac'
    case 'ADMIN':
      return 'Administrator'
    case 'ACCOUNTANT':
      return 'Računovođa'
    default:
      return ''
  }
}

const useStyles = makeStyles(() => ({
  root: {
    color: 'white',
    '& .MuiFormLabel-root': {
      color: 'white'
    },
    '& .MuiIconButton-label': {
      color: 'white'
    },
  },
  inputRoot: {
    color: 'white',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'white'
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: 'white'
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'white'
    },
    '&.Mui-disabled': {
      color: 'white'
    },

  }
}))

const Header = props => {
  const { classes, handleToggleDrawer } = props
  const classes1 = useStyles()
  const currentRole = useSelector(getCurrentRole)
  const subscribers = useSelector(getSubscribers)
  const currentSubscriber = useSelector(getCurrentSubscriber)
  const currentStore = useSelector(getCurrentStore)
  const currentStoreTcrs = useSelector(getCurrentStoreTcrs)
  const currentTcr = useSelector(getCurrentTcr)
  const user = useSelector(getUser)
  const { keycloak } = useKeycloak()
  const [anchorEl, setAnchorEl] = useState(null)
  const [verifyIKOFDialogOpened, setVerifyIKOFDialogOpened] = useState(false)
  const open = Boolean(anchorEl)
  const history = useHistory()

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setOpenRole(false)
  }
  const dispatch = useDispatch()
  const [openRole, setOpenRole] = React.useState(false)
  const anchorRef = React.useRef(null)

  const handleToggle = () => {
    setOpenRole((prevOpen) => !prevOpen)
  }

  const handleChangeRole = (role) => {
    dispatch(setCurrentRole(role))
    setOpenRole(false)
  }

  function handleListKeyDown (event) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpenRole(false)
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(openRole)
  React.useEffect(() => {
    if (prevOpen.current === true && openRole === false) {
      anchorRef.current.focus()
    }

    prevOpen.current = openRole
  }, [openRole])

  return (
    <AppBar position="fixed">
      <Toolbar disableGutters={true} classes={{ root: classes.toolbarRoot }}>
        <IconButton
          aria-label="Open drawer"
          onClick={handleToggleDrawer}
          className={classes.menuButton}
          color="inherit"
        >
          <MenuIcon/>
        </IconButton>
        <div className="logo-header">
          <img src="/logo_white.png" alt={''}/>
          <span className="version">{`v.${packageJson.version}`}</span>
        </div>
        <Grid container>
          <Grid item sm={1} lg={false}/>
          <Grid item sm={3} lg={2} style={{ marginLeft: 5, marginRight: 5 }}>
            {subscribers && subscribers.length > 0 &&
            <Autocomplete
              id="selectCurrentSubscriber"
              disabled={subscribers && subscribers.length === 1}
              defaultValue={subscribers && subscribers.length === 1 ? currentSubscriber : null}
              value={currentSubscriber}
              options={[...subscribers].sort((a, b) => {
                const nameA = a.name.toUpperCase() // ignore upper and lowercase
                const nameB = b.name.toUpperCase() // ignore upper and lowercase
                if (nameA < nameB) {
                  return -1
                }
                if (nameA > nameB) {
                  return 1
                }

                return 0
              })}
              getOptionSelected={(option, value) => option.id === value.id}
              getOptionLabel={(option) => option.name}
              fullWidth
              size="small"
              classes={classes1}
              onChange={(event, value) => {
                dispatch(setCurrentSubscriber(value))
              }}
              renderInput={(params) =>
                <TextField {...params}
                           label="Firma"
                           placeholder="Izaberite firmu"
                           variant="outlined"/>}/>
            }
          </Grid>

          <Grid item sm={3} lg={2} style={{ marginLeft: 5, marginRight: 5 }}>
            {currentSubscriber && currentSubscriber.stores && currentSubscriber.stores.length > 0 &&
            <Autocomplete
              id="selectCurrentStore"
              disabled={currentSubscriber.stores.length === 1}
              value={currentStore}
              options={[...currentSubscriber.stores].sort((a, b) => {
                const nameA = a.name.toUpperCase() // ignore upper and lowercase
                const nameB = b.name.toUpperCase() // ignore upper and lowercase
                if (nameA < nameB) {
                  return -1
                }
                if (nameA > nameB) {
                  return 1
                }

                return 0
              })}
              getOptionSelected={(option, value) => option.id === value.id}
              getOptionLabel={(option) => option.name}
              fullWidth
              size="small"
              classes={classes1}
              onChange={(event, value) => {
                dispatch(setCurrentStore(value))
              }}
              renderInput={(params) =>
                <TextField {...params}
                           label="Poslovna jedinica"
                           placeholder="Izaberite poslovnu jedinicu"
                           variant="outlined"/>}/>
            }
          </Grid>

          <Grid item sm={3} lg={2} style={{ marginLeft: 5, marginRight: 5 }}>
            {currentStoreTcrs && currentStoreTcrs.length > 0 &&
            <Autocomplete
              id="selectCurrentTcr"
              disabled={currentStoreTcrs.length === 1}
              defaultValue={currentStoreTcrs.length === 1 ? currentTcr : null}
              options={[...currentStoreTcrs].sort((a, b) => {
                const nameA = a.name.toUpperCase() // ignore upper and lowercase
                const nameB = b.name.toUpperCase() // ignore upper and lowercase
                if (nameA < nameB) {
                  return -1
                }
                if (nameA > nameB) {
                  return 1
                }

                return 0
              })}
              getOptionSelected={(option, value) => option.id === value.id}
              getOptionLabel={(option) => option.name}
              fullWidth
              style={{ marginLeft: 5, marginRight: 5 }}
              size="small"
              classes={classes1}
              onChange={(event, value) => {
                dispatch(setCurrentTcr(value))
              }}
              value={currentTcr}
              renderInput={params => {
                return (
                  <TextField
                    {...params}
                    label="ENU"
                    placeholder="Izaberite ENU"
                    variant="outlined"
                    fullWidth
                  />
                )
              }}/>
            }
          </Grid>
        </Grid>
        {keycloak.authenticated && user && user.roles.length > 1 && (
          <>
            <Button
              ref={anchorRef}
              aria-controls={open ? 'menu-list-grow' : undefined}
              aria-haspopup="true"
              onClick={handleToggle}
              color="secondary"
            >
              {translateRole(currentRole)}
            </Button>
            <Popper open={openRole} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList autoFocusItem={open} id="menu-list-grow"
                                onKeyDown={handleListKeyDown}>
                        {user && <MenuItem style={{ color: '#285ca8' }} disabled>{user.name}</MenuItem>}

                        {user.roles.filter(role => role !== currentRole).map(role =>
                          <MenuItem key={role}
                                    onClick={() => {
                                      handleChangeRole(role)
                                    }
                                    }>{translateRole(role)}</MenuItem>
                        )}

                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </>
        )}
        {keycloak.authenticated && (
          <Grid item>
            <Button
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              className={classes.menuButtonColor}
            >
              <AccountCircle color="inherit"/>
            </Button>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={open}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() => {
                  history.push(`/settings`)
                  handleClose()
                }}>Podešavanja
              </MenuItem>
              {currentTcr && <MenuItem
                onClick={() => {
                  setVerifyIKOFDialogOpened(true)
                  handleClose()
                }}>Verifikacija IKOF-a
              </MenuItem>}
              <MenuItem
                onClick={() => {
                  handleClose()
                  keycloak.logout()
                  history.push(`/`)
                }}>Odjava</MenuItem>
            </Menu>
          </Grid>
        )}
      </Toolbar>
      {verifyIKOFDialogOpened && currentSubscriber && currentTcr &&
      <VerifyIKOFDialog
        open={verifyIKOFDialogOpened}
        closeConfirm={() => setVerifyIKOFDialogOpened(false)}
        subscriberId={currentSubscriber.id}
        tcr={currentTcr}
      />
      }
    </AppBar>
  )
}

export default withStyles(styles)(Header)
