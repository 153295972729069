import moment from 'moment-timezone'
import { CreditNoteItemModel } from './CreditNoteItemModel'

export class CreditNoteModel {

  constructor () {
    this.yearOfIssuance = ''
    this.businUnitCode = ''
    this.issueDateTime = ''
    this.invOrdNum = 0
    this.contractId = ''
    this.isIssuerInVAT = true
    this.isReverseCharge = false
    this.operatorCode = ''
    this.softCode = ''
    this.tcrCode = ''
    this.totPrice = 0.0
    this.totPriceWoVAT = 0.0
    this.totVATAmt = 0.0
    this.typeOfInv = 'NONCASH'
    this.invoiceType = 'INVOICE'
    this.payMethods = []
    this.goodsExAmt = 0.0
    this.seller = null
    this.approvals = [new CreditNoteItemModel()]
    this.sameTaxes = []
    this.client = null
    this.discountAmount = 0.0
    this.dueDate = moment().tz('Europe/Podgorica').format()
    this.discountPercentage = 0
    this.note = ''
    this.IICRefs = []
  }

  fromJSON (invoice) {
    Object.assign(this, invoice)
    this.approvals = invoice.approvals.map(i => new CreditNoteItemModel(i))
  }

  update (updatedInvoice) {
    Object.assign(this, updatedInvoice)

    if (updatedInvoice.approvals) {
      const sameTaxes = {}
      let totVATAmt = 0.0
      let totWoVAT = 0.0
      let totDiscount = 0.0

      this.approvals.forEach((e) => {
        if (e.totalAmt) {
          if (!sameTaxes[e.vatRate.toString()]) {
            sameTaxes[e.vatRate.toString()] = {
              numOfItems: 1,
              priceBeforeVAT: e.returnAmt,
              vatAmt: e.vatAmt,
              vatRate: Number(e.vatRate)
            }
          } else {
            sameTaxes[e.vatRate.toString()].numOfItems = sameTaxes[e.vatRate.toString()].numOfItems + 1
            sameTaxes[e.vatRate.toString()].priceBeforeVAT = this.roundDecimal(sameTaxes[e.vatRate.toString()].priceBeforeVAT + e.returnAmt, 4)
            sameTaxes[e.vatRate.toString()].vatAmt = this.roundDecimal(sameTaxes[e.vatRate.toString()].vatAmt + e.vatAmt, 4)
          }

        }
      })

      this.goodsExAmt = sameTaxes['0'] ? this.roundDecimal(sameTaxes['0'].priceBeforeVAT, 2) : 0

      //same taxes
      this.sameTaxes = Object.keys(sameTaxes).map(v => {
        return {
          ...sameTaxes[v],
          priceBeforeVAT: this.roundDecimal(sameTaxes[v].priceBeforeVAT, 2),
          vatAmt: this.roundDecimal(sameTaxes[v].vatAmt, 2)
        }
      })

      this.sameTaxes.forEach((st) => {
        totVATAmt = this.roundDecimal(totVATAmt + st.vatAmt, 2)
        totWoVAT = this.roundDecimal(totWoVAT + st.priceBeforeVAT, 2)
      })

      this.totPriceWoVAT = this.roundDecimal(totWoVAT, 2)
      this.totVATAmt = this.roundDecimal(totVATAmt, 2)
      this.totPrice = this.roundDecimal(this.roundDecimal(totWoVAT, 2) + this.roundDecimal(totVATAmt, 2), 2)
      this.discountAmount = this.roundDecimal(totDiscount, 2)
    }
  }

  roundDecimal (val, places) {
    const mod = Math.pow(10.0, places)
    return Math.round(val * mod) / mod
  }

  getCorrectiveInvoice () {
    return {
      ...this,
      yearOfIssuance: (new Date()).getFullYear().toString(),
      issueDateTime: moment().tz('Europe/Podgorica').format(),
      totPrice: -this.totPrice,
      totPriceWoVAT: -this.totPriceWoVAT,
      totVATAmt: -this.totVATAmt,
      payMethods: this.payMethods.map(i => {
        return {
          amt: -i.amt,
          type: i.type
        }
      }),
      sameTaxes: this.sameTaxes.map(i => {
        return {
          ...i,
          priceBeforeVAT: -i.priceBeforeVAT,
          vatAmt: -i.vatAmt
        }
      }),
      approvals: this.approvals.map(i => {
        return {
          discountAmt: 0,
          id: i.id,
          note: i.note,
          vatRate: i.vatRate,
          returnAmt: -i.returnAmt,
          totalAmt: -i.totalAmt,
          vatAmt: -i.vatAmt
        }
      })
    }
  }

  toJson () {
    const invoice = this

    return {
      ...invoice
    }
  }

}

