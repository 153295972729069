import React, {Fragment, useEffect} from "react";
import {withStyles} from "@material-ui/core/styles";
import classNames from "classnames";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import {
    Avatar,
    Backdrop,
    CircularProgress,
    Dialog,
    DialogTitle,
    ListItem,
    ListItemAvatar, ListItemText,
    Snackbar
} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {
    getCurrentRole,
    getCurrentStore,
    getCurrentSubscriber,
    getCurrentTcr, getDashboardReportPeriod, getDrawerOpened,
    getIsLoading,
    getSnackbar,
    getUser,
    setCurrentRole,
    setCurrentStoreTcrs,
    setCurrentTcrData, setDashboardLast7Days, setDashboardReportData, setDrawerOpened,
    setIsLoading,
    setSnackbar, setSubscribers
} from '../containers/mainReducer'
import MuiAlert from '@material-ui/lab/Alert';
import List from "@material-ui/core/List";
import {Person} from "@material-ui/icons";
import {useAxios} from "../utils/hooks";
import {translateRole} from "../components/Header/Header";
import {useHistory} from "react-router";
import {useLocation} from "react-router-dom";
import moment from "moment-timezone";
import WarningMessage from '../components/WarningMessage'
import {calculateDateFrom, createDateFromUrl} from '../utils/helpers'

const drawerWidth = 240;

const styles = theme => ({
    root: {
        display: "flex"
    },
    content: {
        flexGrow: 1,
        marginLeft: theme.spacing(7),
        padding: theme.spacing(2),
        marginTop: theme.spacing(7),
        overflowX: "hidden"
    },
    contentShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
});

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const MainLayout = ({classes, children}) => {

    const drawerOpened = useSelector(getDrawerOpened);
    const currentRole = useSelector(getCurrentRole);
    const currentSubscriber = useSelector(getCurrentSubscriber);
    const currentStore = useSelector(getCurrentStore);
    const currentTcr = useSelector(getCurrentTcr);
    const user = useSelector(getUser);
    const dashboardReportPeriod = useSelector(getDashboardReportPeriod);
    const snackbar = useSelector(getSnackbar);
    const isLoading = useSelector(getIsLoading);
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const handleToggleDrawer = () => {
      dispatch(setDrawerOpened(!drawerOpened));
    };

    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        dispatch(setSnackbar({
            show: false,
            message: "",
            type: null
        }));

    };

    const handleChangeCurrentRole = (role) => {
        dispatch(setCurrentRole(role));
    }

    const axiosInstance = useAxios();

    useEffect(() => {
        const getSubscribers = () => {
            dispatch(setIsLoading(true));
            if (!!axiosInstance.current) {
                axiosInstance.current.post(`/admin/subscriber/_search?from=0&size=100`, {term: '', role: currentRole})
                    .then(response => {
                        if (response && response.data && response.data.subscribers.length) {
                            dispatch(setSubscribers(response.data.subscribers.filter(s => s.active)));
                        }
                    })
                    .finally(() => {
                        dispatch(setIsLoading(false));
                    });
            }
        };

        switch (currentRole) {
            case "OWNER":
                getSubscribers();
                break;
            case "ACCOUNTANT":
                getSubscribers();
                break;
            default:

        }
    }, [dispatch, axiosInstance, currentRole]);

    useEffect(() => {

        const dateFrom = calculateDateFrom(dashboardReportPeriod);
        const dateTo = moment().endOf('day').valueOf();

        const dateFromUrl = encodeURIComponent(moment(dateFrom).tz("Europe/Podgorica").format())
        // const dateFromUrl7Days = encodeURIComponent(moment().subtract(dashboardReportPeriod === 1 ? 7 : 0, 'days').startOf('day').tz("Europe/Podgorica").format());
        const dateToUrl = encodeURIComponent(moment(dateTo).tz("Europe/Podgorica").format());

        const getStoresTcrs = () => {
            if (!!axiosInstance.current) {
                dispatch(setIsLoading(true));
                axiosInstance.current.get(`/admin/subscriber/${currentSubscriber.id}/store/${currentStore.id}/tcr?from=0&size=500`)
                    .then(response => {
                        if (response && response.data && response.data.pos.length) {
                            dispatch(setCurrentStoreTcrs(response.data.pos.filter(p => !!p.TCR && p.active)));
                        }
                    })
                    .finally(() => {
                        dispatch(setIsLoading(false));
                    });
            }
        }

        const getSubscriberReport = async () => {

            if (!!axiosInstance.current) {
                dispatch(setIsLoading(true));
                await axiosInstance.current.get(`/report/subscriber/${currentSubscriber.id}/_items_turnover?dateFrom=${dateFromUrl}&dateTo=${dateToUrl}&status=SUCCESS,STALLED`)
                    .then(({data}) => {
                        const sumBySubscriber = data && data.sumBySubscriber && data.sumBySubscriber.buckets.length ? data.sumBySubscriber.buckets[0] : null;
                        if (sumBySubscriber) {
                            let sumsByStores = [];
                            if (sumBySubscriber.sumsByStores && sumBySubscriber.sumsByStores.buckets && sumBySubscriber.sumsByStores.buckets.length) {
                                sumsByStores = sumBySubscriber.sumsByStores.buckets.map(store => {
                                    const subStore = currentSubscriber.stores.find(s => s.businUnitCode === store.key);
                                    return {
                                        key: store.key,
                                        name: subStore.name,
                                        totalAmountOfVAT: store.totVATAmt ? store.totVATAmt.value : 0,
                                        totalDiscount: store.totDiscount ? store.totDiscount.value : 0,
                                        totalPriceWithVAT: store.totPrice ? store.totPrice.value : 0,
                                        totalPriceWithoutVAT: store.totPriceWoVAT ? store.totPriceWoVAT.value : 0
                                    }
                                })
                            }
                            dispatch(setDashboardReportData({
                                totalAmountOfVAT: sumBySubscriber && sumBySubscriber.totVATAmt ? sumBySubscriber.totVATAmt.value : 0,
                                totalDiscount: sumBySubscriber && sumBySubscriber.totDiscount ? sumBySubscriber.totDiscount.value : 0,
                                totalPriceWithVAT: sumBySubscriber && sumBySubscriber.totPrice ? sumBySubscriber.totPrice.value : 0,
                                totalPriceWithoutVAT: sumBySubscriber && sumBySubscriber.totPriceWoVAT ? sumBySubscriber.totPriceWoVAT.value : 0,
                                sumsByStores
                            }));
                        } else {
                            dispatch(setDashboardReportData({
                                totalAmountOfVAT: 0,
                                totalDiscount: 0,
                                totalPriceWithVAT: 0,
                                totalPriceWithoutVAT: 0,
                                sumsByStores: []
                            }));
                        }
                    })
                    .finally(() => {
                        dispatch(setIsLoading(false));
                    });

                if (!!axiosInstance.current) {
                    dispatch(setIsLoading(true));
                    const calendarInterval = dashboardReportPeriod === 0 ? '1h' : (dashboardReportPeriod === 1 ? '1d' : (dashboardReportPeriod === 2 ? '1d' : '1M'));
                    await axiosInstance.current.get(`/report/subscriber/${currentSubscriber.id}/_items_turnover?dateFrom=${dateFromUrl}&dateTo=${dateToUrl}&status=SUCCESS,STALLED&calendarInterval=${calendarInterval}`)
                      .then(({ data }) => {
                          const timeRanges = data && data.timeRange && data.timeRange.buckets;
                          if (timeRanges && timeRanges.length > 0) {
                              const report = timeRanges.map(tr => {
                                  return {
                                      key: tr.key,
                                      date: tr.key_as_string,
                                      itemsCount: tr.sumBySubscriber && tr.sumBySubscriber.buckets && tr.sumBySubscriber.buckets.length && tr.sumBySubscriber.buckets[0].doc_count,
                                      totPrice: tr.sumBySubscriber && tr.sumBySubscriber.buckets && tr.sumBySubscriber.buckets.length && tr.sumBySubscriber.buckets[0].totPrice && tr.sumBySubscriber.buckets[0].totPrice.value,
                                  }
                              })

                              dispatch(setDashboardLast7Days(report));
                          } else {
                              dispatch(setDashboardLast7Days([]));
                          }
                      })
                      .finally(() => {
                          dispatch(setIsLoading(false));
                      });
                }
            }
        }

        const getStoreReport = async () => {
            if (!!axiosInstance.current) {
                dispatch(setIsLoading(true));
                await axiosInstance.current.get(`/report/subscriber/${currentSubscriber.id}/store/${currentStore.businUnitCode}/_items_turnover?dateFrom=${dateFromUrl}&dateTo=${dateToUrl}&status=SUCCESS,STALLED`)
                    .then(({data}) => {
                        const sumByStore = data && data.sumByStore && data.sumByStore.buckets.length ? data.sumByStore.buckets[0] : null;
                        if (sumByStore) {
                            dispatch(setDashboardReportData({
                                totalAmountOfVAT: sumByStore && sumByStore.totVATAmt ? sumByStore.totVATAmt.value : 0,
                                totalDiscount: sumByStore && sumByStore.totDiscount ? sumByStore.totDiscount.value : 0,
                                totalPriceWithVAT: sumByStore && sumByStore.totPrice ? sumByStore.totPrice.value : 0,
                                totalPriceWithoutVAT: sumByStore && sumByStore.totPriceWoVAT ? sumByStore.totPriceWoVAT.value : 0
                            }));
                        } else {
                            dispatch(setDashboardReportData({
                                totalAmountOfVAT: 0,
                                totalDiscount: 0,
                                totalPriceWithVAT: 0,
                                totalPriceWithoutVAT: 0
                            }));
                        }
                    })
                    .finally(() => {
                        dispatch(setIsLoading(false));
                    });

                if (!!axiosInstance.current) {
                    dispatch(setIsLoading(true));
                    const calendarInterval = dashboardReportPeriod === 0 ? '1h' : (dashboardReportPeriod === 1 ? '1d' : (dashboardReportPeriod === 2 ? '1d' : '1M'));

                    await axiosInstance.current.get(`/report/subscriber/${currentSubscriber.id}/store/${currentStore.businUnitCode}/_items_turnover?dateFrom=${dateFromUrl}&dateTo=${dateToUrl}&status=SUCCESS,STALLED&calendarInterval=${calendarInterval}`)
                      .then(({ data }) => {
                          const timeRanges = data && data.timeRange && data.timeRange.buckets;
                          if (timeRanges && timeRanges.length > 0) {
                              const report = timeRanges.map(tr => {
                                  return {
                                      key: tr.key,
                                      date: tr.key_as_string,
                                      itemsCount: tr.sumByStore && tr.sumByStore.buckets && tr.sumByStore.buckets.length && tr.sumByStore.buckets[0].doc_count,
                                      totPrice: tr.sumByStore && tr.sumByStore.buckets && tr.sumByStore.buckets.length && tr.sumByStore.buckets[0].totPrice && tr.sumByStore.buckets[0].totPrice.value,
                                  }
                              })

                              dispatch(setDashboardLast7Days(report));
                          } else {
                              dispatch(setDashboardLast7Days([]));
                          }
                      })
                      .finally(() => {
                          dispatch(setIsLoading(false));
                      });
                }
            }
        }

        const getTcrReport = async () => {
            if (!!axiosInstance.current) {
                dispatch(setIsLoading(true));
                axiosInstance.current.get(`/report/subscriber/${currentSubscriber.id}/tcr/${currentTcr.id}/_items_turnover?dateFrom=${dateFromUrl}&dateTo=${dateToUrl}&status=SUCCESS,STALLED`)
                    .then(({data}) => {
                        dispatch(setDashboardReportData({
                            totalAmountOfVAT: data.byItemsTurnover.totalAmountOfVAT.value,
                            totalDiscount: data.byItemsTurnover.totalDiscount.value,
                            totalPriceWithVAT: data.byItemsTurnover.totalPriceWithVAT.value,
                            totalPriceWithoutVAT: data.byItemsTurnover.totalPriceWithoutVAT.value,
                            totalQuantity: data.byItemsTurnover.totalQuantity.value
                        }));
                    })
                    .finally(() => {
                        dispatch(setIsLoading(false));
                    });
            }

            if (!!axiosInstance.current) {
                dispatch(setIsLoading(true));
                const calendarInterval = dashboardReportPeriod === 0 ? '1h' : (dashboardReportPeriod === 1 ? '1d' : (dashboardReportPeriod === 2 ? '1d' : '1M'));

                await axiosInstance.current.get(`/report/subscriber/${currentSubscriber.id}/tcr/${currentTcr.id}/_items_turnover?dateFrom=${dateFromUrl}&dateTo=${dateToUrl}&status=SUCCESS,STALLED&calendarInterval=${calendarInterval}`)
                  .then(({ data }) => {
                      const timeRanges = data && data.timeRange && data.timeRange.buckets;
                      if (timeRanges && timeRanges.length > 0) {
                          const report = timeRanges.map(tr => {
                              return {
                                  key: tr.key,
                                  date: tr.key_as_string,
                                  itemsCount: tr.sumByTCR && tr.sumByTCR.buckets && tr.sumByTCR.buckets.length && tr.sumByTCR.buckets[0].doc_count,
                                  totPrice: tr.sumByTCR && tr.sumByTCR.buckets && tr.sumByTCR.buckets.length && tr.sumByTCR.buckets[0].totPrice && tr.sumByTCR.buckets[0].totPrice.value,
                              }
                          })

                          dispatch(setDashboardLast7Days(report));
                      } else {
                          dispatch(setDashboardLast7Days([]));
                      }
                  })
                  .finally(() => {
                      dispatch(setIsLoading(false));
                  });
            }
        }

        if (currentSubscriber && currentStore && currentTcr) {
            if (location && location.pathname === '/') {
                getTcrReport();
            }
        } else if (currentSubscriber && currentStore && !currentTcr) {
            getStoresTcrs();
            if (location && location.pathname === '/') {
                getStoreReport();
            }
        } else if (currentSubscriber && !currentStore) {
            if (location && location.pathname !== '/') {
                history.push("/");
            } else {
                getSubscriberReport();
            }
        } else if (!currentSubscriber && !currentStore) {
            dispatch(setDashboardReportData(null));
            if ((currentRole === "OWNER" || currentRole === "ACCOUNTANT") && location && location.pathname !== '/') {
                history.push("/");
            } else if (currentRole === "ADMIN") {

            }
        }
    }, [axiosInstance, dispatch, currentSubscriber, currentStore, currentTcr, history, location, dashboardReportPeriod, currentRole]);

    useEffect(() => {
        const getTcr = () => {
            dispatch(setIsLoading(true));
            if (!!axiosInstance.current) {
                axiosInstance.current.get(`/inventory/subscriber/${currentSubscriber.id}/tcr/${currentTcr.id}/tcrdata`)
                    .then(response => {
                        dispatch(setCurrentTcrData(response.data));
                    })
                    .finally(() => {
                        dispatch(setIsLoading(false));
                    });
            }
        };

        if (currentSubscriber && currentStore && currentTcr) {
            getTcr();
        }

    }, [axiosInstance, dispatch, currentSubscriber, currentStore, currentTcr]);

    return (
        <Fragment>
            <div className={classes.root}>
                <Header
                    handleToggleDrawer={handleToggleDrawer}
                />
                <main
                    className={classNames(classes.content, {
                        [classes.contentShift]: drawerOpened
                    })}
                >
                    <WarningMessage />
                    {children}
                </main>
            </div>
            <Sidebar open={drawerOpened} drawerWidth={drawerWidth}/>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={snackbar.show}
                autoHideDuration={7000}
                onClose={handleCloseSnackBar}
            >
                {snackbar.type &&
                <Alert onClose={handleCloseSnackBar} severity={snackbar.type}>
                    {snackbar.message}
                </Alert>
                }
            </Snackbar>
            <Backdrop className={classes.backdrop} open={isLoading}>
                <CircularProgress color="inherit"/>
            </Backdrop>

            {user && user.roles.length > 0 && !currentRole &&
            <Dialog aria-labelledby="simple-dialog-title" open={!currentRole}>
                <DialogTitle id="simple-dialog-title">Odaberite ulogu</DialogTitle>
                <List>
                    {user.roles.map((role) => (
                        <ListItem button onClick={() => handleChangeCurrentRole(role)} key={role}>
                            <ListItemAvatar>
                                <Avatar className={classes.avatar}>
                                    <Person/>
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={translateRole(role)}/>
                        </ListItem>
                    ))}

                </List>
            </Dialog>
            }
        </Fragment>
    );
}

export default withStyles(styles)(MainLayout);
