import { Chip } from '@material-ui/core'
import { Check, Error, Loop, Warning } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import moment from 'moment-timezone'

export function htmlSpecialChars (unsafe) {
  return unsafe
    .replace(/&/g, '\u0026')
    .replace(/</g, '')
    .replace(/>/g, '')
    .replace(/"/g, '')
    .replace(/'/g, '')
}

export function removeLineBreaks (unsafe) {
  return unsafe.replace(/\n|\r|\t/g, '')
}

export function maxNumChars (unsafe, num) {
  if (!unsafe || !num) return ''
  return unsafe.trim().substring(0, num).trim()
}

export function parsePayMethods (payMethods) {
  const firstPayMethod = payMethods.length > 0 ? payMethods[0].type : ''
  switch (firstPayMethod) {
    case 'ADVANCE':
      return 'Avansni'
    case 'ACCOUNT':
      return 'Faktura'
    default:
      return ''
  }
}

export function parsePayMethodsChip (payMethods) {
  const firstPayMethod = payMethods.length > 0 ? payMethods[0].type : ''
  switch (firstPayMethod) {
    case 'ADVANCE':
      return <Chip
        label="Avans"
        color="secondary"
        style={{ minWidth: 80, color: 'white', backgroundColor: '#0bb10b' }}
      />
    case 'ACCOUNT':
      return <Chip
        label="Virman"
        color="primary"
        style={{ minWidth: 80 }}
      />
    case 'BANKNOTE':
      return <Chip
        label="Keš"
        style={{ backgroundColor: '#ce8a0f', color: 'white', minWidth: 80 }}
      />
    case 'CARD':
      return <Chip
        label="Kartica"
        style={{ backgroundColor: '#b9bb20', color: 'white', minWidth: 80 }}
      />
    default:
      return ''
  }
}

export function parseInvoiceTypeChip (invoiceType) {
  switch (invoiceType) {
    case 'ADVANCE':
      return <Chip
        label="Avansni"
        color="secondary"
        style={{ minWidth: 80, color: 'white', backgroundColor: '#0bb10b' }}
      />
    case 'INVOICE':
      return <Chip
        label="Faktura"
        color="primary"
        style={{ minWidth: 80 }}
      />
    case 'CREDIT_NOTE':
      return <Chip
        label="Knjižno odobrenje"
        color="default"
        style={{ minWidth: 80 }}
      />
    default:
      return <Chip
        label="Faktura"
        color="primary"
        style={{ minWidth: 80 }}
      />
  }
}

export function dashboardInvoiceStatusIcon (status) {
  switch (status) {
    case 'SUCCESS':
      return <Check style={{ color: '#90f300' }}/>
    case 'ERROR':
      return <Error style={{ color: 'red' }}/>
    case 'STALLED':
      return <Warning style={{ color: 'orange' }}/>
    case 'IN_PROGRESS':
      return <Loop style={{ color: 'black' }}/>
    default:
      return ''
  }
}

export const dashboardDepositStatusIcon = (status) => {
  switch (status) {
    case 'SUCCESS':
      return <Check />;
    case 'ERROR':
      return <Error />;
    case 'STALLED':
      return <Warning />;
    case 'IN_PROGRESS':
      return <Loop />;
    default:
      return null;
  }
};

export const exemptFromVATType = {
  'VAT_0': 'Nema razloga',
  'VAT_CL17': 'Mjesto prometa usluga',
  'VAT_CL20': 'Poreska osnovica i ispravka poreske osnovice',
  'VAT_CL26': 'Oslobođenja od javnog interesa',
  'VAT_CL27': 'Ostala oslobođenja',
  'VAT_CL28': 'Oslobođenja kod uvoza proizvoda',
  'VAT_CL29': 'Oslobođenja kod privremenog uvoza proizvoda',
  'VAT_CL30': 'Posebna oslobođenja',
  'VAT_CL44': 'Poseban postupak oporezivanja'
}

export function firstDayInPreviousMonth (date) {
  return new Date(date.getFullYear(), date.getMonth() - 1, 1)
}

export const parseInvoiceType = (pm) => {
  switch (pm) {
    case 'INVOICE':
      return 'Faktura'
    case 'CREDIT_NOTE':
      return 'Knjižno odobrenje'
    case 'ADVANCE':
      return 'Avans'
    default:
      return 'Nepoznat'
  }
}

export const parsePaymentMethod = (pm) => {
  switch (pm) {
    case 'ACCOUNT':
      return 'Virman'
    case 'BANKNOTE':
      return 'Gotovina'
    case 'CARD':
      return 'Kartica'
    case 'ADVANCE':
      return 'Avans'
    default:
      return 'Nepoznat'
  }
}

export const useInvoicesListStyles = makeStyles({
  root: {
    minWidth: 150,
  },
  smallerPadding: {
    padding: '10px !important'
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  CorrectiveRow: {
    '& td': { color: '#fd2020' },
  },
  NameCell: {
    fontWeight: 900,
  },
})

export const useDashboardInvoicesListStyles = makeStyles({
  CorrectiveRow: {
    '& td': { color: '#fd2020' },
  }
})

export const calculateDateFrom = (date) => {
  let dateFrom
  switch (date) {
    case 0:
      dateFrom = moment().startOf('day').valueOf()
      break
    case 1:
      dateFrom = moment().subtract(7, 'days').startOf('day').valueOf()
      break
    case 2:
      dateFrom = moment().startOf('month').valueOf()
      break
    case 3:
      dateFrom = moment().startOf('year').valueOf()
      break
    case 4:
      dateFrom = moment().startOf('year').valueOf()
      break
    case 5:
      dateFrom = moment().subtract(7, 'days').startOf('day').valueOf()
      break
    case 6:
      dateFrom = moment().subtract(30, 'days').startOf('day').valueOf()
      break
    case 7:
      dateFrom = moment({ year: 2019, month: 0, day: 1 }).startOf('day').valueOf()
      break
    default:
      dateFrom = moment().startOf('day').valueOf()
  }
  return dateFrom
}

export function sleep (ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

function amounts (price, quantity, rebate, vatRate, originalPrice) {
  const pom1 = roundDecimal(price * (quantity || 1) * (1 - rebate / 100), 2)
  const pom2 = roundDecimal((pom1 / (quantity || 1)) / (1 + vatRate / 100), 6) || 0
  const upa = roundDecimal(pom2 * (1 + vatRate / 100), 6)
  const pb = roundDecimal(pom2 * quantity, 6)
  const va = roundDecimal(pb * (vatRate / 100), 6) || 0
  const pa = roundDecimal(pb + va, 2) || 0
  const upb = rebate < 100 ? roundDecimal(upa / ((1 + vatRate / 100) * (1 - rebate / 100)), 6) : roundDecimal((originalPrice || price) / (1 + vatRate / 100), 6)
  return [upa, upb, pa, pb, va]
}

export function createNewInvoiceItem (item, rebate) {
  const [upa, upb, pa, pb, va] = amounts(item.price, 0, rebate, Number(item.taxRate))

  return {
    id: item.id,
    name: item.name,
    code: item.sku,
    rebateReducing: false,
    vatRate: item.taxRate,
    unitOfMeasure: item.unitOfMeasure,
    priceWithQuantWoVAT: pb,
    amountOfVAT: va,
    priceWithVAT: pa,
    quantity: 0,
    rebate: rebate || 0,
    price: item.price,
    unitPriceWithVAT: upa,
    unitPriceWoVAT: upb,
    exemptFromVAT: 'VAT_0',
    originalPrice: item.price
  }
}

export function editInvoiceItemAmounts (item, data) {
  //check if data has changed
  if (data.price !== undefined && roundDecimal(data.price, 2) === roundDecimal(item.price, 2)) {
    return null
  } else if (data.rebate !== undefined && roundDecimal(data.rebate, 2) === roundDecimal(item.rebate, 2)) {
    return null
  } else if (data.vatRate !== undefined && roundDecimal(data.vatRate, 2) === roundDecimal(item.vatRate, 2)) {
    return null
  } else if (data.quantity !== undefined && roundDecimal(data.quantity, 3) === roundDecimal(item.quantity, 3)) {
    return null
  }

  let price = data.price !== undefined ? data.price : item.price
  let rebate = data.rebate !== undefined && !isNaN(data.rebate) ? Number(data.rebate) : item.rebate
  let vatRate = data.vatRate !== undefined && !isNaN(data.vatRate) ? Number(data.vatRate) : item.vatRate
  let quantity = data.quantity !== undefined && !isNaN(data.quantity) ? Number(data.quantity) : item.quantity
  let exemptFromVAT = data.exemptFromVAT !== undefined ? data.exemptFromVAT : item.exemptFromVAT

  const [upa, upb, pa, pb, va] = amounts(price, quantity, rebate, vatRate, item.originalPrice)

  return {
    price,
    exemptFromVAT,
    unitPriceWithVAT: upa,
    unitPriceWoVAT: upb,
    priceWithQuantWoVAT: pb,
    priceWithVAT: pa,
    amountOfVAT: va,
    quantity,
    vatRate,
    rebate
  }
}

export function roundDecimal (val, places) {
  const mod = Math.pow(10.0, places)
  return Math.round(val * mod) / mod
}

export const validateStatus = (status) => {
  return status >= 200 && status < 303
}

export const calculateInvoiceAmounts = (items) => {
  return items.reduce((acc, curr) => {
    acc = {
      amountOfVAT: roundDecimal(acc.amountOfVAT + curr.amountOfVAT, 2),
      priceWithVAT: roundDecimal(acc.priceWithVAT + curr.priceWithVAT, 2),
      priceWithoutVAT: roundDecimal(acc.priceWithoutVAT + curr.priceWithQuantWoVAT, 2),
      goodsExAmt: roundDecimal(acc.goodsExAmt + (curr.vatRate === 0 ? curr.priceWithVAT : 0), 2)
    }
    return acc
  }, { priceWithVAT: 0, priceWithoutVAT:0, amountOfVAT: 0, goodsExAmt: 0 })
}

const calculateSameTaxes = (items) => {
  const sameTaxes = items.reduce((acc, e) => {
    const existingSameTax = acc.findIndex(st => Number(st.vatRate) === Number(e.vatRate) && st.exemptFromVAT === e.exemptFromVAT);
    if (existingSameTax === -1) {
      acc.push({
        numOfItems: 1,
        exemptFromVAT: e.exemptFromVAT,
        priceBeforeVAT: roundDecimal(e.priceWithQuantWoVAT, 6),
        vatAmt: roundDecimal(e.amountOfVAT, 6),
        vatRate: Number(e.vatRate)
      })
    } else {
      acc[existingSameTax].numOfItems = acc[existingSameTax].numOfItems + 1
      acc[existingSameTax].priceBeforeVAT = roundDecimal(acc[existingSameTax].priceBeforeVAT + e.priceWithQuantWoVAT, 6)
      acc[existingSameTax].vatAmt = roundDecimal(acc[existingSameTax].vatAmt + e.amountOfVAT, 6)
    }
    return acc
  }, [])

  return sameTaxes.map(v => {
    //TODO find better way for handling VAT_0
    if (v.exemptFromVAT === 'VAT_0') delete v.exemptFromVAT
    return {
      ...v,
      priceBeforeVAT: roundDecimal(v.priceBeforeVAT, 2),
      vatAmt: roundDecimal(v.vatAmt, 2)
    }
  })
}

const parseInvoiceItems = (items) => {
  return items.map(i => {
    return {
      amountOfVAT: roundDecimal(i.amountOfVAT, 4),
      code: i.code,
      id: i.id,
      name: i.name,
      note: i.note,
      priceWithQuantWoVAT: roundDecimal(i.priceWithQuantWoVAT, 4),
      priceWithVAT: roundDecimal(i.priceWithVAT, 4),
      quantity: roundDecimal(i.quantity, 3),
      rebate: i.rebate,
      rebateReducing: i.rebateReducing,
      unitOfMeasure: i.unitOfMeasure,
      unitPriceWithVAT: roundDecimal(i.unitPriceWithVAT, 4),
      unitPriceWoVAT: roundDecimal(i.unitPriceWoVAT, 4),
      vatRate: i.vatRate
    }
  })
}

export const invoiceToJson = (invoice, items, invOrdNum, subscriber, tcr, currentTime, note) => {

  const invoiceAmounts = calculateInvoiceAmounts(items)

  const invoiceToSend = {
    contractId: invoice.contractId,
    discountAmount: 0,
    discountPercentage: invoice.discountPercentage,
    dueDate: invoice.dueDate,
    invoiceType: invoice.invoiceType,
    isReverseCharge: false,
    note,
    operatorCode: invoice.operatorCode,
    totPrice: invoiceAmounts.priceWithVAT,
    totPriceWoVAT: invoiceAmounts.priceWithoutVAT,
    totVATAmt: invoiceAmounts.amountOfVAT,
    typeOfInv: 'NONCASH',
    invOrdNum,
    yearOfIssuance: (new Date()).getFullYear().toString(),
    businUnitCode: tcr.businUnitCode,
    isIssuerInVAT: subscriber.isIssuerInVAT,
    softCode: process.env.REACT_APP_SOFT_CODE,
    issueDateTime: currentTime,
    tcrCode: tcr.TCR,
    payMethods: [{ amt: invoiceAmounts.priceWithVAT, type: 'ACCOUNT' }],
    client: invoice.client,
    seller: {
      'idNum': subscriber.taxIdentifier,
      'idType': subscriber.taxIdentifierType,
      'name': subscriber.name,
      'address': subscriber.address,
      'vatNumber': subscriber.vatNumber,
      'town': subscriber.town,
      'country': subscriber.country,
      'isIssuerInVAT': subscriber.isIssuerInVAT,
      'active': subscriber.active,
      'bankAccounts': subscriber.bankAccounts
    },
    sameTaxes: calculateSameTaxes(items),
    items: parseInvoiceItems(items)
  }

  return invoiceToSend
}

export const downloadCsv = (data, fileName) => {
  const finalFileName = fileName.endsWith('.csv') ? fileName : `${fileName}.csv`
  const a = document.createElement('a')
  a.href = URL.createObjectURL(new Blob([data], { type: 'text/csv' }))
  a.setAttribute('download', finalFileName)
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

export const createTaxRateUpdateMessage = (response) => {
  const { summary } = response;

  if (summary.errors > 0) {
    return `Ažuriranje stopa PDV-a završeno sa ${summary.errors} grešaka`;
  }

  if (summary.successfulUpdates > 0) {
    return `Uspješno ažurirane stope PDV-a za ${summary.successfulUpdates} ENU-a`;
  }

  if (summary.noChangesNeeded === summary.totalTcrs) {
    return 'Sve stope PDV-a su već ažurne';
  }

  return 'Uspješno provjerene stope PDV-a';
};