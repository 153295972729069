import React, { useCallback, useEffect, useState } from 'react'
import Typography from '@material-ui/core/Typography'
import MUIDataTable from 'mui-datatables'
import {
  Box,
  IconButton
} from '@material-ui/core'
import { useAxios } from '../../utils/hooks'
import { useHistory } from 'react-router'
import {
  getCurrentSubscriber,
  getCurrentTcr,
  getCurrentTcrData,
  getIsLoading,
  setErrorSnackbar,
  setIsLoading
} from '../mainReducer'
import { useDispatch, useSelector } from 'react-redux'
import { Add, Edit } from '@material-ui/icons'
import moment from 'moment-timezone'
import MomentUtils from '@date-io/moment'
import 'moment/locale/me'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'

const rowsPerPage = 10

export default function GoodsReceipts () {
  const history = useHistory()
  const [goodsReceipts, setGoodsReceipts] = useState([])
  const [goodsReceiptsCount, setGoodsReceiptsCount] = useState(0)
  const [from, setFrom] = useState(0)
  const [dateFrom, setDateFrom] = useState(moment().startOf('month').valueOf())
  const [dateTo, setDateTo] = useState(moment().endOf('day').valueOf())
  const [operator, setOperator] = useState(null)
  const dispatch = useDispatch()
  const currentSubscriber = useSelector(getCurrentSubscriber)
  const currentTcr = useSelector(getCurrentTcr)

  const axiosInstance = useAxios()

  const getGoodsReceipts = useCallback(() => {
    const dateFromUrl = encodeURIComponent(moment(dateFrom).tz('Europe/Podgorica').format())
    const dateToUrl = encodeURIComponent(moment(dateTo).tz('Europe/Podgorica').format())
    if (!!axiosInstance.current && currentSubscriber && currentTcr) {
      dispatch(setIsLoading(true))
      axiosInstance.current.get(`inventory/subscriber/${currentSubscriber.id}/tcr/${currentTcr.id}/goodsreceipt?from=${from}&size=${rowsPerPage}&dateFrom=${dateFromUrl}&dateTo=${dateToUrl}`)
        .then(({ data }) => {
          setGoodsReceipts(data.goodsreceipts.map(i => {
            return {
              id: i ? i.id : '',
              invNum: i ? `${i.invOrdNum}/${i.yearOfIssuance}` : '',
              issueDateTime: i ? i.issueDateTime : '',
              operator: i ? i.operatorCode : null
            }
          }))
          setGoodsReceiptsCount(data.metadata.hits)
        })
        .catch(e => {
          dispatch(setErrorSnackbar('Greška prilikom učitavanja liste prijemnica'))
        })
        .finally(() => {
          dispatch(setIsLoading(false))
        })
    }
  }, [dispatch, axiosInstance, from, currentSubscriber, currentTcr, dateFrom, dateTo, operator])

  useEffect(() => {
    getGoodsReceipts()
  }, [from, getGoodsReceipts, dateFrom, dateTo])

  const columns = [
    {
      name: 'invNum',
      label: 'RB',
      options: {},
    },
    {
      name: 'issueDateTime',
      label: 'Datum',
      options: {
        customBodyRender: value => moment(value * 1000).format('DD.MM.YYYY HH:mm')
      },
    },
    {
      name: 'operator',
      label: 'Operater',
      options: {
        customBodyRender: value => {
          if (currentTcr && currentTcr.users) {
            const user = currentTcr.users.find(u => u.operatorCode === value)
            if (user) {
              return user.name
            } else {
              return ''
            }
          } else {
            return ''
          }
        }
      },
    },
    {
      name: 'id',
      label: ' ',
      options: {
        filter: false,
        sort: false,
        empty: true,
        width: 50,
        customBodyRender: (dataIndex) => {
          return (
            <IconButton
              color="primary"
              size="small"
              component="span"
              onClick={() => {
                history.push(`/goods-receipts/${dataIndex}`)
              }}>
              <Edit/>
            </IconButton>
          )
        },
        setCellProps: () => ({ style: { width: 50 } }),
      },
    },
  ]

  const options = {
    filter: false,
    search: false,
    download: false,
    sort: false,
    print: false,
    viewColumns: false,
    responsive: 'standard',
    serverSide: true,
    count: goodsReceiptsCount,
    rowsPerPage,
    rowsPerPageOptions: [],
    sortOrder: {},
    selectableRows: 'none',
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    customToolbar: () => {
      return (
        <IconButton onClick={() => history.push(`/goods-receipts/new`)}>
          <Add/>
        </IconButton>
      )
    },
    textLabels: {
      body: {
        noMatch: 'Nema podataka',
        toolTip: 'Sortiraj',
        columnHeaderTooltip: column => `Sort for ${column.label}`
      },
      pagination: {
        next: 'Sledeća strana',
        previous: 'Prethodna strana',
        rowsPerPage: 'Redova po strani:',
        displayRows: 'od',
      },
      toolbar: {
        search: 'Pretraga',
        downloadCsv: 'Download CSV',
        print: 'Print',
        viewColumns: 'View Columns',
        filterTable: 'Filter Table',
      },
      filter: {
        all: 'All',
        title: 'FILTERS',
        reset: 'RESET',
      },
      viewColumns: {
        title: 'Show Columns',
        titleAria: 'Show/Hide Table Columns',
      },
      selectedRows: {
        text: 'row(s) selected',
        delete: 'Delete',
        deleteAria: 'Delete Selected Rows',
      },
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changePage':
          setFrom(tableState.page * rowsPerPage)
          break
        case 'search':
          setFrom(tableState.page * rowsPerPage)
          break
        default:
      }
    },
  }

  return (
    <>
      <Box display="flex" p={1}>
        <MuiPickersUtilsProvider locale="me" utils={MomentUtils}>
          <Box p={1} flexGrow={0}>
            <KeyboardDatePicker
              autoOk
              disableToolbar
              size="small"
              variant="inline"
              inputVariant="outlined"
              margin="normal"
              id="date-picker-dialog-from"
              label="Datum od"
              format="DD.MM.yyyy."
              value={moment(dateFrom)}
              onChange={(value) => {
                setFrom(0)
                setDateFrom(moment(value).startOf('day').valueOf())
              }}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </Box>
          <Box p={1} flexGrow={0}>
            <KeyboardDatePicker
              autoOk
              disableToolbar
              size="small"
              variant="inline"
              inputVariant="outlined"
              margin="normal"
              id="date-picker-dialog-to"
              label="Datum do"
              format="DD.MM.yyyy."
              value={dateTo}
              onChange={(value) => {
                setFrom(0)
                setDateTo(moment(value).endOf('day').valueOf())
              }}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </Box>
        </MuiPickersUtilsProvider>
      </Box>

      <MUIDataTable
        title={
          <Typography variant="h6">
            Lista prijemnica
          </Typography>
        }
        data={goodsReceipts}
        columns={columns}
        options={options}
      />

    </>

  )
}
