import {useMemo} from 'react';

export const useTaxRateValidation = (useAdditionalTaxRate = false) => {
  return useMemo(() => {
    const NEW_TAX_RATE_VALID_FROM = process.env.REACT_APP_NEW_TAX_RATE_VALID_FROM;

    if (!NEW_TAX_RATE_VALID_FROM || !useAdditionalTaxRate) {
      return false;
    }

    const today = new Date().setHours(0, 0, 0, 0);
    const validFromDate = new Date(NEW_TAX_RATE_VALID_FROM).setHours(0, 0, 0, 0);

    return today > validFromDate;
  }, [useAdditionalTaxRate]);
};