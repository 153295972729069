import React, { useEffect, useState } from 'react'
import {
  Container, FormControl,
  Grid, InputLabel, MenuItem, Select
} from '@material-ui/core'
import Tile from './Tile'
import { useDispatch, useSelector } from 'react-redux'
import {
  getAdminDashboardData,
  getCurrentRole,
  getDashboardAutoRefreshPeriodInSec,
  getDashboardReportPeriod,
  getSellers,
  getSubscribersFilter,
  getUser,
  setAdminDashboardData,
  setDashboardAutoRefreshPeriodInSec,
  setDashboardReportPeriod, setSellers,
  setSubscribersFilter,
} from '../mainReducer'
import {
  Business, Cancel, CheckCircle,
  Store
} from '@material-ui/icons'
import { getTheme } from '../Setting/settingsReducer'
import { useApi } from '../../api/api'
import DashboardInvoices from './DashboardInvoices'
import DashboardDeposits from './DashboardDeposits'
import DashboardSubscribersStats from "./DashboardSubscribersStats";
import OwnerDashboard from "./OwnerDashboard";

const Dashboard = () => {
  const dashboardReportPeriod = useSelector(getDashboardReportPeriod)
  const currentRole = useSelector(getCurrentRole)
  const theme = useSelector(getTheme)
  const dispatch = useDispatch()
  const api = useApi()
  const sellers = useSelector(getSellers)
  const subscribersFilter = useSelector(getSubscribersFilter)
  const user = useSelector(getUser)
  const [documentType, setDocumentType] = useState('invoice')

  useEffect(() => {
    if (currentRole && ['ADMIN'].includes(currentRole) && sellers.length === 0) {
      api.getSellers()
          .then(({ data }) => {
            dispatch(setSellers(data))
          })
          .catch(err => {
            console.log(err)
          })
    }
  }, [api, sellers, dispatch, currentRole])

  return (
      <Container maxWidth={false}>
        <OwnerDashboard />
        {['ADMIN', 'SELLER'].includes(currentRole) ?
            <>
              <DashboardSubscribersStats subscribersFilter={subscribersFilter} theme={theme} />
              <br/>
              <Grid container spacing={2}>
                <Grid item>
                  <FormControl style={{ width: 200 }}>
                    <InputLabel id="document-type">Tip dokumenta</InputLabel>
                    <Select
                        labelId="document-type"
                        id="document-type"
                        value={documentType}
                        onChange={(e) => setDocumentType(e.target.value)}
                    >
                      <MenuItem value="invoice">Računi</MenuItem>
                      <MenuItem value="deposit">Depoziti</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl style={{ width: 200 }}>
                    <InputLabel id="period">Period</InputLabel>
                    <Select
                        labelId="period"
                        id="period"
                        value={dashboardReportPeriod}
                        onChange={(e) => dispatch(setDashboardReportPeriod(e.target.value))}
                    >
                      <MenuItem value={0}>Danas</MenuItem>
                      <MenuItem value={5}>7 dana</MenuItem>
                      <MenuItem value={6}>30 dana</MenuItem>
                      <MenuItem value={4}>Trenutna godina</MenuItem>
                      <MenuItem value={7}>Od pamtivijeka</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {['ADMIN'].includes(currentRole) &&
                    <Grid item>
                      <FormControl style={{ width: 200 }}>
                        <InputLabel id="label-seller">Prodavac</InputLabel>
                        <Select
                            labelId="seller"
                            id="seller"
                            value={subscribersFilter.seller || ''}
                            onChange={(e) => dispatch(setSubscribersFilter({...subscribersFilter, seller: e.target.value}))}
                        >
                          <MenuItem value="">Svi prodavci</MenuItem>
                          {sellers.map(s => <MenuItem key={s.id} value={s.id}>{s.firstName} {s.lastName}</MenuItem>)}
                        </Select>
                      </FormControl>
                    </Grid>
                }
              </Grid>
              <br/>
              {documentType === 'invoice' ? (
                  <DashboardInvoices
                      subscribersFilter={subscribersFilter}
                      dashboardReportPeriod={dashboardReportPeriod}
                  />
              ) : (
                  <DashboardDeposits
                     subscribersFilter={subscribersFilter}
                     dashboardReportPeriod={dashboardReportPeriod}
                  />
              )}
            </> : <div/>}
      </Container>
  )
}

export default Dashboard