import React from "react";
import {
    HashRouter,
    Route,
    Switch
} from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import {MuiThemeProvider} from "@material-ui/core/styles";
import {createMuiTheme} from "@material-ui/core/styles";
import {useSelector} from 'react-redux';
import Dashboard from "./containers/Dashboard/Dashboard";
import Settings from "./containers/Setting/Settings";
import {getTheme} from "./containers/Setting/settingsReducer";
import {useKeycloak} from "@react-keycloak/web";
import {PrivateRoute} from "./utils/utils";
import LoginPage from "./containers/Login";
import Loader from "./components/Loader";
import Subscribers from "./containers/Subscribers/Subscribers";
import Subscriber from "./containers/Subscribers/Subscriber";
import NewSubscriber from "./containers/Subscribers/NewSubscriber";
import {getCurrentRole, getCurrentTcr} from "./containers/mainReducer";
import Clients from "./containers/TcrData/Clients";
import Invoices from "./containers/Invoices/Invoices";
import NewInvoice from "./containers/Invoices/NewInvoice";
import {Box, Grid} from "@material-ui/core";
import InventoryPage from "./containers/TcrData/InventoryPage";
import GoodsReceipts from './containers/GoodsReceipts/GoodsReceipts'
import GoodsReceipt from './containers/GoodsReceipts/GoodsReceipt'
import NewCreditNote from './containers/CreditNotes/NewCreditNote'

const NotFound = () => {
    return <Grid container justify = "center">
        <Box p={10}>Nema informacija</Box>
    </Grid>;
};

export default function App() {
    const {initialized} = useKeycloak()
    const theTheme = useSelector(getTheme);
    const currentRole = useSelector(getCurrentRole);
    const currentTcr = useSelector(getCurrentTcr);

    if (!initialized) {
        return <Loader/>
    }

    return (
        <MuiThemeProvider theme={createMuiTheme(theTheme)}>
            <CssBaseline/>
            <div style={{height: "100vh"}}>
                <HashRouter>
                    <Switch>
                        <PrivateRoute exact path="/settings" component={Settings} name="Podešavanja"/>
                        {
                            currentRole && ["ADMIN", "SELLER"].includes(currentRole) &&
                        <PrivateRoute exact path="/subscribers/new" component={NewSubscriber} name="Novi pretplatnik"/>}
                        {
                            currentRole && ["ADMIN", "SELLER"].includes(currentRole) &&
                            <PrivateRoute exact path="/subscribers/:id" component={Subscriber} name="Pretplatnik"/>
                        }
                        {
                            currentRole && ["ADMIN", "SELLER"].includes(currentRole) &&
                            <PrivateRoute exact path="/subscribers" component={Subscribers} name="Pretplatnici"/>
                        }
                        {
                            currentRole && ["OWNER", "ACCOUNTANT"].includes(currentRole) && currentTcr &&
                            <PrivateRoute exact path="/inventory" component={InventoryPage} name="Proizvodi"/>
                        }
                        {
                            currentRole && ["OWNER", "ACCOUNTANT"].includes(currentRole) && currentTcr &&
                            <PrivateRoute exact path="/clients" component={Clients} name="Partneri"/>
                        }
                        {
                            currentRole && ["OWNER", "ACCOUNTANT"].includes(currentRole) && currentTcr &&
                            <PrivateRoute exact path="/invoices/new" component={NewInvoice} name="Račun"/>
                        }
                        {
                            currentRole && ["OWNER", "ACCOUNTANT"].includes(currentRole) && currentTcr &&
                            <PrivateRoute exact path="/invoices" component={Invoices} name="Računi"/>
                        }
                        {
                            currentRole && ["OWNER", "ACCOUNTANT"].includes(currentRole) && currentTcr &&
                            currentTcr.useGoodsReceipt &&
                            <PrivateRoute exact path="/goods-receipts" component={GoodsReceipts} name="Prijemnice"/>
                        }
                        {
                            currentRole && ["OWNER", "ACCOUNTANT"].includes(currentRole) && currentTcr &&
                            currentTcr.useGoodsReceipt &&
                            <PrivateRoute exact path="/goods-receipts/new" component={GoodsReceipt} name="Nova prijemnica"/>
                        }
                        {
                            currentRole && ["OWNER", "ACCOUNTANT"].includes(currentRole) && currentTcr &&
                            currentTcr.useGoodsReceipt &&
                            <PrivateRoute exact path="/goods-receipts/:id" component={GoodsReceipt} name="Prijemnica"/>
                        }
                        {
                            currentRole && ["OWNER", "ACCOUNTANT"].includes(currentRole) && currentTcr &&
                            currentTcr.useCreditNotes &&
                            <PrivateRoute exact path="/credit-note/new" component={NewCreditNote} name="Novo knjižno odobrenje"/>
                        }
                        <Route exact path="/login" component={LoginPage}/>
                        <PrivateRoute exact path="/" component={Dashboard} name="Naslovna"/>
                        <PrivateRoute component={NotFound}/>
                    </Switch>
                </HashRouter>
            </div>
        </MuiThemeProvider>
    );
};