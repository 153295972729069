import React from 'react'
import QRCode from 'qrcode.react'
import moment from 'moment'
import { exemptFromVATType } from '../../utils/helpers'

export default function NonCacheInvoice ({ invoice, nextInvoiceNumber, settings, currentSubscriber, currentStore }) {

  const invoiceType = invoice && invoice.invoice && invoice.invoice.invoiceType ? invoice.invoice.invoiceType : 'INVOICE'

  let invoiceTypeDesc

  switch (invoiceType) {
    case 'INVOICE':
      invoiceTypeDesc = 'Račun-Otpremnica'
      break
    case 'ADVANCE':
      invoiceTypeDesc = 'Avansni račun'
      break
    default:
      invoiceTypeDesc = 'Račun-Otpremnica'
  }

  return (
    <div style={{ maxWidth: 860, textAlign: 'center', margin: '0 auto' }}>
      {invoice && invoice.invoice ?
        <div className="invoice-box">
          <div style={{ width: '100%', float: 'left', paddingBottom: 5 }}>
            {!settings.excludeInvoiceHeader && settings.logoFullHeader && settings.data &&
            <div style={{ float: 'left', width: '100%', height: 145, overflow: 'hidden' }}>
              <img style={{ height: 'auto', width: '100%', verticalAlign: 'middle' }} src={settings.data} alt=""/>
            </div>
            }
            {!settings.excludeInvoiceHeader && !settings.logoFullHeader && settings.data &&
            <div style={{ float: 'left', paddingRight: 20, textAlign: 'left', height: 140, width: '50%' }}>
              <img style={{ maxHeight: 140, maxWidth: 320, width: 'auto', verticalAlign: 'middle' }}
                   src={settings.data} alt=""/>
            </div>
            }
            {!settings.excludeInvoiceHeader && !settings.data &&
            <div className="seller-logo" style={{ textAlign: 'left', width: '50%' }}>
              {invoice.invoice.seller.name}<br/>
            </div>
            }
            {!settings.excludeInvoiceHeader && !settings.logoFullHeader && <div className="seller">
              {invoice.invoice.seller.address}, {invoice.invoice.seller.town}, Crna Gora<br/>
              PIB: {invoice.invoice.seller.idNum}<br/>
              {invoice.invoice.seller.vatNumber ? 'PDV: ' + invoice.invoice.seller.vatNumber : ''}{invoice.invoice.seller.vatNumber &&
            <br/>}
              {invoice.invoice.seller.bankAccounts && invoice.invoice.seller.bankAccounts.length > 0 ? 'ŽR: ' + invoice.invoice.seller.bankAccounts.join('; ') : ''}<br/>
              {currentSubscriber && currentSubscriber.phone && <span>{currentSubscriber.phone}<br/></span>}
              {currentSubscriber && currentSubscriber.note && <span>{currentSubscriber.note}</span>}
            </div>
            }
          </div>
          <div>
            <div className="client" style={{ minHeight: 110 }}>
              {invoice.invoice.client.name}<br/>
              {invoice.invoice.client.address}, {invoice.invoice.client.city}, {invoice.invoice.client.country}<br/>
              PIB: {invoice.invoice.client.idNumber}<br/>
              {invoice.invoice.client.vatNumber && <span>PDV: {invoice.invoice.client.vatNumber}<br/></span>}
            </div>
            <div className="invoice-data">
              <span className="invoice-title">{invoiceTypeDesc}</span><br/>
              <span className="invoice-number">br. {nextInvoiceNumber || invoice.invoice.invOrdNum}/{
                invoice.invoice.yearOfIssuance
              }</span><br/>
              <div style={{ fontSize: 12 }}>
                {settings && settings.contractLabel && invoice.invoice.contractId &&
                <span>{settings.contractLabel}: {invoice.invoice.contractId}<br/></span>}
                Datum: {moment(invoice.invoice.issueDateTime).format('DD.MM.yyyy.')}<br/>
                Datum prometa: {invoice.invoice?.supplyDate ? moment(invoice.invoice.supplyDate).format('DD.MM.yyyy.') : moment(invoice.invoice.issueDateTime).format('DD.MM.yyyy.')}<br/>
                Mjesto izdavanja računa: {currentStore?.city || currentSubscriber?.town}<br/>
                {invoice.invoice.dueDate && <>Valuta plaćanja: {moment(invoice.invoice.dueDate).format('DD.MM.yyyy.')}<br/></>}
              </div>
            </div>
            <div style={{width: '100%', float: 'left', fontSize: 11, lineHeight: '13px', paddingBottom: '10px'}}>
              <div style={{float: 'left', width: '26%', textAlign: 'left'}}>
                Kod operatera: {invoice.invoice.operatorCode}<br/>
                Kod softvera: {invoice.invoice.softCode}
              </div>
              <div style={{float: 'left', width: '30%', textAlign: 'left'}}>
                Kod poslovne jedinice: {invoice.invoice.businUnitCode}<br/>
                Kod ENU-a: {invoice.invoice.tcrCode}
              </div>
              <div style={{float: 'left', width: '44%', textAlign: 'left'}}>
                IKOF:<br/>
                JIKR:<br/>
              </div>
            </div>
          </div>
          <div>
            <table cellPadding="0" cellSpacing="0">
              <thead>
              <tr className="heading">
                <td className="rb">
                  RB
                </td>
                <td className="sifra">
                  Šifra
                </td>
                <td className="naziv">
                  Naziv
                </td>
                <td className="sifra">
                  JM
                </td>
                <td className="sifra">
                  Količina
                </td>
                <td className="sifra">
                  Cijena bez<br/>PDV-a
                </td>
                <td className="sifra text-right">
                  Rabat<br/>%
                </td>
                <td className="sifra text-right">
                  PDV<br/>%
                </td>
                <td className="sifra text-right">
                  Cijena sa<br/>PDV-om
                </td>
                <td className="sifra text-right">
                  Vrijednost sa<br/>PDV-om
                </td>
              </tr>
              </thead>
              <tbody>
              {invoice.invoice.items.map((i, idx) =>
                <tr className="item" key={idx}>
                  <td className="rb">{idx + 1}</td>
                  <td className="sifra">
                    {i.code}
                  </td>
                  <td className="naziv">
                    {i.name}{i.note? <span style={{float:'left', width: '100%', whiteSpace: 'pre-line'}}>({i.note})</span> : ""}
                  </td>
                  <td>
                    {i.unitOfMeasure}
                  </td>
                  <td>
                    {i.quantity}
                  </td>
                  <td>
                    {i.unitPriceWoVAT.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                  </td>
                  <td className="sifra text-right">
                    {i.rebate.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                  </td>
                  <td className="sifra text-right">
                    {i.vatRate}
                  </td>
                  <td className="sifra text-right">
                    {i.unitPriceWithVAT.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                  </td>
                  <td className="sifra text-right">
                    {i.priceWithVAT.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                  </td>
                </tr>
              )}

              </tbody>
            </table>
          </div>

          <div style={{ width: '100%', fontSize: 13, marginTop: 20 }}>
            <div style={{ float: 'left', width: '50%' }}>
              <strong>Rekapitulacija poreza po tarifama</strong><br/>
              <table style={{ fontSize: 12, width: '80%' }} cellPadding="0" cellSpacing="0">
                <thead>
                <tr>
                  <td style={{ borderBottom: '1px solid black' }}>Stopa</td>
                  <td style={{ borderBottom: '1px solid black' }}>Osnovica</td>
                  <td style={{ borderBottom: '1px solid black' }}>Porez</td>
                </tr>
                </thead>
                <tbody>
                {invoice.invoice.sameTaxes.map(i =>
                  <tr key={`${i.vatRate}${i.exemptFromVAT || ""}`}>
                    <td style={{lineHeight: '14px'}}>{i.exemptFromVAT && i.exemptFromVAT !== 'VAT_0' ? exemptFromVATType[i.exemptFromVAT] : Number(i.vatRate).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                    <td>{i.priceBeforeVAT.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                    <td>{i.vatAmt.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                  </tr>)}
                </tbody>
              </table>
            </div>
            <div
              style={{ float: 'right', width: '50%', textAlign: 'right', borderBottom: '1px solid black' }}>
              <table style={{ fontSize: 12, width: '100%' }} cellPadding="0" cellSpacing="0">
                <thead>
                <tr>
                  <td style={{ textAlign: 'right', fontWeight: 'bold' }}>Rabat</td>
                  <td style={{
                    textAlign: 'right',
                    fontWeight: 'bold'
                  }}>{invoice.invoice.discountAmount.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                </tr>
                <tr>
                  <td style={{
                    textAlign: 'right',
                    fontWeight: 'bold',
                    borderBottom: '1px solid black'
                  }}>PDV
                    osnovica
                  </td>
                  <td style={{
                    textAlign: 'right',
                    fontWeight: 'bold',
                    borderBottom: '1px solid black'
                  }}>{invoice.invoice.totPriceWoVAT.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</td>
                </tr>
                <tr>
                  <td style={{
                    textAlign: 'right',
                    fontWeight: 'bold',
                    borderBottom: '1px solid black'
                  }}>Vrijednost
                    sa PDV-om
                  </td>
                  <td style={{
                    textAlign: 'right',
                    fontWeight: 'bold',
                    borderBottom: '1px solid black'
                  }}>{invoice.invoice.totPrice.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})} &euro;</td>
                </tr>
                </thead>
              </table>
            </div>
            <div style={{ float: 'left', width: '100%', marginTop: 30 }}>
              <div dangerouslySetInnerHTML={{ __html: invoice.invoice.note || '' }}/>
            </div>
            <div style={{ float: 'left', width: '100%', marginTop: 30 }}>

              <div style={{ float: 'left', width: '33%', textAlign: 'center' }}>
                <div style={{ width: '100%', textAlign: 'center', borderBottom: '1px solid black' }}>
                  &nbsp;
                </div>
              </div>

              <div style={{ float: 'left', width: '34%', textAlign: 'center' }}>
                MP
              </div>

              <div style={{ float: 'left', width: '33%' }}>
                <div style={{ width: '100%', textAlign: 'center', borderBottom: '1px solid black' }}>
                  &nbsp;
                </div>
              </div>
            </div>
          </div>


        </div>
        :
        <span>Podaci nisu ispravni</span>
      }

    </div>
  )
}