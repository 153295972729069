import * as React from 'react'
import { Box } from '@material-ui/core'
import MaterialTable from 'material-table'
import { tableIcons } from '../../utils/material-table'

const columns = [
  { field: 'ordNum', title: 'RB', width: 150 },
  { field: 'year', title: 'Godina', width: 150 },
  { field: 'date', title: 'Datum', width: 150 }
]

export default function TcrEod ({ eod, postEod, deleteEod }) {

  return (
    <Box style={{ minHeight: 650, marginTop: 10 }}>
      <MaterialTable
        title=""
        icons={tableIcons}
        columns={columns}
        data={eod.sort((a, b) => (a.ordNum > b.ordNum) ? 1 : -1)}
        options={{
          pageSize: 10,
          emptyRowsWhenPaging: true,
          pageSizeOptions: [5, 10, 25],
          actionsColumnIndex: -1
        }}
        editable={{
          onRowDelete: oldData =>
            new Promise((resolve, reject) => {
              const deletedEod = {
                date: oldData.date,
                year: oldData.year,
                ordNum: oldData.ordNum,
                id: oldData.id,
                deleted: true
              }
              postEod(deletedEod).then(({ data }) => {
                if (data && data.id) deleteEod(data.id)
                resolve()
              }).catch(() => {
                reject()
              })
              resolve()
            }),
        }}
        localization={{
          toolbar: {
            nRowsSelected: '{0} redova odabrano',
            searchTooltip: 'Pretraga',
            searchPlaceholder: 'Pretraga',
          },
          pagination: {
            labelDisplayedRows: '{from}-{to} od {count}',
            labelRowsSelect: 'redova',
            nextAriaLabel: 'Sledeća strana',
            nextTooltip: 'Sledeća strana',
            previousAriaLabel: 'Prethodna strana',
            previousTooltip: 'Prethodna strana',
            lastAriaLabel: 'Poslednja strana',
            lastTooltip: 'Poslednja strana',
            firstAriaLabel: 'Prva strana',
            firstTooltip: 'Prva strana',
          },
          header: {
            actions: 'Akcije'
          },
          body: {
            addTooltip: 'Unos novog partnera',
            editTooltip: 'Izmjena',
            deleteTooltip: 'Brisanje',
            emptyDataSourceMessage: 'Nema rezultata pretrage',
            filterRow: {
              filterTooltip: 'Filter'
            },
            editRow: {
              deleteText: 'Da li ste sigurni?'
            }
          }
        }}

      />
    </Box>
  )
}