export const initialSubscriber = {
    active: true,
    address: "",
    certificates: [],
    country: "MNE",
    id: "",
    name: "",
    note: "",
    phone: "",
    responsiblePerson: "",
    stores: [],
    taxIdentifier: "",
    taxIdentifierType: "TIN",
    town: "",
    users: []
}

export const statesList = [
    {
        "value":"Afghanistan",
        "phoneCode":"+93",
        "alpha2code":"AF",
        "key":"AFG"
    },
    {
        "value":"Albania",
        "phoneCode":"+355",
        "alpha2code":"AL",
        "key":"ALB"
    },
    {
        "value":"Algeria",
        "phoneCode":"+213",
        "alpha2code":"DZ",
        "key":"DZA"
    },
    {
        "value":"American Samoa",
        "phoneCode":"+1",
        "alpha2code":"AS",
        "key":"ASM"
    },
    {
        "value":"Andorra",
        "phoneCode":"+376",
        "alpha2code":"AD",
        "key":"AND"
    },
    {
        "value":"Angola",
        "phoneCode":"+244",
        "alpha2code":"AO",
        "key":"AGO"
    },
    {
        "value":"Anguilla",
        "phoneCode":"+1",
        "alpha2code":"AI",
        "key":"AIA"
    },
    {
        "value":"Antigua and Barbuda",
        "phoneCode":"+1",
        "alpha2code":"AG",
        "key":"ATG"
    },
    {
        "value":"Argentina",
        "phoneCode":"+54",
        "alpha2code":"AR",
        "key":"ARG"
    },
    {
        "value":"Armenia",
        "phoneCode":"+374",
        "alpha2code":"AM",
        "key":"ARM"
    },
    {
        "value":"Aruba",
        "phoneCode":"+297",
        "alpha2code":"AW",
        "key":"ABW"
    },
    {
        "value":"Australia",
        "phoneCode":"+61",
        "alpha2code":"AU",
        "key":"AUS"
    },
    {
        "value":"Austria",
        "phoneCode":"+43",
        "alpha2code":"AT",
        "key":"AUT"
    },
    {
        "value":"Azerbaijan",
        "phoneCode":"+994",
        "alpha2code":"AZ",
        "key":"AZE"
    },
    {
        "value":"Bahrain",
        "phoneCode":"+973",
        "alpha2code":"BH",
        "key":"BHR"
    },
    {
        "value":"Bangladesh",
        "phoneCode":"+880",
        "alpha2code":"BD",
        "key":"BGD"
    },
    {
        "value":"Barbados",
        "phoneCode":"+1",
        "alpha2code":"BB",
        "key":"BRB"
    },
    {
        "value":"Belarus",
        "phoneCode":"+375",
        "alpha2code":"BY",
        "key":"BLR"
    },
    {
        "value":"Belgium",
        "phoneCode":"+32",
        "alpha2code":"BE",
        "key":"BEL"
    },
    {
        "value":"Belize",
        "phoneCode":"+501",
        "alpha2code":"BZ",
        "key":"BLZ"
    },
    {
        "value":"Benin",
        "phoneCode":"+229",
        "alpha2code":"BJ",
        "key":"BEN"
    },
    {
        "value":"Bermuda",
        "phoneCode":"+1",
        "alpha2code":"BM",
        "key":"BMU"
    },
    {
        "value":"Bhutan",
        "phoneCode":"+975",
        "alpha2code":"BT",
        "key":"BTN"
    },
    {
        "value":"Bolivia",
        "phoneCode":"+591",
        "alpha2code":"BO",
        "key":"BOL"
    },
    {
        "value":"Bonaire, Sint Eustatius and Saba",
        "phoneCode":"+599",
        "alpha2code":"BQ",
        "key":"BES"
    },
    {
        "value":"Bosnia and Herzegovina",
        "phoneCode":"+387",
        "alpha2code":"BA",
        "key":"BIH"
    },
    {
        "value":"Botswana",
        "phoneCode":"+267",
        "alpha2code":"BW",
        "key":"BWA"
    },
    {
        "value":"Brazil",
        "phoneCode":"+55",
        "alpha2code":"BR",
        "key":"BRA"
    },
    {
        "value":"British Indian Ocean Territory",
        "phoneCode":"+246",
        "alpha2code":"IO",
        "key":"IOT"
    },
    {
        "value":"British Virgin Islands",
        "phoneCode":"+1",
        "alpha2code":"VG",
        "key":"VGB"
    },
    {
        "value":"Brunei Darussalam",
        "phoneCode":"+673",
        "alpha2code":"BN",
        "key":"BRN"
    },
    {
        "value":"Bulgaria",
        "phoneCode":"+359",
        "alpha2code":"BG",
        "key":"BGR"
    },
    {
        "value":"Burkina Faso",
        "phoneCode":"+226",
        "alpha2code":"BF",
        "key":"BFA"
    },
    {
        "value":"Myanmar",
        "phoneCode":"+95",
        "alpha2code":"MM",
        "key":"MMR"
    },
    {
        "value":"Burundi",
        "phoneCode":"+257",
        "alpha2code":"BI",
        "key":"BDI"
    },
    {
        "value":"Cambodia",
        "phoneCode":"+855",
        "alpha2code":"KH",
        "key":"KHM"
    },
    {
        "value":"Cameroon",
        "phoneCode":"+237",
        "alpha2code":"CM",
        "key":"CMR"
    },
    {
        "value":"Canada",
        "phoneCode":"+1",
        "alpha2code":"CA",
        "key":"CAN"
    },
    {
        "value":"Cabo Verde",
        "phoneCode":"+238",
        "alpha2code":"CV",
        "key":"CPV"
    },
    {
        "value":"Cayman Islands",
        "phoneCode":"+1",
        "alpha2code":"KY",
        "key":"CYM"
    },
    {
        "value":"Central African Republic",
        "phoneCode":"+236",
        "alpha2code":"CF",
        "key":"CAF"
    },
    {
        "value":"Chad",
        "phoneCode":"+235",
        "alpha2code":"ID",
        "key":"TCD"
    },
    {
        "value":"Chile",
        "phoneCode":"+56",
        "alpha2code":"CL",
        "key":"CHL"
    },
    {
        "value":"China",
        "phoneCode":"+86",
        "alpha2code":"CN",
        "key":"CHN"
    },
    {
        "value":"Colombia",
        "phoneCode":"+57",
        "alpha2code":"CO",
        "key":"COL"
    },
    {
        "value":"Comoros",
        "phoneCode":"+269",
        "alpha2code":"KM",
        "key":"COM"
    },
    {
        "value":"Cook Islands",
        "phoneCode":"+682",
        "alpha2code":"CK",
        "key":"COK"
    },
    {
        "value":"Costa Rica",
        "phoneCode":"+506",
        "alpha2code":"CR",
        "key":"CRI"
    },
    {
        "value":"Côte dIvoire",
        "phoneCode":"+225",
        "alpha2code":"CI",
        "key":"CIV"
    },
    {
        "value":"Croatia",
        "phoneCode":"+385",
        "alpha2code":"HR",
        "key":"HRV"
    },
    {
        "value":"Cuba",
        "phoneCode":"+53",
        "alpha2code":"CU",
        "key":"CUB"
    },
    {
        "value":"Curaçao",
        "phoneCode":"+599",
        "alpha2code":"CW",
        "key":"CUW"
    },
    {
        "value":"Cyprus",
        "phoneCode":"+357",
        "alpha2code":"CY",
        "key":"CYP"
    },
    {
        "value":"Czechia",
        "phoneCode":"+420",
        "alpha2code":"CZ",
        "key":"CZE"
    },
    {
        "value":"Denmark",
        "phoneCode":"+45",
        "alpha2code":"DK",
        "key":"DNK"
    },
    {
        "value":"Djibouti",
        "phoneCode":"+253",
        "alpha2code":"DJ",
        "key":"DJI"
    },
    {
        "value":"Dominica",
        "phoneCode":"+1",
        "alpha2code":"DM",
        "key":"DMA"
    },
    {
        "value":"Dominican Republic",
        "phoneCode":"+1",
        "alpha2code":"DO",
        "key":"DOM"
    },
    {
        "value":"Ecuador",
        "phoneCode":"+593",
        "alpha2code":"EC",
        "key":"ECU"
    },
    {
        "value":"Egypt",
        "phoneCode":"+20",
        "alpha2code":"EG",
        "key":"EGY"
    },
    {
        "value":"El Salvador",
        "phoneCode":"+503",
        "alpha2code":"SV",
        "key":"SLV"
    },
    {
        "value":"Equatorial Guinea",
        "phoneCode":"+240",
        "alpha2code":"GQ",
        "key":"GNQ"
    },
    {
        "value":"Eritrea",
        "phoneCode":"+291",
        "alpha2code":"ER",
        "key":"ERI"
    },
    {
        "value":"Estonia",
        "phoneCode":"+372",
        "alpha2code":"EE",
        "key":"EST"
    },
    {
        "value":"Ethiopia",
        "phoneCode":"+251",
        "alpha2code":"ET",
        "key":"ETH"
    },
    {
        "value":"Falkland Islands",
        "phoneCode":"+500",
        "alpha2code":"FK",
        "key":"FLK"
    },
    {
        "value":"Faroe Islands",
        "phoneCode":"+298",
        "alpha2code":"FO",
        "key":"FRO"
    },
    {
        "value":"Federated States of Micronesia",
        "phoneCode":"+691",
        "alpha2code":"FM",
        "key":"FSM"
    },
    {
        "value":"Fiji",
        "phoneCode":"+679",
        "alpha2code":"FJ",
        "key":"FJI"
    },
    {
        "value":"Finland",
        "phoneCode":"+358",
        "alpha2code":"FI",
        "key":"FIN"
    },
    {
        "value":"France",
        "phoneCode":"+33",
        "alpha2code":"FR",
        "key":"FRA"
    },
    {
        "value":"French Guiana",
        "phoneCode":"+594",
        "alpha2code":"GF",
        "key":"GUF"
    },
    {
        "value":"French Polynesia",
        "phoneCode":"+689",
        "alpha2code":"PF",
        "key":"PYF"
    },
    {
        "value":"Gabon",
        "phoneCode":"+241",
        "alpha2code":"GA",
        "key":"GAB"
    },
    {
        "value":"Georgia",
        "phoneCode":"+995",
        "alpha2code":"GE",
        "key":"GEO"
    },
    {
        "value":"Germany",
        "phoneCode":"+49",
        "alpha2code":"DE",
        "key":"DEU"
    },
    {
        "value":"Ghana",
        "phoneCode":"+233",
        "alpha2code":"GH",
        "key":"GHA"
    },
    {
        "value":"Gibraltar",
        "phoneCode":"+350",
        "alpha2code":"GI",
        "key":"GIB"
    },
    {
        "value":"Greece",
        "phoneCode":"+30",
        "alpha2code":"GR",
        "key":"GRC"
    },
    {
        "value":"Greenland",
        "phoneCode":"+299",
        "alpha2code":"GL",
        "key":"GRL"
    },
    {
        "value":"Grenada",
        "phoneCode":"+1",
        "alpha2code":"GD",
        "key":"GRD"
    },
    {
        "value":"Guadeloupe",
        "phoneCode":"+590",
        "alpha2code":"GP",
        "key":"GLP"
    },
    {
        "value":"Guam",
        "phoneCode":"+1",
        "alpha2code":"GU",
        "key":"GUM"
    },
    {
        "value":"Guatemala",
        "phoneCode":"+502",
        "alpha2code":"GT",
        "key":"GTM"
    },
    {
        "value":"Guinea",
        "phoneCode":"+224",
        "alpha2code":"GN",
        "key":"GIN"
    },
    {
        "value":"Guinea-Bissau",
        "phoneCode":"+245",
        "alpha2code":"GW",
        "key":"GNB"
    },
    {
        "value":"Guyana",
        "phoneCode":"+592",
        "alpha2code":"GY",
        "key":"GUY"
    },
    {
        "value":"Haiti",
        "phoneCode":"+509",
        "alpha2code":"HT",
        "key":"HTI"
    },
    {
        "value":"Honduras",
        "phoneCode":"+504",
        "alpha2code":"HN",
        "key":"HND"
    },
    {
        "value":"Hong Kong",
        "phoneCode":"+852",
        "alpha2code":"HK",
        "key":"HKG"
    },
    {
        "value":"Hungary",
        "phoneCode":"+36",
        "alpha2code":"HU",
        "key":"HUN"
    },
    {
        "value":"Iceland",
        "phoneCode":"+354",
        "alpha2code":"IS",
        "key":"ISL"
    },
    {
        "value":"India",
        "phoneCode":"+91",
        "alpha2code":"IN",
        "key":"IND"
    },
    {
        "value":"Indonesia",
        "phoneCode":"+62",
        "alpha2code":"ID",
        "key":"IDN"
    },
    {
        "value":"Iran",
        "phoneCode":"+98",
        "alpha2code":"IR",
        "key":"IRN"
    },
    {
        "value":"Iraq",
        "phoneCode":"+964",
        "alpha2code":"IQ",
        "key":"IRQ"
    },
    {
        "value":"Ireland",
        "phoneCode":"+353",
        "alpha2code":"IE",
        "key":"IRL"
    },
    {
        "value":"Israel",
        "phoneCode":"+972",
        "alpha2code":"IL",
        "key":"ISR"
    },
    {
        "value":"Italy",
        "phoneCode":"+39",
        "alpha2code":"IT",
        "key":"ITA"
    },
    {
        "value":"Jamaica",
        "phoneCode":"+1",
        "alpha2code":"JM",
        "key":"JAM"
    },
    {
        "value":"Japan",
        "phoneCode":"+81",
        "alpha2code":"JP",
        "key":"JPN"
    },
    {
        "value":"Jordan",
        "phoneCode":"+962",
        "alpha2code":"JO",
        "key":"JOR"
    },
    {
        "value":"Kazakhstan",
        "phoneCode":"+7",
        "alpha2code":"KZ",
        "key":"KAZ"
    },
    {
        "value":"Kenya",
        "phoneCode":"+254",
        "alpha2code":"KE",
        "key":"KEN"
    },
    {
        "value":"Kiribati",
        "phoneCode":"+686",
        "alpha2code":"KI",
        "key":"KIR"
    },
    {
        "value":"Kosovo",
        "phoneCode":"+383",
        "alpha2code":"XK",
        "key":"RKS"
    },
    {
        "value":"Kuwait",
        "phoneCode":"+965",
        "alpha2code":"KW",
        "key":"KWT"
    },
    {
        "value":"Kyrgyzstan",
        "phoneCode":"+996",
        "alpha2code":"KG",
        "key":"KGZ"
    },
    {
        "value":"Laos",
        "phoneCode":"+856",
        "alpha2code":"LA",
        "key":"LAO"
    },
    {
        "value":"Latvia",
        "phoneCode":"+371",
        "alpha2code":"LV",
        "key":"LVA"
    },
    {
        "value":"Lebanon",
        "phoneCode":"+961",
        "alpha2code":"LB",
        "key":"LBN"
    },
    {
        "value":"Lesotho",
        "phoneCode":"+266",
        "alpha2code":"LS",
        "key":"LSO"
    },
    {
        "value":"Liberia",
        "phoneCode":"+231",
        "alpha2code":"LR",
        "key":"LBR"
    },
    {
        "value":"Libya",
        "phoneCode":"+218",
        "alpha2code":"LY",
        "key":"LBY"
    },
    {
        "value":"Liechtenstein",
        "phoneCode":"+423",
        "alpha2code":"LI",
        "key":"LIE"
    },
    {
        "value":"Lithuania",
        "phoneCode":"+370",
        "alpha2code":"LT",
        "key":"LTU"
    },
    {
        "value":"Luxembourg",
        "phoneCode":"+352",
        "alpha2code":"LU",
        "key":"LUX"
    },
    {
        "value":"Macao",
        "phoneCode":"+853",
        "alpha2code":"MO",
        "key":"MAC"
    },
    {
        "value":"Macedonia",
        "phoneCode":"+389",
        "alpha2code":"MK",
        "key":"MKD"
    },
    {
        "value":"Madagascar",
        "phoneCode":"+261",
        "alpha2code":"MG",
        "key":"MDG"
    },
    {
        "value":"Malawi",
        "phoneCode":"+265",
        "alpha2code":"MW",
        "key":"MWI"
    },
    {
        "value":"Malaysia",
        "phoneCode":"+60",
        "alpha2code":"MY",
        "key":"MYS"
    },
    {
        "value":"Maldives",
        "phoneCode":"+960",
        "alpha2code":"MV",
        "key":"MDV"
    },
    {
        "value":"Mali",
        "phoneCode":"+223",
        "alpha2code":"ML",
        "key":"MLI"
    },
    {
        "value":"Malta",
        "phoneCode":"+356",
        "alpha2code":"MT",
        "key":"MLT"
    },
    {
        "value":"Marshall Islands",
        "phoneCode":"+692",
        "alpha2code":"MH",
        "key":"MHL"
    },
    {
        "value":"Martinique",
        "phoneCode":"+596",
        "alpha2code":"MQ",
        "key":"MTQ"
    },
    {
        "value":"Mauritania",
        "phoneCode":"+222",
        "alpha2code":"MR",
        "key":"MRT"
    },
    {
        "value":"Mauritius",
        "phoneCode":"+230",
        "alpha2code":"MU",
        "key":"MUS"
    },
    {
        "value":"Mayotte",
        "phoneCode":"+262",
        "alpha2code":"YT",
        "key":"MYT"
    },
    {
        "value":"Mexico",
        "phoneCode":"+52",
        "alpha2code":"MX",
        "key":"MEX"
    },
    {
        "value":"Moldova",
        "phoneCode":"+373",
        "alpha2code":"MD",
        "key":"MDA"
    },
    {
        "value":"Monaco",
        "phoneCode":"+377",
        "alpha2code":"MC",
        "key":"MCO"
    },
    {
        "value":"Mongolia",
        "phoneCode":"+976",
        "alpha2code":"MN",
        "key":"MNG"
    },
    {
        "value":"Crna Gora",
        "phoneCode":"+382",
        "alpha2code":"ME",
        "key":"MNE"
    },
    {
        "value":"Montserrat",
        "phoneCode":"+1",
        "alpha2code":"MS",
        "key":"MSR"
    },
    {
        "value":"Morocco",
        "phoneCode":"+212",
        "alpha2code":"MA",
        "key":"MAR"
    },
    {
        "value":"Mozambique",
        "phoneCode":"+258",
        "alpha2code":"MZ",
        "key":"MOZ"
    },
    {
        "value":"Namibia",
        "phoneCode":"+264",
        "alpha2code":"NA",
        "key":"NAM"
    },
    {
        "value":"Nauru",
        "phoneCode":"+674",
        "alpha2code":"NR",
        "key":"NRU"
    },
    {
        "value":"Nepal",
        "phoneCode":"+977",
        "alpha2code":"NP",
        "key":"NPL"
    },
    {
        "value":"Netherlands",
        "phoneCode":"+31",
        "alpha2code":"NL",
        "key":"NLD"
    },
    {
        "value":"Netherlands Antilles",
        "phoneCode":"+599",
        "alpha2code":"AN",
        "key":"ANT"
    },
    {
        "value":"New Caledonia",
        "phoneCode":"+687",
        "alpha2code":"NC",
        "key":"NCL"
    },
    {
        "value":"New Zealand",
        "phoneCode":"+64",
        "alpha2code":"NZ",
        "key":"NZL"
    },
    {
        "value":"Nicaragua",
        "phoneCode":"+505",
        "alpha2code":"NI",
        "key":"NIC"
    },
    {
        "value":"Niger",
        "phoneCode":"+227",
        "alpha2code":"NE",
        "key":"NER"
    },
    {
        "value":"Nigeria",
        "phoneCode":"+234",
        "alpha2code":"NG",
        "key":"NGA"
    },
    {
        "value":"Niue",
        "phoneCode":"+683",
        "alpha2code":"NU",
        "key":"NIU"
    },
    {
        "value":"Norfolk Island",
        "phoneCode":"+672",
        "alpha2code":"NF",
        "key":"NFK"
    },
    {
        "value":"North Korea",
        "phoneCode":"+850",
        "alpha2code":"KP",
        "key":"PRK"
    },
    {
        "value":"Northern Mariana Islands",
        "phoneCode":"+1",
        "alpha2code":"MP",
        "key":"MNP"
    },
    {
        "value":"Norway",
        "phoneCode":"+47",
        "alpha2code":"NO",
        "key":"NOR"
    },
    {
        "value":"Oman",
        "phoneCode":"+968",
        "alpha2code":"OM",
        "key":"OMN"
    },
    {
        "value":"Pakistan",
        "phoneCode":"+92",
        "alpha2code":"PK",
        "key":"PAK"
    },
    {
        "value":"Palau",
        "phoneCode":"+680",
        "alpha2code":"PW",
        "key":"PLW"
    },
    {
        "value":"Palestine",
        "phoneCode":"+970",
        "alpha2code":"PS",
        "key":"PSE"
    },
    {
        "value":"Panama",
        "phoneCode":"+507",
        "alpha2code":"PA",
        "key":"PAN"
    },
    {
        "value":"Papua New Guinea",
        "phoneCode":"+675",
        "alpha2code":"PG",
        "key":"PNG"
    },
    {
        "value":"Paraguay",
        "phoneCode":"+595",
        "alpha2code":"PY",
        "key":"PRY"
    },
    {
        "value":"Peru",
        "phoneCode":"+51",
        "alpha2code":"PE",
        "key":"PER"
    },
    {
        "value":"Philippines",
        "phoneCode":"+63",
        "alpha2code":"PH",
        "key":"PHL"
    },
    {
        "value":"Poland",
        "phoneCode":"+48",
        "alpha2code":"PL",
        "key":"POL"
    },
    {
        "value":"Portugal",
        "phoneCode":"+351",
        "alpha2code":"PT",
        "key":"PRT"
    },
    {
        "value":"Puerto Rico",
        "phoneCode":"+1",
        "alpha2code":"PR",
        "key":"PRI"
    },
    {
        "value":"Qatar",
        "phoneCode":"+974",
        "alpha2code":"QA",
        "key":"QAT"
    },
    {
        "value":"Congo",
        "phoneCode":"+242",
        "alpha2code":"CG",
        "key":"COG"
    },
    {
        "value":"Réunion",
        "phoneCode":"+262",
        "alpha2code":"RE",
        "key":"REU"
    },
    {
        "value":"Romania",
        "phoneCode":"+40",
        "alpha2code":"RO",
        "key":"ROU"
    },
    {
        "value":"Russia",
        "phoneCode":"+7",
        "alpha2code":"RU",
        "key":"RUS"
    },
    {
        "value":"Rwanda",
        "phoneCode":"+250",
        "alpha2code":"RW",
        "key":"RWA"
    },
    {
        "value":"Saint Barthélemy",
        "phoneCode":"+590",
        "alpha2code":"BL",
        "key":"BLM"
    },
    {
        "value":"Saint Helena",
        "phoneCode":"+290",
        "alpha2code":"SH",
        "key":"SHN"
    },
    {
        "value":"Saint Kitts and Nevis",
        "phoneCode":"+1",
        "alpha2code":"KN",
        "key":"KNA"
    },
    {
        "value":"Saint Martin",
        "phoneCode":"+590",
        "alpha2code":"MF",
        "key":"MAF"
    },
    {
        "value":"Saint Pierre and Miquelon",
        "phoneCode":"+508",
        "alpha2code":"PM",
        "key":"SPM"
    },
    {
        "value":"Saint Vincent and the Grenadines",
        "phoneCode":"+1",
        "alpha2code":"VC",
        "key":"VCT"
    },
    {
        "value":"Samoa",
        "phoneCode":"+685",
        "alpha2code":"WS",
        "key":"WSM"
    },
    {
        "value":"San Marino",
        "phoneCode":"+378",
        "alpha2code":"SM",
        "key":"SMR"
    },
    {
        "value":"São Tomé and Príncipe",
        "phoneCode":"+239",
        "alpha2code":"ST",
        "key":"STP"
    },
    {
        "value":"Saudi Arabia",
        "phoneCode":"+966",
        "alpha2code":"SA",
        "key":"SAU"
    },
    {
        "value":"Senegal",
        "phoneCode":"+221",
        "alpha2code":"SN",
        "key":"SEN"
    },
    {
        "value":"Serbia",
        "phoneCode":"+381",
        "alpha2code":"RS",
        "key":"SRB"
    },
    {
        "value":"Seychelles",
        "phoneCode":"+248",
        "alpha2code":"SC",
        "key":"SYC"
    },
    {
        "value":"Sierra Leone",
        "phoneCode":"+232",
        "alpha2code":"SL",
        "key":"SLE"
    },
    {
        "value":"Singapore",
        "phoneCode":"+65",
        "alpha2code":"SG",
        "key":"SGP"
    },
    {
        "value":"Sint Maarten (Dutch part)",
        "phoneCode":"+1",
        "alpha2code":"SX",
        "key":"SXM"
    },
    {
        "value":"Slovakia",
        "phoneCode":"+421",
        "alpha2code":"SK",
        "key":"SVK"
    },
    {
        "value":"Slovenia",
        "phoneCode":"+386",
        "alpha2code":"SI",
        "key":"SVN"
    },
    {
        "value":"Solomon Islands",
        "phoneCode":"+677",
        "alpha2code":"SB",
        "key":"SLB"
    },
    {
        "value":"Somalia",
        "phoneCode":"+252",
        "alpha2code":"SO",
        "key":"SOM"
    },
    {
        "value":"South Africa",
        "phoneCode":"+27",
        "alpha2code":"ZA",
        "key":"ZAF"
    },
    {
        "value":"South Korea",
        "phoneCode":"+82",
        "alpha2code":"KR",
        "key":"KOR"
    },
    {
        "value":"South Sudan",
        "phoneCode":"+211",
        "alpha2code":"SS",
        "key":"SSD"
    },
    {
        "value":"Spain",
        "phoneCode":"+34",
        "alpha2code":"ES",
        "key":"ESP"
    },
    {
        "value":"Sri Lanka",
        "phoneCode":"+94",
        "alpha2code":"LK",
        "key":"LKA"
    },
    {
        "value":"Saint Lucia",
        "phoneCode":"+1",
        "alpha2code":"LC",
        "key":"LCA"
    },
    {
        "value":"Sudan",
        "phoneCode":"+249",
        "alpha2code":"SD",
        "key":"SDN"
    },
    {
        "value":"Suriname",
        "phoneCode":"+597",
        "alpha2code":"SR",
        "key":"SUR"
    },
    {
        "value":"Swaziland",
        "phoneCode":"+268",
        "alpha2code":"SZ",
        "key":"SWZ"
    },
    {
        "value":"Sweden",
        "phoneCode":"+46",
        "alpha2code":"SE",
        "key":"SWE"
    },
    {
        "value":"Switzerland",
        "phoneCode":"+41",
        "alpha2code":"CH",
        "key":"CHE"
    },
    {
        "value":"Syrian Arab Republic",
        "phoneCode":"+963",
        "alpha2code":"SY",
        "key":"SYR"
    },
    {
        "value":"Taiwan",
        "phoneCode":"+886",
        "alpha2code":"TW",
        "key":"TWN"
    },
    {
        "value":"Tajikistan",
        "phoneCode":"+992",
        "alpha2code":"TJ",
        "key":"TJK"
    },
    {
        "value":"Tanzania",
        "phoneCode":"+255",
        "alpha2code":"TZ",
        "key":"TZA"
    },
    {
        "value":"Thailand",
        "phoneCode":"+66",
        "alpha2code":"TH",
        "key":"THA"
    },
    {
        "value":"Bahamas",
        "phoneCode":"+1",
        "alpha2code":"BS",
        "key":"BHS"
    },
    {
        "value":"Gambia",
        "phoneCode":"+220",
        "alpha2code":"GM",
        "key":"GMB"
    },
    {
        "value":"Timor-Leste",
        "phoneCode":"+670",
        "alpha2code":"TL",
        "key":"TLS"
    },
    {
        "value":"Togo",
        "phoneCode":"+228",
        "alpha2code":"TG",
        "key":"TGO"
    },
    {
        "value":"Tokelau",
        "phoneCode":"+690",
        "alpha2code":"TK",
        "key":"TKL"
    },
    {
        "value":"Tonga",
        "phoneCode":"+676",
        "alpha2code":"TO",
        "key":"TON"
    },
    {
        "value":"Trinidad and Tobago",
        "phoneCode":"+1",
        "alpha2code":"TT",
        "key":"TTO"
    },
    {
        "value":"Tunisia",
        "phoneCode":"+216",
        "alpha2code":"TN",
        "key":"TUN"
    },
    {
        "value":"Turkey",
        "phoneCode":"+90",
        "alpha2code":"TR",
        "key":"TUR"
    },
    {
        "value":"Turkmenistan",
        "phoneCode":"+993",
        "alpha2code":"TM",
        "key":"TKM"
    },
    {
        "value":"Turks and Caicos Islands",
        "phoneCode":"+1",
        "alpha2code":"TC",
        "key":"TCA"
    },
    {
        "value":"Tuvalu",
        "phoneCode":"+688",
        "alpha2code":"TV",
        "key":"TUV"
    },
    {
        "value":"Uganda",
        "phoneCode":"+256",
        "alpha2code":"UG",
        "key":"UGA"
    },
    {
        "value":"Ukraine",
        "phoneCode":"+380",
        "alpha2code":"UA",
        "key":"UKR"
    },
    {
        "value":"United Arab Emirates",
        "phoneCode":"+971",
        "alpha2code":"AE",
        "key":"ARE"
    },
    {
        "value":"United Kingdom",
        "phoneCode":"+44",
        "alpha2code":"GB",
        "key":"GBR"
    },
    {
        "value":"United States of America",
        "phoneCode":"+1",
        "alpha2code":"US",
        "key":"USA"
    },
    {
        "value":"Uruguay",
        "phoneCode":"+598",
        "alpha2code":"UY",
        "key":"URY"
    },
    {
        "value":"U.S. Virgin Islands",
        "phoneCode":"+1",
        "alpha2code":"VI",
        "key":"VIR"
    },
    {
        "value":"Uzbekistan",
        "phoneCode":"+998",
        "alpha2code":"UZ",
        "key":"UZB"
    },
    {
        "value":"Vanuatu",
        "phoneCode":"+678",
        "alpha2code":"VU",
        "key":"VUT"
    },
    {
        "value":"Vatican City",
        "phoneCode":"+39",
        "alpha2code":"VA",
        "key":"VAT"
    },
    {
        "value":"Venezuela",
        "phoneCode":"+58",
        "alpha2code":"VE",
        "key":"VEN"
    },
    {
        "value":"Vietnam",
        "phoneCode":"+84",
        "alpha2code":"VN",
        "key":"VNM"
    },
    {
        "value":"Wallis and Futuna",
        "phoneCode":"+681",
        "alpha2code":"WF",
        "key":"WLF"
    },
    {
        "value":"Yemen",
        "phoneCode":"+967",
        "alpha2code":"YE",
        "key":"YEM"
    },
    {
        "value":"Zambia",
        "phoneCode":"+260",
        "alpha2code":"ZM",
        "key":"ZMB"
    },
    {
        "value":"Zimbabwe",
        "phoneCode":"+263",
        "alpha2code":"ZW",
        "key":"ZWE"
    }
]