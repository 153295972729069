import moment from 'moment-timezone'
import { InvoiceItemModel } from './InvoiceItemModel'

export class InvoiceModel {

  constructor () {
    this.yearOfIssuance = ''
    this.businUnitCode = ''
    this.issueDateTime = ''
    this.supplyDate = null
    this.invOrdNum = 0
    this.contractId = ''
    this.isIssuerInVAT = true
    this.isReverseCharge = false
    this.operatorCode = ''
    this.softCode = ''
    this.tcrCode = ''
    this.totPrice = 0.0
    this.totPriceWoVAT = 0.0
    this.totVATAmt = 0.0
    this.typeOfInv = 'NONCASH'
    this.invoiceType = 'INVOICE'
    this.payMethods = []
    this.goodsExAmt = 0.0
    this.seller = null
    this.items = [new InvoiceItemModel()]
    this.sameTaxes = []
    this.client = null
    this.discountAmount = 0.0
    this.dueDate = moment().tz('Europe/Podgorica').format()
    this.discountPercentage = 0
    this.note = ''
  }

  fromJSON (invoice) {
    Object.assign(this, invoice)
    this.items = invoice.items.map(i => new InvoiceItemModel(i))
  }

  update (updatedInvoice) {
    Object.assign(this, updatedInvoice)

    if (updatedInvoice.discountPercentage !== undefined && updatedInvoice.changeAll === true) {
      this.items.forEach(i => {
        i.update({
          rebate: Number(Number(updatedInvoice.discountPercentage).toFixed(2))
        })
      })
    }

    if (updatedInvoice.items || (updatedInvoice.discountPercentage !== undefined && updatedInvoice.changeAll === true)) {
      let totVATAmt = 0.0
      let totWoVAT = 0.0
      let totDiscount = 0.0

      this.items.forEach((e) => {
        if (e.id && e.quantity > 0) {
          if (e.rebate > 0) {
            totDiscount = this.roundDecimal(totDiscount + ((e.price * e.quantity) - e.priceWithVAT), 2)
          }
        }
      })

      //same taxes
      const sameTaxes = this.items.reduce((acc, e) => {
        const existingSameTax = acc.findIndex(st => Number(st.vatRate) === Number(e.vatRate) && st.exemptFromVAT === e.exemptFromVAT);
        if (existingSameTax === -1) {
          acc.push({
            numOfItems: 1,
            exemptFromVAT: e.exemptFromVAT,
            priceBeforeVAT: this.roundDecimal(e.priceWithQuantWoVAT, 6),
            vatAmt: this.roundDecimal(e.amountOfVAT, 6),
            vatRate: Number(e.vatRate)
          })
        } else {
          acc[existingSameTax].numOfItems = acc[existingSameTax].numOfItems + 1
          acc[existingSameTax].priceBeforeVAT = this.roundDecimal(acc[existingSameTax].priceBeforeVAT + e.priceWithQuantWoVAT, 6)
          acc[existingSameTax].vatAmt = this.roundDecimal(acc[existingSameTax].vatAmt + e.amountOfVAT, 6)
        }
        return acc
      }, [])

      this.sameTaxes = sameTaxes.map(v => {
        return {
          ...v,
          priceBeforeVAT: this.roundDecimal(v.priceBeforeVAT, 4),
          vatAmt: this.roundDecimal(v.vatAmt, 4)
        }
      })

      let goodsExAmt = 0;
      this.sameTaxes.forEach((st) => {
        if (st.vatRate === 0) {
          goodsExAmt += this.roundDecimal(st.priceBeforeVAT, 4)
        }
        totVATAmt = this.roundDecimal(totVATAmt + st.vatAmt, 4)
        totWoVAT = this.roundDecimal(totWoVAT + st.priceBeforeVAT, 4)
      })

      this.goodsExAmt = this.roundDecimal(goodsExAmt, 2)
      this.totPriceWoVAT = this.roundDecimal(totWoVAT, 2)
      this.totVATAmt = this.roundDecimal(totVATAmt, 2)
      this.totPrice = this.roundDecimal(this.roundDecimal(totWoVAT, 2) + this.roundDecimal(totVATAmt, 2), 2)
      this.discountAmount = this.roundDecimal(totDiscount, 2)

    }
  }

  roundDecimal (val, places) {
    const mod = Math.pow(10.0, places)
    return Math.round(val * mod) / mod
  }

  getCorrectiveInvoice (iic) {
    const correctiveInvoice = {
      ...this,
      yearOfIssuance: (new Date()).getFullYear().toString(),
      dueDate: moment().tz('Europe/Podgorica').format(),
      issueDateTime: moment().tz('Europe/Podgorica').format(),
      totPrice: -this.totPrice,
      totPriceWoVAT: -this.totPriceWoVAT,
      totVATAmt: -this.totVATAmt,
      payMethods: this.payMethods.map(i => {
        return {
          amt: -i.amt,
          type: i.type
        }
      }),
      sameTaxes: this.sameTaxes.map(i => {
        return {
          ...i,
          priceBeforeVAT: -i.priceBeforeVAT,
          vatAmt: -i.vatAmt
        }
      }),
      items: this.items.map(i => {
        const item = {
          ...i,
          priceWithVAT: -i.priceWithVAT,
          priceWithQuantWoVAT: -i.priceWithQuantWoVAT,
          quantity: -i.quantity,
          amountOfVAT: -i.amountOfVAT
        }

        if (item.exemptFromVAT === 'VAT_0') {
          delete item.exemptFromVAT
        }

        return item
      })
    }

    if (correctiveInvoice.client.country === 'MNE') {
      delete correctiveInvoice.goodsExAmt
    }
    if (!correctiveInvoice.dueDate) {
      delete correctiveInvoice.dueDate
    }

    // have to add IICRefs to corrective invoice of old invoices with pay method ADVANCE
    if (correctiveInvoice.payMethods && correctiveInvoice.payMethods.length > 0 && correctiveInvoice.payMethods[0].type === 'ADVANCE') {
      correctiveInvoice.payMethods =
        [{
          ...correctiveInvoice.payMethods[0],
          advIIC: iic
        }]
    }

    //always delete supplyDate on correctiveInvoice
    delete correctiveInvoice.supplyDate
    return correctiveInvoice
  }

  toJson () {
    const invoice = this
    if (this.client.country === 'MNE') {
      delete invoice.goodsExAmt
    }
    if (!invoice.supplyDate || invoice.invoiceType !== 'INVOICE') {
      delete invoice.supplyDate
    }
    if (!invoice.dueDate) {
      delete invoice.dueDate
    }

    delete invoice.changeAll

    return {
      ...invoice,
      sameTaxes: this.sameTaxes.map(i => {
        const item = {
          ...i,
        }
        if (item.exemptFromVAT === 'VAT_0') delete item.exemptFromVAT
        return item
      }),
      items: this.items.filter(i => i.quantity > 0)
        .map(i => {
          const item = {
            ...i,
            vatRate: Number(i.vatRate),
            name: i.name.replace(/[\r\n\t]/g, ""),
            priceWithVAT: this.roundDecimal(i.priceWithVAT, 4),
            priceWithQuantWoVAT: this.roundDecimal(i.priceWithQuantWoVAT, 4),
            unitPriceWithVAT: this.roundDecimal(i.unitPriceWithVAT, 4),
            unitPriceWoVAT: this.roundDecimal(i.unitPriceWoVAT, 4),
            amountOfVAT: this.roundDecimal(i.amountOfVAT, 4)
          }
          if (item.exemptFromVAT === 'VAT_0') delete item.exemptFromVAT
          delete item.price
          delete item.originalVatRate

          return item
        })
    }
  }

}

