import React, {useRef} from 'react';
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Divider,
    FormControl, InputLabel, Select, MenuItem,
    useTheme,
    colors
} from '@material-ui/core';
import moment from "moment";
import {useDispatch, useSelector} from "react-redux";
import {getDashboardLast7Days, getDashboardReportPeriod, setDashboardReportPeriod} from "../mainReducer";
import {Bar} from "react-chartjs-2";

const Sales = ({ className, ...rest }) => {
    const theme = useTheme();
    const dashboardReportPeriod = useSelector(getDashboardReportPeriod);
    const dashboardLast7Days = useSelector(getDashboardLast7Days);
    const graphContainerRef = useRef();

    const dispatch = useDispatch();

    const data = {
        datasets: [
            {
                backgroundColor: theme.palette.primary.main,
                data: dashboardLast7Days.map(d => Number(d.totPrice.toFixed(2))),
                label: 'Ukupno'
            },
            {
                backgroundColor: colors.grey[200],
                data: dashboardLast7Days.map(d => d.itemsCount),
                label: 'Br. računa'
            }
        ],
        labels: dashboardLast7Days.map(d => moment(d.key).format(dashboardReportPeriod === 0 ? 'HH:mm' : 'DD.MM.')),
    };

    const options = {
        animation: false,
        cornerRadius: 20,
        layout: { padding: 0 },
        legend: { display: false },
        maintainAspectRatio: false,
        responsive: true,
        scales: {
            xAxes: [
                {
                    barThickness: 12,
                    maxBarThickness: 10,
                    barPercentage: 0.5,
                    categoryPercentage: 0.5,
                    ticks: {
                        fontColor: theme.palette.text.secondary
                    },
                    gridLines: {
                        display: false,
                        drawBorder: false
                    }
                }
            ],
            yAxes: [
                {
                    ticks: {
                        fontColor: theme.palette.text.secondary,
                        beginAtZero: true,
                    },
                    gridLines: {
                        borderDash: [2],
                        borderDashOffset: [2],
                        color: theme.palette.divider,
                        drawBorder: false,
                        zeroLineBorderDash: [2],
                        zeroLineBorderDashOffset: [2],
                        zeroLineColor: theme.palette.divider
                    }
                }
            ]
        },
        tooltips: {
            backgroundColor: theme.palette.background.default,
            bodyFontColor: theme.palette.text.secondary,
            borderColor: theme.palette.divider,
            borderWidth: 1,
            enabled: true,
            footerFontColor: theme.palette.text.secondary,
            intersect: false,
            mode: 'index',
            titleFontColor: theme.palette.text.primary
        }
    };

    return (
        <Card
            {...rest}
        >
            <CardHeader
                title="Promet za period"
                action={(
                    <FormControl style={{width: 200}}>
                        <InputLabel id="period">Period</InputLabel>
                        <Select
                            style={{minWidth: '100%'}}
                            labelId="period"
                            name="period"
                            id="period"
                            value={dashboardReportPeriod}
                            onChange={event => {
                                dispatch(setDashboardReportPeriod(event.target.value));
                            }}
                        >
                            <MenuItem value={0}>Danas</MenuItem>
                            <MenuItem value={1}>Poslednjih 7 dana</MenuItem>
                            <MenuItem value={2}>Trenutni mjesec</MenuItem>
                            <MenuItem value={3}>Trenutna godina</MenuItem>
                        </Select>
                    </FormControl>
                )}
            />
            <Divider />
            <CardContent>
                <Box
                    height={400}
                    position="relative"
                    ref={graphContainerRef}
                    style={{width: '100%'}}>
                    <Bar
                        data={data}
                        options={options}
                    />
                </Box>
            </CardContent>
            <Divider />
            <Box
                display="flex"
                justifyContent="flex-end"
                p={2}
            >
            </Box>
        </Card>
    );
};

export default Sales;
