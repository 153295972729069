import * as React from 'react';
import {
    AppBar,
    Box, Button,
    Tab, Tabs
} from "@material-ui/core";
import {useState} from "react";
import TabPanel from "../../components/TabPanel";
import Template from "./Template";
import {TemplateModel} from "../../models/TemplateModel";
import { Add } from '@material-ui/icons'

const ButtonInTabs = ({className, onClick, children}) => {
    return <Button className={className} onClick={onClick} children={children}/>;
};

export default function TemplateList({content, setContent, subscriberId}) {

    const [tabIndex, setTabIndex] = useState(0);

    const handleTabIndexChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    return (
        <Box>
            <AppBar position="static" color="default">
                <Tabs
                    value={tabIndex}
                    onChange={handleTabIndexChange}
                    scrollButtons="on"
                    variant="scrollable"
                    aria-label="scrollable force tabs tcrs"
                >
                    {content && content.templates && content.templates.map(s => {
                        return <Tab key={`tab_${s.id}`}
                                    label={s.name}/>
                    })}
                    <ButtonInTabs
                        onClick={() => {
                            setTabIndex(content && content.templates && content.templates.length > 0 ? content.templates.length : 0);
                        }}
                    >
                        <Add color="secondary"/>
                    </ButtonInTabs>
                </Tabs>
            </AppBar>
            {content && content.templates && content.templates.map((t, idx) => {
                return <TabPanel key={`tabpanel_${idx}`} value={tabIndex} index={idx}>
                    <Box p={2}>
                        <Template content={content} initialTemplate={t} setContent={setContent}
                             subscriberId={subscriberId}/>
                    </Box>
                </TabPanel>
            })}
            <TabPanel key={`tabpanel_new`} value={tabIndex} index={content && content.templates && content.templates.length > 0 ? content.templates.length : 0}>
                <Box p={2}>
                    <Template content={content} setContent={setContent} initialTemplate={new TemplateModel()} subscriberId={subscriberId}/>
                </Box>
            </TabPanel>

        </Box>
    );
}
