import { useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import { useKeycloak } from '@react-keycloak/web';

const baseURL = process.env.REACT_APP_API_URL;

export const useAxios = () => {
  const axiosInstanceRef = useRef();
  const { keycloak, initialized } = useKeycloak();

  const createAxiosInstance = useCallback(() => {
    const instance = axios.create({ baseURL });

    instance.interceptors.request.use(config => {
      if (keycloak.token) {
        config.headers.authorization = `Bearer ${keycloak.token}`;
      }
      return config;
    });

    instance.interceptors.response.use(
        response => response,
        async error => {
          const originalRequest = error.config;
          if (
              (error.code === 'ERR_NETWORK' || (error.response && [401, 403].includes(error.response.status))) &&
              !originalRequest._retry &&
              keycloak.token
          ) {
            originalRequest._retry = true;
            try {
              await keycloak.updateToken(5);
              originalRequest.headers.authorization = `Bearer ${keycloak.token}`;
              return axios(originalRequest);
            } catch (refreshError) {
              console.error('Token refresh failed:', refreshError);
              keycloak.logout();
              return Promise.reject(refreshError);
            }
          }
          return Promise.reject(error);
        }
    );

    return instance;
  }, [keycloak]);

  useEffect(() => {
    if (initialized && keycloak.token) {
      axiosInstanceRef.current = createAxiosInstance();
    }

    return () => {
      axiosInstanceRef.current = undefined;
    };
  }, [initialized, keycloak.token, createAxiosInstance]);

  return axiosInstanceRef;
};