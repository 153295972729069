import {v4 as uuidv4} from "uuid";

export class TemplateModel {
    
    constructor() {
        this.id = uuidv4();
        this.name = '';
        this.jsonSchema = '';
        this.uiSchema = '';
        this.template = '';
    }

    update(updatedTemplate) {

        this.id = updatedTemplate.id !== undefined ? updatedTemplate.id : this.id;
        this.name = updatedTemplate.name !== undefined ? updatedTemplate.name : this.name;
        this.jsonSchema = updatedTemplate.jsonSchema !== undefined ? updatedTemplate.jsonSchema : this.jsonSchema;
        this.uiSchema = updatedTemplate.uiSchema !== undefined ? updatedTemplate.uiSchema : this.uiSchema;
        this.template = updatedTemplate.template !== undefined ? updatedTemplate.template : this.template;
    }

}

