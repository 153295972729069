import React, { useEffect, useState } from 'react'
import { useAxios } from '../../utils/hooks'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentSubscriber, getCurrentTcr, getCurrentTcrData, setIsLoading } from '../mainReducer'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import Grid from '@material-ui/core/Grid'
import moment from 'moment-timezone'
import {Add, BarChart, Delete} from '@material-ui/icons'
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, IconButton
} from '@material-ui/core'
import ItemTurnoverReport from './ItemTurnoverReport'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { Autocomplete } from '@material-ui/lab'
import TextField from '@material-ui/core/TextField'
import MUIDataTable from 'mui-datatables'
import MaterialTable from "material-table";
import {downloadCsv} from "../../utils/helpers";
import jsPDF from "jspdf";
import {callAddFont} from "../../utils/Roboto-Regular-normal";
import { localization, tableIcons } from '../../utils/material-table'
import Edit from "@material-ui/icons/Edit";

const columns = [
  {
    name: 'sku',
    label: 'Šifra',
    options: {}
  },
  {
    name: 'name',
    label: 'Naziv',
    options: {}
  },
  {
    name: 'totalQuantity',
    label: 'Količina',
    options: {
      sortCompare: (order) => {
        return (obj1, obj2) => {
          let val1 = parseInt(obj1.data, 10)
          let val2 = parseInt(obj2.data, 10)
          return (val1 - val2) * (order === 'asc' ? 1 : -1)
        }
      }
    }
  },
  {
    name: 'totalPriceWithoutVAT',
    label: 'Ukupno bez PDV-a',
    options: {
      sortCompare: (order) => {
        return (obj1, obj2) => {
          let val1 = parseInt(obj1.data, 10)
          let val2 = parseInt(obj2.data, 10)
          return (val1 - val2) * (order === 'asc' ? 1 : -1)
        }
      }
    }
  },
  {
    name: 'totalAmountOfVAT',
    label: 'Ukupno poreza',
    options: {
      sortCompare: (order) => {
        return (obj1, obj2) => {
          let val1 = parseInt(obj1.data, 10)
          let val2 = parseInt(obj2.data, 10)
          return (val1 - val2) * (order === 'asc' ? 1 : -1)
        }
      }
    }
  },
  {
    name: 'totalPriceWithVAT',
    label: 'Ukupno',
    options: {
      sortCompare: (order) => {
        return (obj1, obj2) => {
          let val1 = parseInt(obj1.data, 10)
          let val2 = parseInt(obj2.data, 10)
          return (val1 - val2) * (order === 'asc' ? 1 : -1)
        }
      }
    }
  },
  {
    name: 'totalDiscount',
    label: 'Ukupno popusta',
    options: {
      sortCompare: (order) => {
        return (obj1, obj2) => {
          let val1 = parseInt(obj1.data, 10)
          let val2 = parseInt(obj2.data, 10)
          return (val1 - val2) * (order === 'asc' ? 1 : -1)
        }
      }
    }
  }
]

const options = {
  filter: false,
  search: true,
  download: false,
  sort: true,
  print: true,
  viewColumns: false,
  responsive: 'standard',
  serverSide: false,
  rowsPerPageOptions: [],
  sortOrder: {},
  selectableRows: 'none',
  selectableRowsHeader: false,
  selectableRowsHideCheckboxes: true,
  pagination: false,
  textLabels: {
    body: {
      noMatch: 'Nema podataka',
      toolTip: 'Sortiraj',
      columnHeaderTooltip: column => `Sort for ${column.label}`
    },
    pagination: {
      next: 'Sledeća strana',
      previous: 'Prethodna strana',
      rowsPerPage: 'Redova po strani:',
      displayRows: 'od',
    },
    toolbar: {
      search: 'Pretraga',
      downloadCsv: 'Download CSV',
      print: 'Print',
      viewColumns: 'View Columns',
      filterTable: 'Filter Table',
    },
    filter: {
      all: 'All',
      title: 'FILTERS',
      reset: 'RESET',
    },
    viewColumns: {
      title: 'Show Columns',
      titleAria: 'Show/Hide Table Columns',
    },
    selectedRows: {
      text: 'row(s) selected',
      delete: 'Delete',
      deleteAria: 'Delete Selected Rows',
    },
  },

}

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
})

export default function ItemsTurnoverReport ({ subscriberId, tcrId }) {
  const classes = useStyles()
  const axiosInstance = useAxios()
  const dispatch = useDispatch()
  const [summaryData, setSummaryData] = useState()
  const [reportData, setReportData] = useState()
  const [dateFrom, setDateFrom] = useState(moment().startOf('month').valueOf())
  const [dateTo, setDateTo] = useState(moment().endOf('day').valueOf())
  const [status, setStatus] = useState({ id: 'SUCCESS,STALLED', name: 'Svi' })
  const [paymentMethod, setPaymentMethod] = useState({ id: 'ALL', name: 'Svi' })
  const [operator, setOperator] = useState(null)
  const [openItemReport, setOpenItemReport] = React.useState(false)
  const [currentItemForReport, setCurrentItemForReport] = useState(null)
  const [buyer, setBuyer] = useState(null)
  const currentSubscriber = useSelector(getCurrentSubscriber)
  const currentTcr = useSelector(getCurrentTcr)
  const currentTcrData = useSelector(getCurrentTcrData)

  const inventoryHashMap = (currentTcrData && currentTcrData.inventory && currentTcrData.inventory.length > 0 ? currentTcrData.inventory : []).reduce(function (map, obj) {
    map[obj.id] = obj
    return map
  }, {})

  const handleCloseItemReport = () => {
    setOpenItemReport(false)
  }

  useEffect(() => {
    const dateFromUrl = encodeURIComponent(moment(dateFrom).tz('Europe/Podgorica').format())
    const dateToUrl = encodeURIComponent(moment(dateTo).tz('Europe/Podgorica').format())
    if (!!axiosInstance.current) {
      dispatch(setIsLoading(true))
      setReportData(null)
      axiosInstance.current.get(`report/subscriber/${subscriberId}/tcr/${tcrId}/_items_turnover?simplified=true&size=1000&dateFrom=${dateFromUrl}&dateTo=${dateToUrl}${buyer ? '&clientID=' + buyer.id : ''}${paymentMethod && paymentMethod.id !== 'ALL' ? '&payMethodType=' + paymentMethod.id : ''}${status ? '&status=' + status.id : ''}${operator ? '&operatorCode=' + operator.operatorCode : ''}`)
        .then(({ data }) => {

          const summary = data.summary

          const table = data.inventory.map(i => {
            return {
              ...i,
              sku: inventoryHashMap[i.id] ? inventoryHashMap[i.id].sku : ''
            }
          })

          setSummaryData(summary)
          setReportData(table)
        })
        .finally(() => {
          dispatch(setIsLoading(false))
        })
    }
  }, [dateFrom, dateTo, axiosInstance, dispatch, subscriberId, tcrId, status.id, operator, buyer, paymentMethod])

  return (
    <>
      <Box>
        <Grid container>
          <MuiPickersUtilsProvider locale="me" utils={MomentUtils}>
            <Grid item style={{ width: '180px' }}>
              <KeyboardDatePicker
                autoOk
                disableToolbar
                size="small"
                variant="inline"
                inputVariant="outlined"
                margin="normal"
                id="date-picker-dialog-from"
                label="Datum od"
                format="DD.MM.yyyy."
                value={moment(dateFrom)}
                onChange={(value) => {
                  setDateFrom(moment(value).startOf('day').valueOf())
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </Grid>
            <Grid item style={{ width: '180px' }}>
              <KeyboardDatePicker
                autoOk
                disableToolbar
                size="small"
                variant="inline"
                inputVariant="outlined"
                margin="normal"
                id="date-picker-dialog-to"
                label="Datum do"
                format="DD.MM.yyyy."
                value={dateTo}
                onChange={(value) => {
                  setDateTo(moment(value).endOf('day').valueOf())
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </Grid>
          </MuiPickersUtilsProvider>

          <Grid item style={{ width: 200 }}>
            <Autocomplete
              id="combo-box-status"
              size="small"
              fullWidth
              disableClearable
              options={[
                { id: 'SUCCESS,STALLED', name: 'Svi' },
                { id: 'SUCCESS', name: 'Fiskalizovani' },
                { id: 'STALLED', name: 'Nefiskalizovani' },
              ]}
              value={status}
              getOptionSelected={(option, value) => option.id === value.id}
              getOptionLabel={(option) => option.name}
              style={{ marginTop: 16, paddingLeft: 5, paddingRight: 5 }}
              onChange={(event, value) => {
                setStatus(value)
              }}
              renderInput={(params) =>
                <TextField {...params}
                           label="Status"
                           variant="outlined"/>}/>
          </Grid>
          <Grid item style={{ width: 200 }}>
            <Autocomplete
              id="combo-box-buyer"
              size="small"
              fullWidth
              options={currentTcrData && currentTcrData.clients && currentTcrData.clients.length ? currentTcrData.clients.filter(c => !c.deleted).sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1) : []}
              getOptionSelected={(option, value) => option.id === value.id}
              getOptionLabel={(option) => option.name}
              style={{ marginTop: 16, paddingLeft: 5, paddingRight: 5 }}
              onChange={(event, value) => {
                setBuyer(value)
              }}
              renderInput={(params) =>
                <TextField {...params}
                           label="Partner"
                           variant="outlined"/>}/>
          </Grid>
          <Grid item style={{ width: 200 }}>
            {currentTcr && currentTcr.users &&
            <Autocomplete
              id="combo-box-operator"
              size="small"
              style={{ marginTop: 16, paddingLeft: 5, paddingRight: 5 }}
              options={currentTcr && currentTcr.users ? currentTcr.users.filter(u => !u.deleted) : []}
              getOptionSelected={(option, value) => option.id === value.id}
              getOptionLabel={(option) => option.name}
              value={operator}
              onChange={(event, value) => {
                setOperator(value)
              }}
              renderInput={(params) =>
                <TextField {...params}
                           label="Operater"
                           variant="outlined"/>}/>

            }
          </Grid>
          <Grid item style={{ width: 200 }}>
            <Autocomplete
              id="combo-box-payment-method"
              size="small"
              fullWidth
              disableClearable
              options={[
                { id: 'ALL', name: 'Svi' },
                { id: 'BANKNOTE', name: 'Gotovina' },
                { id: 'CARD', name: 'Kartica' },
                { id: 'ACCOUNT', name: 'Virman' },
              ]}
              value={paymentMethod}
              getOptionSelected={(option, value) => option.id === value.id}
              getOptionLabel={(option) => option.name}
              style={{ marginTop: 16, paddingLeft: 5, paddingRight: 5 }}
              onChange={(event, value) => {
                setPaymentMethod(value)
              }}
              renderInput={(params) =>
                <TextField {...params}
                           label="Način plaćanja"
                           variant="outlined"/>}/>
          </Grid>
        </Grid>
      </Box>

      <Grid container>
        {summaryData &&
        <Grid item xs={12}>
          <Box style={{ marginBottom: 10 }}>
            <Grid container>
              <Grid item xs={3} md={3} style={{ padding: 5 }}>
                <Card className={classes.root} variant="outlined">
                  <CardContent>
                    <Typography variant="h5" component="h2">
                      {summaryData.totalDiscount && summaryData.totalDiscount.value && summaryData.totalDiscount.value.toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}
                    </Typography>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      UKUPNO POPUSTA
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={3} md={3} style={{ padding: 5 }}>
                <Card className={classes.root} variant="outlined">
                  <CardContent>
                    <Typography variant="h5" component="h2">
                      {summaryData.totalPriceWithoutVAT && summaryData.totalPriceWithoutVAT.value && summaryData.totalPriceWithoutVAT.value.toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}
                    </Typography>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      UKUPNO BEZ PDV
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={3} md={3} style={{ padding: 5 }}>
                <Card className={classes.root} variant="outlined">
                  <CardContent>
                    <Typography variant="h5" component="h2">
                      {summaryData.totalAmountOfVAT && summaryData.totalAmountOfVAT.value && summaryData.totalAmountOfVAT.value.toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}
                    </Typography>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      UKUPNO PDV
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={3} md={3} style={{ padding: 5 }}>
                <Card className={classes.root} variant="outlined">
                  <CardContent>
                    <Typography variant="h5" component="h2">
                      {summaryData.totalPriceWithVAT && summaryData.totalPriceWithVAT.value && summaryData.totalPriceWithVAT.value.toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}
                    </Typography>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      UKUPNO
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        }

        {reportData &&
        <Grid item xs={12} style={{ minHeight: 450, marginBottom: 15 }}>
          {/*<MUIDataTable
            title=""
            data={reportData.sort(function (a, b) {
              return a.sku.toString().localeCompare(b.sku, undefined, {
                numeric: true,
                sensitivity: 'base'
              })
            })}
            columns={[...columns, ...actions]}
            options={options}
          />*/}
          <MaterialTable
            title=""
            data={reportData.sort(function (a, b) {
              return a.sku.toString().localeCompare(b.sku, undefined, {
                numeric: true,
                sensitivity: 'base'
              })
            })}
            columns={columns.map(column => ({
              title: column.label,
              field: column.name,
            }))}
            localization={localization}
            actions={[
              {
                icon: () => <BarChart/>,
                tooltip: 'Izvještaj',
                onClick: ((event, rowData) => {
                  setOpenItemReport(true)
                  setCurrentItemForReport(rowData)
                }),
                position: 'row'
              }
            ]}
            icons={tableIcons}
            options={{
              rowStyle: rowData => {
                if (!rowData.deleted) {
                  return {}
                }
                return { backgroundColor: '#ffbaba' }
              },
              pageSize: reportData.length || 250,
              emptyRowsWhenPaging: false,
              pageSizeOptions: [reportData.length, 50, 100, 250, 500, 1000],
              actionsColumnIndex: -1,
              exportButton: true,
              exportAllData: true,
              exportCsv: (columns, data) => {
                const headerRow = ["Sifra", "Naziv", "Cijena", "PDV", "jm"];
                const dataRows = data.filter(i => !i.deleted).map( d => [d.sku, d.name, Number(d.price), Number(d.taxRate), d.unitOfMeasure]);
                const delimiter = ',';
                const csvContent = [headerRow, ...dataRows].map(e => e.join(delimiter)).join('\n')
                const csvFileName = "Artikli";
                downloadCsv(csvContent, csvFileName);
              },
              exportPdf: (columns, data) => {
                jsPDF.API.events.push(["addFonts", callAddFont]);

                // Create a categories map for quick lookup
                const categoriesMap = currentTcrData.categories?.reduce((acc, c) => ({ ...acc, [c.id]: c.name }), {}) || {};
                const totalCategories = categoriesMap ? Object.keys(categoriesMap).length : 0;

                // Combine map, filter, and forEach into a single reduce function
                const inventoryFromReport = currentTcrData.inventory.reduce((acc, i) => {
                  const reportItem = reportData.find(r => r.id === i.id);
                  if (reportItem) {
                    acc.push({
                      ...i,
                      category: categoriesMap[i.categoryId] || null,
                      totalQuantity: reportItem.totalQuantity,
                      totalPriceWithoutVAT: reportItem.totalPriceWithoutVAT,
                      totalAmountOfVAT: reportItem.totalAmountOfVAT,
                      totalPriceWithVAT: reportItem.totalPriceWithVAT,
                      totalDiscount: reportItem.totalDiscount
                    });
                  }
                  return acc;
                }, []);

                const sortedData = inventoryFromReport.sort((a, b) => {
                  // Items without a category should go at the end
                  if (!a.categoryId && b.categoryId) {
                    return 1;
                  }
                  if (a.categoryId && !b.categoryId) {
                    return -1;
                  }
                  if (!a.categoryId && !b.categoryId) {
                    return 0;
                  }

                  // Sort by categoryId
                  const categoryComparison = a.categoryId.localeCompare(b.categoryId);
                  if (categoryComparison !== 0) {
                    return categoryComparison;
                  }

                  // If categories are the same, sort by sku as a number
                  return a.sku.toString().localeCompare(b.sku, undefined, {
                    numeric: true,
                    sensitivity: 'base'
                  });
                });

                const dataWithCategoryRows = sortedData.reduce((acc, item, index, array) => {
                  if (totalCategories > 1 && (index === 0 || (index > 0 && array[index - 1].category !== item.category))) {
                    acc.push({ isCategory: true, category: item.category })
                  }
                  acc.push(item)
                  if (totalCategories > 1 && (index === array.length - 1 || (index < array.length - 1 && array[index + 1].category !== item.category))) {
                    const categoryItems = array.filter(i => i.category === item.category)
                    const totalQuantity = categoryItems.reduce((acc, i) => acc + parseFloat(i.totalQuantity), 0)
                    const totalPriceWithoutVAT = categoryItems.reduce((acc, i) => acc + parseFloat(i.totalPriceWithoutVAT), 0)
                    const totalAmountOfVAT = categoryItems.reduce((acc, i) => acc + parseFloat(i.totalAmountOfVAT), 0)
                    const totalPriceWithVAT = categoryItems.reduce((acc, i) => acc + parseFloat(i.totalPriceWithVAT), 0)
                    const totalDiscount = categoryItems.reduce((acc, i) => acc + parseFloat(i.totalDiscount), 0)
                    acc.push({ isCategorySummary: true, category: 'Ukupno', totalQuantity, totalPriceWithoutVAT, totalAmountOfVAT, totalPriceWithVAT, totalDiscount })
                  }
                  // add last row as total of all items
                  if (index === array.length - 1) {
                    const totalQuantity = summaryData.totalQuantity.value;
                    const totalPriceWithoutVAT = summaryData.totalPriceWithoutVAT.value;
                    const totalAmountOfVAT = summaryData.totalAmountOfVAT.value;
                    const totalPriceWithVAT = summaryData.totalPriceWithVAT.value;
                    const totalDiscount = summaryData.totalDiscount.value;
                    acc.push({ isTotal: true, totalQuantity, totalPriceWithoutVAT, totalAmountOfVAT, totalPriceWithVAT, totalDiscount })
                  }
                  return acc
                }, []);

                const doc = new jsPDF();
                doc.setFont('Roboto-Regular', 'normal');
                doc.setFontSize(12);

                const pdfData = dataWithCategoryRows.map(rowData => {
                  if (rowData.isCategory) {
                    return ['Grupa:', rowData.category || '', '', '', '', '']
                  } else if (rowData.isCategorySummary) {
                    return ['', '      Ukupno', rowData.totalQuantity.toFixed(2), rowData.totalPriceWithoutVAT.toFixed(2), rowData.totalAmountOfVAT.toFixed(2), rowData.totalPriceWithVAT.toFixed(2), rowData.totalDiscount.toFixed(2)]
                  } else if (rowData.isTotal) {
                    return ['Ukupno:', '', rowData.totalQuantity.toFixed(2), rowData.totalPriceWithoutVAT.toFixed(2), rowData.totalAmountOfVAT.toFixed(2), rowData.totalPriceWithVAT.toFixed(2), rowData.totalDiscount.toFixed(2)]
                  }
                  return [rowData.sku, rowData.name, rowData.totalQuantity, rowData.totalPriceWithoutVAT, rowData.totalAmountOfVAT, rowData.totalPriceWithVAT, rowData.totalDiscount]
                });

                doc.autoTable({
                  styles: {
                    font: 'Roboto-Regular',
                    fontStyle: 'normal',
                  },
                  columnStyles: {
                    0: { fillColor: [105, 105, 105] } // Set the background color for the header row
                  },
                  willDrawCell: function (data) {
                    const firstCell = data.row.raw[0]?.toString();
                    const secondCell = data.row.raw[1]?.toString();
                    // Check if the row is a header
                    if (data.section === 'head') {
                      doc.setFillColor(105, 105, 105);
                    }
                    // Check if the row is a category
                    else if (firstCell?.includes('Grupa')) {
                      doc.setFillColor(169, 169, 169); // Set the cell background color to a light blue
                    }
                    // Check if the row is a category summary
                    else if (secondCell?.includes('Ukupno')) {
                      doc.setFillColor(211, 211, 211); // Set the cell background color to a light green
                    } else {
                      doc.setFillColor(255, 255, 255); // Set the cell background color to white for other rows
                    }
                  },
                  head: [columns],
                  body: pdfData,
                });

                doc.save(`promet_u_periodu_${moment(dateFrom).tz('Europe/Podgorica').format("DD.MM.yyyy")}-${moment(dateTo).tz('Europe/Podgorica').format("DD.MM.yyyy")}.pdf`);
              }
            }}
          />
        </Grid>}

        {currentItemForReport && currentSubscriber && currentTcr && openItemReport &&
        <Dialog open={openItemReport} onClose={handleCloseItemReport} aria-labelledby="form-dialog-report"
                fullWidth={true} fullScreen
                maxWidth={'md'}>
          <DialogTitle id="form-dialog-report">Izvještaj za proizvod {currentItemForReport.name}</DialogTitle>
          <DialogContent>
            <ItemTurnoverReport itemId={currentItemForReport.id} subscriberId={currentSubscriber.id}
                                tcrId={currentTcr.id}/>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseItemReport} variant="outlined" color="primary">
              Zatvori
            </Button>

          </DialogActions>
        </Dialog>
        }
      </Grid>
    </>
  )
}