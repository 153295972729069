import React from 'react'
import Typography from '@material-ui/core/Typography'
import MUIDataTable from 'mui-datatables'
import moment from 'moment-timezone'
import { CheckBox, CheckBoxOutlineBlank, Print } from '@material-ui/icons'
import { parseInvoiceTypeChip } from '../../utils/helpers'
import { Box } from '@material-ui/core'
import { Pagination } from '@material-ui/lab'

const CreditNoteIICRefs = ({ IICRefs, setIICRefs, invoices, from, setFrom, invoicesCount, rowsPerPage }) => {

  const options = {
    filter: false,
    search: false,
    download: false,
    sort: false,
    pagination: false,
    print: false,
    viewColumns: false,
    responsive: 'standard',
    serverSide: true,
    count: IICRefs.length,
    rowsPerPage: invoices.length || 1,
    rowsPerPageOptions: [],
    sortOrder: {},
    selectableRows: 'none',
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: false,
    /*    customToolbar: () => {
          return (
            <IconButton onClick={() => history.push(`/invoices/new`)}>
              <Add/>
            </IconButton>
          )
        },*/
    textLabels: {
      body: {
        noMatch: 'Nema podataka',
        toolTip: 'Sortiraj',
        columnHeaderTooltip: column => `Sort for ${column.label}`
      },
      pagination: {
        next: 'Sledeća strana',
        previous: 'Prethodna strana',
        rowsPerPage: 'Redova po strani:',
        displayRows: 'od',
      },
      toolbar: {
        search: 'Pretraga',
        downloadCsv: 'Download CSV',
        print: 'Print',
        viewColumns: 'View Columns',
        filterTable: 'Filter Table',
      },
      filter: {
        all: 'All',
        title: 'FILTERS',
        reset: 'RESET',
      },
      viewColumns: {
        title: 'Show Columns',
        titleAria: 'Show/Hide Table Columns',
      },
      selectedRows: {
        text: 'row(s) selected',
        delete: 'Delete',
        deleteAria: 'Delete Selected Rows',
      },
    },
  }

  const columns = [
    {
      name: 'invNum',
      label: 'RB',
      options: {
        setCellProps: () => ({ style: { width: 100 } })
      },
    },
    {
      name: 'invoiceType',
      label: 'Tip računa',
      options: {
        setCellProps: () => ({ style: { width: 70 } }),
        customBodyRender: value => parseInvoiceTypeChip(value)
      },
    },
    {
      name: 'ikof',
      label: 'IKOF',
      options: {
        setCellProps: () => ({ style: { width: 100 } })
      },
    },
    {
      name: 'issueDateTime',
      label: 'Datum',
      options: {
        setCellProps: () => ({ style: { width: 90 } }),
        customBodyRender: value => moment(value).format('DD/MM/YYYY HH:mm')
      },
    },
    {
      name: 'totPrice',
      label: 'Ukupno',
      options: {
        setCellProps: () => ({ style: { width: 70 } }),
        customBodyRender: value => value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
      },
    },
    {
      name: 'obj',
      label: ' ',
      options: {
        filter: false,
        sort: false,
        empty: true,
        width: 50,
        customBodyRender: (dataIndex) => {
          return (
            ['CREDIT_NOTE'].includes(dataIndex.invoiceType) || dataIndex.amount <= 0 || dataIndex.corrective || dataIndex.corrected ? null :
            (IICRefs.map(i => i.iic).includes(dataIndex.iic) ?
              <CheckBox
                color="secondary"
                onClick={() => {
                  setIICRefs(IICRefs.filter(i => i.iic !== dataIndex.iic))
                }}
              />
              :
              <CheckBoxOutlineBlank
                color="secondary"
                onClick={() => {
                  setIICRefs([...IICRefs, dataIndex])
                }}
              />)
          )
        },
        setCellProps: () => ({ style: { width: 50 } }),
      }
    }
  ]

  return (
    <>
      <MUIDataTable
        title={
          <Typography variant="h6">
            Fakture
          </Typography>
        }
        data={invoices}
        columns={columns}
        options={options}
      />
      <Box display="flex" justifyContent="center" p={1}>
        <Box p={1}>
          <Pagination
            color="primary"
            showFirstButton
            showLastButton
            count={Math.ceil(invoicesCount / rowsPerPage)}
            page={Math.floor(from / rowsPerPage + 1)}
            onChange={(e, v) => {
              setFrom(rowsPerPage * (v - 1))
            }}
          />
        </Box>
        <Box p={2}>
          Ukupno: {invoicesCount}
        </Box>
      </Box>
    </>
  )
}

export default CreditNoteIICRefs