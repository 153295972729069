import React from 'react'
import { AppBar, Box, Tab, Tabs } from '@material-ui/core'
import TabPanel from '../../components/TabPanel'
import Inventory from './Inventory'
import ItemsTurnoverReport from './ItemsTurnoverReport'
import { useSelector } from 'react-redux'
import { getCurrentSubscriber, getCurrentTcr } from '../mainReducer'
import ItemsStockReport from './ItemsStockReport'

export default function InventoryPage () {
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const currentSubscriber = useSelector(getCurrentSubscriber)
  const currentTcr = useSelector(getCurrentTcr)

  return (
    <Box>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="tabs"
          scrollButtons="on"
          variant="scrollable"
        >
          <Tab label="ARTIKLI"/>
          <Tab label="PROMET U PERIODU"/>
          {currentTcr && currentTcr.useGoodsReceipt && <Tab label="ZALIHE"/>}
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <Inventory/>
      </TabPanel>
      <TabPanel value={value} index={1}>
        {currentSubscriber && currentTcr &&
        <ItemsTurnoverReport subscriberId={currentSubscriber.id} tcrId={currentTcr.id}/>}
      </TabPanel>
      <TabPanel value={value} index={2}>
        {currentSubscriber && currentTcr && currentTcr.useGoodsReceipt &&
        <ItemsStockReport subscriberId={currentSubscriber.id} tcrId={currentTcr.id}/>}
      </TabPanel>
    </Box>
  )
}