import React from "react";
import classNames from "classnames";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DashboardIcon from "@material-ui/icons/Dashboard";
import { withStyles } from "@material-ui/core/styles";
import {
  AddOutlined,
  Business, ListAlt,
  People,
  Receipt,
  Shop
} from '@material-ui/icons'
import {useSelector} from "react-redux";
import { getCurrentRole, getCurrentTcr, getDrawerOpened } from '../../containers/mainReducer'
import { useHistory, useLocation } from 'react-router'

const drawerWidth = 240;

const styles = theme => ({
  drawerPaper: {
    position: "fixed",
    top: theme.spacing(8),
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(7)
    }
  },
  activeListItem: {
    backgroundColor: theme.palette.action.selected,
  }
});

const Sidebar = props => {
  const { classes } = props;
  const currentRole = useSelector(getCurrentRole);
  const currentTcr = useSelector(getCurrentTcr);
  const history = useHistory();
  const location = useLocation();
  const open = useSelector(getDrawerOpened);

  const isActive = (path) => location.pathname === path;

  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: classNames(
          classes.drawerPaper,
          !open && classes.drawerPaperClose
        )
      }}
      open={open}
    >
      <List>
        <ListItem
          button
          onClick={() => history.push("/")}
          className={isActive("/") ? classes.activeListItem : ""}
        >
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Naslovna" />
        </ListItem>
        {["ADMIN", "SELLER"].includes(currentRole) &&
          <ListItem
            button
            onClick={() => history.push("/subscribers")}
            className={isActive("/subscribers") ? classes.activeListItem : ""}
          >
            <ListItemIcon>
              <Business />
            </ListItemIcon>
            <ListItemText primary="Pretplatnici" />
          </ListItem>
        }
        {currentTcr && ["OWNER", "ACCOUNTANT"].includes(currentRole) &&
          <ListItem
            button
            onClick={() => history.push("/inventory")}
            className={isActive("/inventory") ? classes.activeListItem : ""}
          >
            <ListItemIcon>
              <Shop />
            </ListItemIcon>
            <ListItemText primary="Artikli" />
          </ListItem>
        }
        {currentTcr && ["OWNER", "ACCOUNTANT"].includes(currentRole) &&
          <ListItem
            button
            onClick={() => history.push("/clients")}
            className={isActive("/clients") ? classes.activeListItem : ""}
          >
            <ListItemIcon>
              <People />
            </ListItemIcon>
            <ListItemText primary="Partneri" />
          </ListItem>
        }
        {currentTcr && ["OWNER", "ACCOUNTANT"].includes(currentRole) &&
          <ListItem
            button
            onClick={() => history.push("/invoices")}
            className={isActive("/invoices") ? classes.activeListItem : ""}
          >
            <ListItemIcon>
              <Receipt />
            </ListItemIcon>
            <ListItemText primary="Računi" />
          </ListItem>
        }
        {currentTcr && ["OWNER", "ACCOUNTANT"].includes(currentRole) &&
          <ListItem
            button
            onClick={() => history.push("/invoices/new")}
            className={isActive("/invoices/new") ? classes.activeListItem : ""}
          >
            <ListItemIcon>
              <AddOutlined />
            </ListItemIcon>
            <ListItemText primary="Novi Račun" />
          </ListItem>
        }
        {currentTcr && currentTcr.useGoodsReceipt && ["OWNER", "ACCOUNTANT"].includes(currentRole) &&
          <ListItem
            button
            onClick={() => history.push("/goods-receipts")}
            className={isActive("/goods-receipts") ? classes.activeListItem : ""}
          >
            <ListItemIcon>
              <ListAlt />
            </ListItemIcon>
            <ListItemText primary="Prijemnice" />
          </ListItem>
        }
        {currentTcr && currentTcr.useGoodsReceipt && ["OWNER", "ACCOUNTANT"].includes(currentRole) &&
          <ListItem
            button
            onClick={() => history.push("/goods-receipts/new")}
            className={isActive("/goods-receipts/new") ? classes.activeListItem : ""}
          >
            <ListItemIcon>
              <AddOutlined />
            </ListItemIcon>
            <ListItemText primary="Nova prijemnica" />
          </ListItem>
        }
        {currentTcr && currentTcr.useCreditNotes && ["OWNER", "ACCOUNTANT"].includes(currentRole) &&
          <ListItem
            button
            onClick={() => history.push("/credit-note/new")}
            className={isActive("/credit-note/new") ? classes.activeListItem : ""}
          >
            <ListItemIcon>
              <AddOutlined />
            </ListItemIcon>
            <ListItemText primary="Novo knjižno odobrenje" />
          </ListItem>
        }
      </List>
    </Drawer>
  );
};

export default withStyles(styles)(Sidebar);