import moment from 'moment'
import {v4 as uuidv4} from 'uuid';

export class InventoryModel {
    constructor() {
        this.id = '';
        this.createdAt = moment().valueOf();
        this.creator = '';
        this.deleted = false;
        this.modifier = null;
        this.name = '';
        this.price = 0.0;
        this.quantity = 0;
        this.sku = '';
        this.taxRate = 21;
        this.unitOfMeasure = 'kom';
        this.updatedAt = null;
        this.service = false;
        this.updateToNewTaxRate = false;
    }

    update(updatedInventory) {
        this.id = updatedInventory.id || this.id || uuidv4();
        this.createdAt = updatedInventory.createdAt || this.createdAt || moment().valueOf();
        this.creator = updatedInventory.creator || this.creator;
        this.deleted = updatedInventory.deleted !== undefined ? updatedInventory.deleted : this.deleted;
        this.modifier = updatedInventory.modifier || this.modifier;
        this.name = (updatedInventory.name || this.name).replace(/[\r\n\t]/g, "");
        this.price = updatedInventory.price && updatedInventory.price >=0 ? Number(updatedInventory.price) : Number(this.price);
        this.quantity = updatedInventory.quantity || this.quantity;
        this.sku = (updatedInventory.sku || this.sku).toString().replace(/[\r\n\t]/g, "");
        this.taxRate = updatedInventory.taxRate !== undefined && updatedInventory.taxRate >=0 ? Number(updatedInventory.taxRate) : Number(this.taxRate);
        this.unitOfMeasure = (updatedInventory.unitOfMeasure || this.unitOfMeasure).replace(/[\r\n\t]/g, "");
        this.updatedAt = moment().valueOf();
        this.service = updatedInventory.service !== undefined ? updatedInventory.service : this.service;
        this.updateToNewTaxRate = updatedInventory.updateToNewTaxRate !== undefined ? updatedInventory.updateToNewTaxRate : this.updateToNewTaxRate;
    }

    toJson() {
        return {...this}
    }

}

