import React from 'react'
import { Grid } from '@material-ui/core'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import { firstDayInPreviousMonth } from '../../utils/helpers'
import moment from 'moment-timezone'

const SupplyDate = ({ supplyDate, updateInvoice }) => {
  return (
    <MuiPickersUtilsProvider locale="me" utils={MomentUtils}>
      <KeyboardDatePicker
        tabIndex={-1}
        disableToolbar
        minDate={firstDayInPreviousMonth(new Date())}
        maxDate={new Date()}
        size="small"
        style={{ width: '100%', minWidth: 180, maxWidth: 220 }}
        inputVariant="outlined"
        margin="none"
        label={'Datum prometa'}
        format="DD.MM.yyyy."
        clearable
        value={supplyDate ? moment(supplyDate) : null}
        inputValue={supplyDate ? moment(supplyDate, 'yyyy-MM-DD').format('DD.MM.yyyy.') : null}
        onChange={(value) => {
          updateInvoice({
            supplyDate: value ? moment(value).format('yyyy-MM-DD') : null
          })
        }}
      />
    </MuiPickersUtilsProvider>
  )
}

export default SupplyDate