import * as React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useKeycloak } from '@react-keycloak/web'
import MainLayout from '../layouts/MainLayout'
import { setCurrentRoute } from '../containers/mainReducer'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

export function PrivateRoute (
  {
    name,
    component: Component,
    ...rest
  }) {

  const { keycloak } = useKeycloak()

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setCurrentRoute(name))
  }, [name, dispatch])

  return (
    <Route
      {...rest}
      render={(props) =>
        keycloak?.authenticated ? (
          <MainLayout>
            <Component {...props} />
          </MainLayout>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  )
}
