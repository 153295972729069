import { useRef } from 'react'
import { useAxios } from '../utils/hooks'
import moment from 'moment-timezone'

export const useApi = () => {
  const axiosInstance = useAxios()

  const validateStatus = (status) => {
    return status >= 200 && status < 303
  }

  const api = useRef({});

  api.current.getGoodsReceiptsMaxNum = (subscriberId, tcrId, dateFrom, dateTo) => {
    const dateFromUrl = dateFrom
      ? encodeURIComponent(moment(dateFrom).startOf('day').tz('Europe/Podgorica').format())
      : encodeURIComponent(moment().startOf('year').tz('Europe/Podgorica').format())
    const dateToUrl = dateTo
      ? encodeURIComponent(moment(dateTo).endOf('day').tz('Europe/Podgorica').format())
      : encodeURIComponent(moment().endOf('year').tz('Europe/Podgorica').format())

    const url = `inventory/subscriber/${subscriberId}/tcr/${tcrId}/goodsreceipt/_maxOrdNum?dateFrom=${dateFromUrl}&dateTo=${dateToUrl}`
    return axiosInstance.current.get(url)
  }

  api.current.getGoodsReceiptById = (subscriberId, tcrId, id) => {
    const url = `inventory/subscriber/${subscriberId}/tcr/${tcrId}/goodsreceipt/${id}`
    return axiosInstance.current.get(url)
  }

  api.current.postGoodsReceipt = (subscriberId, tcrId, goodsReceipt) => {
    const url = `inventory/subscriber/${subscriberId}/tcr/${tcrId}/goodsreceipt`
    return axiosInstance.current.post(url, goodsReceipt, {validateStatus})
  }

  api.current.putGoodsReceipt = (subscriberId, tcrId, id, goodsReceipt) => {
    const url = `inventory/subscriber/${subscriberId}/tcr/${tcrId}/goodsreceipt/${id}`
    return axiosInstance.current.post(url, goodsReceipt, {validateStatus})
  }

  api.current.deleteGoodsReceipt = (subscriberId, tcrId, id) => {
    const url = `inventory/subscriber/${subscriberId}/tcr/${tcrId}/goodsreceipt/${id}`
    return axiosInstance.current.delete(url, {validateStatus})
  }

  api.current.getInvoiceItemsStock = (subscriberId, tcrId, itemId) => {
    const url = `report/subscriber/${subscriberId}/tcr/${tcrId}/_items_stock${itemId?'?'+itemId:''}`
    return axiosInstance.current.get(url)
  }

  api.current.getGoodsReceiptItemsStock = (subscriberId, tcrId, itemId) => {
    const url = `inventory/subscriber/${subscriberId}/tcr/${tcrId}/goodsreceipt/_items_stock${itemId?'?'+itemId:''}`
    return axiosInstance.current.get(url)
  }

  api.current.postVerifyIKOF = (subscriberId, tcrId, data) => {
    const url = `iicvalidator/subscriber/${subscriberId}/tcr/${tcrId}/_ikof`
    return axiosInstance.current.post(url, data)
  }

  api.current.getInvoices = (from, rowsPerPage, status, dateFromUrl, dateToUrl, sellerId) => {
    return axiosInstance.current.get(`report/invoice?from=${from}&size=${rowsPerPage}&dateFrom=${dateFromUrl}&dateTo=${dateToUrl}&status=${status}${sellerId?'&sellerId='+sellerId :''}`)
  }

  api.current.getDeposits = (from, rowsPerPage, status, dateFromUrl, dateToUrl, sellerId) => {
    return axiosInstance.current.get(`report/deposit?from=${from}&size=${rowsPerPage}&dateFrom=${dateFromUrl}&dateTo=${dateToUrl}&status=${status}${sellerId?'&sellerId='+sellerId :''}`)
  }

  api.current.getSellers = () => {
    return axiosInstance.current.get(`admin/user/_by_role?role=SELLER`)
  }

  api.current.getAccountants = () => {
    return axiosInstance.current.get(`admin/user/_by_role?role=ACCOUNTANT`)
  }

  api.current.getInvoicesForClient = (subscriberId, tcrId, clientID, from, rowsPerPage) => {
    const dateFromUrl = encodeURIComponent(moment("2020-01-01").startOf('year').tz('Europe/Podgorica').format())
    const dateToUrl = encodeURIComponent(moment().endOf('year').tz('Europe/Podgorica').format())
    return axiosInstance.current.get(`report/subscriber/${subscriberId}/tcr/${tcrId}/invoice?from=${from}&size=${rowsPerPage}&dateFrom=${dateFromUrl}&dateTo=${dateToUrl}${clientID ? '&clientID=' + clientID : ''}&status=SUCCESS,SUCCESS`)
  }

  api.current.getLastInvoiceForClient = (subscriberId, tcrId, clientID) => {
    const dateFromUrl = encodeURIComponent(moment("2020-01-01").startOf('year').tz('Europe/Podgorica').format())
    const dateToUrl = encodeURIComponent(moment().endOf('year').tz('Europe/Podgorica').format())
    return axiosInstance.current.get(`report/subscriber/${subscriberId}/tcr/${tcrId}/invoice?from=0&size=1&dateFrom=${dateFromUrl}&dateTo=${dateToUrl}${clientID ? '&clientID=' + clientID : ''}&status=SUCCESS,STALLED`)
  }

  return api.current

}