import React from 'react';
import {Grid} from "@material-ui/core";
import Tile from "./Tile";
import {EuroSymbol} from "@material-ui/icons";
import {dashboardInvoiceStatusIcon} from "../../utils/helpers";
import {useSelector} from "react-redux";
import {getTheme} from "../Setting/settingsReducer";

function DashboardInvoicesSummary( {adminDashboardData, setCurrentInvoiceType, currentInvoiceType} ) {
    const theme = useSelector(getTheme);

    return (
        <Grid container spacing={3}>
            {adminDashboardData && adminDashboardData.invoices && Object.keys(adminDashboardData.invoices).map(o => (
                <Grid key={o} item lg={3} sm={6} xl={2} xs={12} md={4}>
                    <Tile
                        text={o}
                        value={adminDashboardData.invoices[o]}
                        color={theme.palette.primary.main}
                        decimalPlaces={0}
                        onClick={() => setCurrentInvoiceType(o)}
                        selected={o === currentInvoiceType}
                        icon={dashboardInvoiceStatusIcon(o)}
                    />
                </Grid>
            ))}
            <Grid item lg={3} sm={6} xl={2} xs={12} md={4}>
                <Tile
                    text={'Iznos'}
                    value={adminDashboardData ? adminDashboardData.totPriceSum : 0}
                    color={theme.palette.primary.main}
                    icon={<EuroSymbol/>}
                />
            </Grid>
        </Grid>
    );
}

export default DashboardInvoicesSummary;