import React, { useEffect, useRef, useState } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, FormControl,
  InputLabel, MenuItem,
  Paper, Select,
  TextField
} from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { useSelector } from 'react-redux'
import {getIsLoading, getUser} from '../mainReducer'
import { htmlSpecialChars, maxNumChars, removeLineBreaks } from '../../utils/helpers'
import {useTaxRateValidation} from "../../hooks/useTaxRateValidation";

const ProductDialog = (
  {
    editingProduct,
    setEditingProduct,
    openProductDialog,
    setOpenProductDialog,
    postInventory,
    useGoodsReceipt,
    useAdditionalTaxRate,
    isIssuerInVAT,
    showIsDeleted,
    inventory
  }) => {

  const initialProduct = {
    id: '',
    name: '',
    price: 0.0,
    sku: '',
    taxRate: isIssuerInVAT ? 21 : 0,
    unitOfMeasure: 'kom',
    service: false,
    deleted: false,
    updateToNewTaxRate: false
  }

  const [createNewAfterSave, setCreateNewAfterSave] = useState(false)
  const [product, setProduct] = useState(initialProduct)
  const skuRef = useRef()
  const isLoading = useSelector(getIsLoading)
  const [formReseted, setFormReseted] = useState(true)

  const isNewProduct = !editingProduct
  const idIsUnique = !isNewProduct || (isNewProduct && !inventory.find(c => c.sku === product.sku))
  const shouldShowAdditionalTaxRate = useTaxRateValidation(useAdditionalTaxRate);


  useEffect(() => {
    if (editingProduct) {
      setProduct({
        id: editingProduct.id,
        name: editingProduct.name,
        price: Number(editingProduct.price),
        sku: editingProduct.sku,
        taxRate: editingProduct.taxRate,
        unitOfMeasure: editingProduct.unitOfMeasure,
        service: !!editingProduct.service,
        deleted: !!editingProduct.deleted,
        updateToNewTaxRate: !!editingProduct.updateToNewTaxRate
      })
      setFormReseted(true)
    } else {
      setProduct(initialProduct)
      setFormReseted(true)
    }

  }, [editingProduct])

  useEffect(() => {
    if (formReseted && skuRef.current) {
      skuRef.current.focus()
      setFormReseted(false)
    }
  }, [formReseted])

  const handleChange = (event) => {
    setProduct({
      ...product,
      [event.target.name]: event.target.type === 'number' ? Number(Number(event.target.value).toFixed(2)) : htmlSpecialChars(event.target.value)
    })
  }

  const handleChangeOnBlur = (name, value) => {
    setProduct({
      ...product,
      [name]: value.trim()
    })
  }

  const handlePriceChange = (event) => {
    const price = event.target.value.trim().replace(/^-?[^\d,.]/g, '')
    setProduct({
      ...product,
      price
    })
  }

  const handleChangeTaxRate = (event) => {
    setProduct({
      ...product,
      [event.target.name]: event.target.value
    })
  }

  const handleChangeCheckbox = (event) => {
    setProduct({
      ...product,
      [event.target.name]: !product[event.target.name]
    })
  }

  const isPriceValid = !isNaN(product.price)

  return (
    <Dialog
      disableBackdropClick
      maxWidth="lg"
      open={openProductDialog}
      onClose={() => {
        setEditingProduct && setEditingProduct(null)
        setOpenProductDialog(false)
      }}
      aria-labelledby="max-width-dialog-title"
    >
      <DialogTitle>{editingProduct ? 'Izmjena artikla' : 'Unos novog artikla'}</DialogTitle>
      <DialogContent>
        {product && <Paper elevation={3}>
          <Box p={2}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoFocus
                  autoComplete="off"
                  required
                  id="sku"
                  name="sku"
                  error={!idIsUnique || product.sku.trim().length === 0}
                  label="Šifra"
                  fullWidth
                  value={product.sku}
                  onChange={handleChange}
                  inputRef={skuRef}
                  inputProps={{ maxLength: 50 }}
                  helperText={!idIsUnique ? 'Šifra mora biti jedninstvena' : `${product.sku.length}/50`}
                  onBlur={(event) => {
                    handleChangeOnBlur(event.target.name, event.target.value)
                  }}
                  onPaste={(e) => {
                    e.preventDefault()
                    setProduct({
                      ...product,
                      sku: maxNumChars(removeLineBreaks(e.clipboardData.getData('Text')), 50)
                    })
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="off"
                  required
                  error={product.name.trim().length < 2}
                  helperText={`${product.name.length}/50`}
                  id="name"
                  name="name"
                  label="Naziv"
                  fullWidth
                  value={product.name}
                  onChange={handleChange}
                  inputProps={{ maxLength: 50 }}
                  onBlur={(event) => {
                    handleChangeOnBlur(event.target.name, event.target.value)
                  }}
                  onPaste={(e) => {
                    e.preventDefault()
                    setProduct({
                      ...product,
                      name: maxNumChars(removeLineBreaks(e.clipboardData.getData('Text')), 50)
                    })
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <div lang="en-US">
                  <TextField
                    autoComplete="off"
                    required
                    error={!isPriceValid || product.price <= 0}
                    helperText={!isPriceValid ? 'Unesite ispravnu cijenu' : ''}
                    id="price"
                    name="price"
                    label="Cijena"
                    fullWidth
                    value={product.price}
                    onChange={handlePriceChange}
                    onPaste={(e) => {
                      e.preventDefault()
                      setProduct({
                        ...product,
                        price: removeLineBreaks(e.clipboardData.getData('Text'))
                      })
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl style={{ minWidth: '100%' }}>
                  <InputLabel id="taxIdentifierType">PDV</InputLabel>
                  <Select
                    style={{ minWidth: '100%' }}
                    labelId="taxRate"
                    name="taxRate"
                    id="taxRate"
                    value={product.taxRate}
                    onChange={handleChangeTaxRate}
                  >
                    <MenuItem value={0}>0%</MenuItem>
                    {isIssuerInVAT && <MenuItem value={7}>7%</MenuItem>}
                    {shouldShowAdditionalTaxRate && isIssuerInVAT && <MenuItem value={15}>15%</MenuItem>}
                    {isIssuerInVAT && <MenuItem value={21}>21%</MenuItem>}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="off"
                  id="unitOfMeasure"
                  name="unitOfMeasure"
                  label="JM"
                  fullWidth
                  value={product.unitOfMeasure}
                  onChange={handleChange}
                  helperText={`${product.unitOfMeasure.length}/50`}
                  onBlur={(event) => {
                    handleChangeOnBlur(event.target.name, event.target.value)
                  }}
                  onPaste={(e) => {
                    e.preventDefault()
                    setProduct({
                      ...product,
                      unitOfMeasure: maxNumChars(removeLineBreaks(e.clipboardData.getData('Text')), 50)
                    })
                  }}
                />
              </Grid>
              {useGoodsReceipt && <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={<Checkbox
                    color="secondary"
                    name="service"
                    checked={product.service}
                    onChange={handleChangeCheckbox}/>}
                  label="Usluga"
                />
              </Grid>}
              {useAdditionalTaxRate && (
                <Grid item xs={12} sm={6}>
                  <FormControlLabel
                    control={<Checkbox
                      color="secondary"
                      name="updateToNewTaxRate"
                      checked={product.updateToNewTaxRate || false}
                      onChange={handleChangeCheckbox}/>}
                    label="Ažuriraj na novu stopu PDV-a"
                  />
                </Grid>
              )}
              {showIsDeleted && <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={<Checkbox
                    color="secondary"
                    name="deleted"
                    checked={product.deleted}
                    onChange={handleChangeCheckbox}/>}
                  label="Obrisan"
                />
              </Grid>}
            </Grid>
          </Box>
        </Paper>}
      </DialogContent>
      <DialogActions>
          {!editingProduct && setEditingProduct && <Box pl={2}>
            <FormControlLabel
              control={<Checkbox
                tabIndex={-1}
                color="secondary"
                checked={createNewAfterSave}
                onChange={() => setCreateNewAfterSave(!createNewAfterSave)}/>
              }
              label="Brzi unos"
            />
          </Box>}
          <Box flexGrow={1} textAlign="right" pr={2}>
            <Button color="primary" onClick={() => {
              setEditingProduct && setEditingProduct(null)
              setOpenProductDialog(false)
            }}>
              Zatvori
            </Button>
            <Button
              onClick={() => {
                postInventory(product, !editingProduct, !createNewAfterSave)
                setProduct(initialProduct)
                skuRef.current && skuRef.current.focus()
              }}
              variant="contained"
              color="primary"
              disabled={
                isLoading ||
                product.sku.trim() === '' ||
                product.name.trim().length < 2 ||
                !isPriceValid ||
                product.price <= 0 ||
                !idIsUnique
              }
            >
              Sačuvaj
            </Button>
          </Box>
      </DialogActions>
    </Dialog>
  )
}

export default ProductDialog