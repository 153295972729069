import React, {useCallback, useEffect, useState} from "react";
import {useAxios} from "../../utils/hooks";
import {useParams} from "react-router";
import {Box, Tab, Tabs} from "@material-ui/core";
import SubscriberBasicData from "./SubscriberBasicData";
import TabPanel from "../../components/TabPanel";
import {useDispatch, useSelector} from "react-redux";
import {setIsLoading, setErrorSnackbar, setSuccessSnackbar, getCurrentRole} from "../mainReducer";
import {SubscriberModel} from "../../models/SubscriberModel";
import SubscriberCertificates from "./SubscriberCertificates";
import SubscriberUsers from "./SubscriberUsers";
import SubscriberStores from "./SubscriberStores";
import {CertificateModel} from "../../models/CertificateModel";
import {StoreModel} from "../../models/StoreModel";
import AdvancedSettings from "./AdvancedSettings";
import {UserModel} from "../../models/UserModel";


export default function Subscriber() {

    let {id} = useParams();
    const [subscriber, setSubscriber] = useState();
    const [value, setValue] = React.useState(0);
    const dispatch = useDispatch();
    const currentRole = useSelector(getCurrentRole);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const addCertificate = (certificate) => {
        setSubscriber({
            ...subscriber,
            certificates: [...subscriber.certificates, certificate]
        })
    };

    const addUser = (user) => {
        setSubscriber({
            ...subscriber,
            users: [...subscriber.users, user]
        })
    };

    const addStore = (store) => {
        setSubscriber({
            ...subscriber,
            stores: [...subscriber.stores, store]
        })
    };

    const updateUser = (user) => {
        const updatedUsers = subscriber.users.map(u => {
            return u.id === user.id ? user : u;
        })

        setSubscriber({
            ...subscriber,
            users: updatedUsers
        })
    };

    const updateStore = (store) => {
        const updatedStores = subscriber.stores.map(s => {
            return s.id === store.id ? store : s;
        })

        setSubscriber({
            ...subscriber,
            stores: updatedStores
        })
    };

    const axiosInstance = useAxios();

    const putSubscriber = useCallback((subscriber) => {
        dispatch(setIsLoading(true));
        if (!!axiosInstance.current) {
            const subs = new SubscriberModel();
            subscriber = {
                ...subscriber,
                certificates: subscriber.certificates.map(c => {
                    const newCert = new CertificateModel();
                    newCert.update(c);
                    return newCert;
                }),
                stores: subscriber.stores.map(s => {
                    const newStore = new StoreModel();
                    newStore.update(s);
                    return newStore;
                }),
                users: subscriber.users.map(s => {
                    const newUser = new UserModel();
                    newUser.update(s);
                    return newUser;
                }),
            }
            subs.update(subscriber);

            axiosInstance.current.put(`/admin/subscriber/${subscriber.id}`, subs.toJson())
                .then(() => {
                    dispatch(setSuccessSnackbar("Pretplatnik je sačuvan"));
                    setSubscriber(subs);
                })
                .catch(err => {
                    console.log('err', err);
                    dispatch(setErrorSnackbar("Greška"));
                })
                .finally(() => {
                    dispatch(setIsLoading(false));
                });
        }
    }, [axiosInstance, dispatch]);

    useEffect(() => {

        const getSubscriber = () => {
            dispatch(setIsLoading(true));
            if (!!axiosInstance.current) {
                axiosInstance.current.get(`/admin/subscriber/${id}`)
                    .then(response => {
                        setSubscriber(response.data);
                    })
                    .catch(err => {
                        console.log('err', err);
                        dispatch(setErrorSnackbar("Greška"));
                    })
                    .finally(() => {
                        dispatch(setIsLoading(false));
                    });
            }
        };

        id && getSubscriber();
    }, [axiosInstance, dispatch, id]);

    return (
        <Box>
            <Tabs
                value={value}
                onChange={handleChange}
                aria-label="tabs example"
                scrollButtons="on"
                variant="scrollable"
            >
                <Tab label={subscriber && subscriber.name}/>
                <Tab label="SERTIFIKATI"/>
                <Tab label="KORISNICI"/>
                <Tab label="POSLOVNE JEDINICE"/>
                {['ADMIN', 'SELLER'].includes(currentRole)  && <Tab label="NAPREDNA PODEŠAVANJA"/>}

            </Tabs>
            <TabPanel value={value} index={0}>
                {subscriber && <SubscriberBasicData subscriber={subscriber} saveSubscriber={putSubscriber}/>}
            </TabPanel>

            <TabPanel value={value} index={1}>
                {subscriber &&
                <SubscriberCertificates subscriberId={subscriber.id} certificates={subscriber.certificates}
                                        addCertificate={addCertificate}/>}
            </TabPanel>
            <TabPanel value={value} index={2}>
                {subscriber &&
                <SubscriberUsers subscriberId={subscriber.id} users={subscriber.users}
                                 addUser={addUser} updateUser={updateUser} subscriber={subscriber} saveSubscriber={putSubscriber} />}
            </TabPanel>
            <TabPanel value={value} index={3}>
                {subscriber &&
                <SubscriberStores subscriberId={subscriber.id} subscriberTIN={subscriber.taxIdentifier} stores={subscriber.stores} users={subscriber.users}
                                  addStore={addStore} updateStore={updateStore} isIssuerInVAT={subscriber.isIssuerInVAT}
                                  certificates={subscriber.certificates}/>}
            </TabPanel>
            {['ADMIN', 'SELLER'].includes(currentRole) && <TabPanel value={value} index={4}>
                {subscriber && <AdvancedSettings subscriberId={subscriber.id} currentRole={currentRole}/>}
            </TabPanel>}
        </Box>
    );
};
