import React, {useCallback, useState} from "react";
import {useAxios} from "../../utils/hooks";
import {useHistory} from "react-router";
import {Box, Tab, Tabs} from "@material-ui/core";
import SubscriberBasicData from "./SubscriberBasicData";
import TabPanel from "../../components/TabPanel";
import {useDispatch, useSelector} from "react-redux";
import {setSuccessSnackbar, setErrorSnackbar, setIsLoading, getCurrentRole, getUser} from "../mainReducer";
import {SubscriberModel} from "../../models/SubscriberModel";

export default function NewSubscriber() {

    const history = useHistory();
    const [subscriber] = useState(new SubscriberModel());
    const [tabIndex, setTabIndex] = React.useState(0);
    const user = useSelector(getUser);
    const currentRole = useSelector(getCurrentRole);
    const dispatch = useDispatch();

    const handleChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    const axiosInstance = useAxios();

    const postSubscriber = useCallback((subscriber) => {
        dispatch(setIsLoading(true));
        if (currentRole === "SELLER") {
            subscriber = {
                ...subscriber,
                seller: {
                    id: user.sub,
                    name: user.name,
                    email: user.preferred_username
                }
            }
        }

        const subs = new SubscriberModel();
        subs.update(subscriber);
        subs.update({
            seller: {
                id: user.sub,
                name: user.name,
                email: user.preferred_username
            }
        })

        if (!!axiosInstance.current) {
            axiosInstance.current.post(`/admin/subscriber`, subs.toJson())
                .then(response => {
                    dispatch(setSuccessSnackbar("Pretplatnik je sačuvan"));
                    history.push(`/subscribers/${response.data.id}`);
                })
                .catch(err => {
                    dispatch(setErrorSnackbar("Greška"));
                    console.log(err);
                })
                .finally(() => {
                    dispatch(setIsLoading(false));
                });
        }
    }, [axiosInstance, dispatch, history, user, currentRole]);

    return (
        <>
            <Box>
                <Tabs
                    value={tabIndex}
                    onChange={handleChange}
                    aria-label="tabs example"
                    scrollButtons="on"
                    variant="scrollable"
                >
                    <Tab label="OSNOVNI PODACI"/>
                    <Tab label="SERTIFIKATI" disabled/>
                    <Tab label="KORISNICI" disabled/>
                    <Tab label="POSLOVNE JEDINICE" disabled/>
                </Tabs>
                <TabPanel value={tabIndex} index={0}>
                    <SubscriberBasicData subscriber={subscriber} saveSubscriber={postSubscriber}/>
                </TabPanel>
                <TabPanel value={tabIndex} index={1}>

                </TabPanel>
                <TabPanel value={tabIndex} index={2}>

                </TabPanel>
                <TabPanel value={tabIndex} index={3}>

                </TabPanel>
            </Box>
        </>
    );
};