import React, {useEffect, useRef, useState} from 'react';
import {useAxios} from "../../utils/hooks";
import {useDispatch} from "react-redux";
import {setIsLoading} from "../mainReducer";
import {Vega} from "react-vega";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import Grid from "@material-ui/core/Grid";
import moment from "moment-timezone";
import {Box, Card, FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
    root: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
});

const initialReportData = {
    totalInvoices: 0,
    totalInvoicesWithItem: 0,
    totalAmountOfVAT: 0.00,
    totalPriceWithVAT: 0.00,
    totalPriceWithoutVAT: 0.00,
    totalDiscount: 0.00
}

export default function ItemTurnoverReport({itemId, subscriberId, tcrId}) {
    const classes = useStyles();
    const axiosInstance = useAxios();
    const dispatch = useDispatch();
    const [reportData, setReportData] = useState(initialReportData);
    const [data, setData] = useState({table: []});
    const [calendarInterval, setCalendarInterval] = useState('1d');
    const [dateFrom, setDateFrom] = useState(moment().startOf('month').valueOf());
    const [dateTo, setDateTo] = useState(moment().endOf('day').valueOf());
    const graphContainerRef = useRef();

    const spec = {
        "width": "container",
        "height": 300,
        "padding": {"top": 10, "left": 30, "bottom": 30, "right": 10},
        "data": [{"name": "table"}],
        "signals": [
            {
                "name": "tooltip",
                "value": {},
                "on": [
                    {"events": "rect:mouseover", "update": "datum"},
                    {"events": "rect:mouseout",  "update": "{}"}
                ]
            }
        ],

        "scales": [

            {
                "name": "yscale_totalPriceWithVAT",
                "type": "linear",
                "zero": true,
                "domain": {"data": "table", "field": "totalPriceWithVAT"},
                "range": "height"
            },

            {
                "name": "xscale_timestamp",
                "type": "time",
                "domain": {"data": "table", "field": "timerange"},
                "range": "width"
            },
            {
                "name": "xscale",
                "type": "band",
                "domain": {"data": "table", "field": "timerange"},
                "range": "width",
                "padding": 0.05,
                "round": true
            },
            {
                "name": "xscale_axis",
                "type": "band",
                "domain": {"data": "table", "field": "timerange_string"},
                "range": "width",
                "padding": 0.05,
                "round": true
            },
        ],
        "axes": [
            {"scale": "xscale", "orient": "bottom",
                "formatType":"utc",
                "encode": {
                    "labels": {
                        "update": {
                            "angle": {"value": -50},
                            "fontSize": {"value": 10},
                            "align": {"value": "right"}
                        }
                    }
                }
            },
            {"scale": "yscale_totalPriceWithVAT", "orient": "left", "title": "Ukupno sa PDV"}
        ],
        "marks": [
            {
                "type": "rect",
                "from": {"data": "table"},
                "encode": {
                    "enter": {
                        "x": {"scale": "xscale", "field": "timerange"},
                        "width": {"scale": "xscale", "band": 1},
                        "y": {"scale": "yscale_totalPriceWithVAT", "field": "totalPriceWithVAT"},
                        "y2": {"scale": "yscale_totalPriceWithVAT", "value": 0}
                    },
                    "update": {
                        "fill": {"value": "steelblue"}
                    },
                    "hover": {
                        "fill": {"value": "red"}
                    }
                }
            },
            {
                "type": "text",
                "encode": {
                    "enter": {
                        "align": {"value": "center"},
                        "baseline": {"value": "bottom"},
                        "fill": {"value": "#333"}
                    },
                    "update": {
                        "x": {"scale": "xscale", "signal": "tooltip.timerange", "band": 0.5},
                        "y": {"scale": "yscale_totalPriceWithVAT", "signal": "tooltip.totalPriceWithVAT", "offset": -2},
                        "text": {"signal": "tooltip.totalPriceWithVAT"},
                        "fillOpacity": [
                            {"test": "datum === tooltip", "value": 0},
                            {"value": 1}
                        ]
                    }
                }
            }
        ]
    };


    useEffect(() => {
        const dateFromUrl = encodeURIComponent(moment(dateFrom).tz("Europe/Podgorica").format());
        const dateToUrl = encodeURIComponent(moment(dateTo).tz("Europe/Podgorica").format());

        if (!!axiosInstance.current && itemId) {
            setData(null);
            dispatch(setIsLoading(true));
            axiosInstance.current.get(`report/subscriber/${subscriberId}/tcr/${tcrId}/item/${itemId}/_item_turnover?dateFrom=${dateFromUrl}&dateTo=${dateToUrl}&status=SUCCESS,STALLED`)
                .then(({data}) => {
                    if (data && data.byItemTurnover) {
                        setReportData({
                            totalQuantity: data.byItemTurnover.filtered.totalQuantity.value.toFixed(3),
                            totalInvoicesWithItem: data.byItemTurnover.filtered.doc_count,
                            totalAmountOfVAT: data.byItemTurnover.filtered.totalAmountOfVAT.value.toFixed(2),
                            totalPriceWithVAT: data.byItemTurnover.filtered.totalPriceWithVAT.value.toFixed(2),
                            totalPriceWithoutVAT: data.byItemTurnover.filtered.totalPriceWithoutVAT.value.toFixed(2),
                            totalDiscount: data.byItemTurnover.filtered.totalDiscount.value.toFixed(2)
                        })
                    }
                })
                .finally(() => {
                    dispatch(setIsLoading(false));
                });

        }
    }, [itemId, dateFrom, dateTo, axiosInstance, dispatch, subscriberId, tcrId]);

    useEffect(() => {
        const dateFromUrl = encodeURIComponent(moment(dateFrom).tz("Europe/Podgorica").format());
        const dateToUrl = encodeURIComponent(moment(dateTo).tz("Europe/Podgorica").format());

        if (!!axiosInstance.current && itemId) {
            dispatch(setIsLoading(true));
            setData(null);
            axiosInstance.current.get(`report/subscriber/${subscriberId}/tcr/${tcrId}/item/${itemId}/_item_turnover?dateFrom=${dateFromUrl}&dateTo=${dateToUrl}&status=SUCCESS,STALLED&calendarInterval=${calendarInterval}`)
                .then(({data}) => {
                    const table = data.timeRange.buckets.map(b => {
                        return {
                            timerange: b.key,
                            totalPriceWithVAT: b.byItemTurnover.filtered.totalPriceWithVAT.value.toFixed(2)
                        }
                    })
                    setData({
                        table
                    })
                })
                .finally(() => {
                    dispatch(setIsLoading(false));
                });
        }
    }, [itemId, dateFrom, dateTo, axiosInstance, dispatch, calendarInterval, subscriberId, tcrId]);

    return (
        <Grid container>
            <Grid item xs={12} sm={6} md={4}>
                <MuiPickersUtilsProvider locale="me" utils={MomentUtils}>
                    <Grid container>
                        <Grid item xs={6}>
                            <KeyboardDatePicker
                                autoOk
                                disableToolbar
                                size="small"
                                variant="inline"
                                inputVariant="outlined"
                                margin="normal"
                                id="date-picker-dialog-from"
                                label="Datum od"
                                format="DD.MM.yyyy."
                                value={moment(dateFrom)}
                                onChange={(value) => {
                                    setDateFrom(moment(value).startOf('day').valueOf())
                                }}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <KeyboardDatePicker
                                autoOk
                                disableToolbar
                                size="small"
                                variant="inline"
                                inputVariant="outlined"
                                margin="normal"
                                id="date-picker-dialog-to"
                                label="Datum do"
                                format="DD.MM.yyyy."
                                value={dateTo}
                                onChange={(value) => {
                                    setDateTo(moment(value).endOf('day').valueOf())
                                }}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </Grid>
                    </Grid>
                </MuiPickersUtilsProvider>
                <Grid container>
                    <Grid item xs={6} style={{padding: 5}}>
                        <Box p={3}>
                            <Card className={classes.root} variant="outlined">
                                <CardContent>
                                    <Typography variant="h4" component="h2">
                                        {reportData.totalQuantity}/{reportData.totalInvoicesWithItem}
                                    </Typography>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                                        Ukupna količina / Ukupno računa
                                    </Typography>

                                </CardContent>
                            </Card>
                        </Box>
                    </Grid>
                    <Grid item xs={6} style={{padding: 5}}>
                        <Box p={3}>
                            <Card className={classes.root} variant="outlined">
                                <CardContent>
                                    <Typography variant="h4" component="h2">
                                        {reportData.totalPriceWithVAT.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                                    </Typography>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                                        Ukupni iznos
                                    </Typography>

                                </CardContent>
                            </Card>
                        </Box>
                    </Grid>
                    <Grid item xs={6} style={{padding: 5}}>
                        <Box p={3}>
                            <Card className={classes.root} variant="outlined">
                                <CardContent>
                                    <Typography variant="h4" component="h2">
                                        {reportData.totalAmountOfVAT.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                                    </Typography>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                                        Ukupno poreza
                                    </Typography>

                                </CardContent>
                            </Card>
                        </Box>
                    </Grid>
                    <Grid item xs={6} style={{padding: 5}}>
                        <Box p={3}>
                            <Card className={classes.root} variant="outlined">
                                <CardContent>
                                    <Typography variant="h4" component="h2">
                                        {reportData.totalDiscount.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                                    </Typography>
                                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                                        Ukupno popusta
                                    </Typography>

                                </CardContent>
                            </Card>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={8}>
                <Grid container style={{textAlign: 'right'}}>
                    <Grid item xs={false} sm={false} md={9} lg={10}/>
                    <Grid item xs={12} sm={6} md={3} lg={2}>
                        <FormControl style={{minWidth: '100%'}}>
                            <InputLabel id="taxIdentifierType">Interval</InputLabel>
                            <Select
                                size="small"
                                style={{minWidth: '100%'}}
                                labelId="calendarInterval"
                                name="calendarInterval"
                                id="calendarInterval"
                                value={calendarInterval}
                                onChange={(event) => setCalendarInterval(event.target.value)}
                            >
                                {/*<MenuItem value={'1h'}>Sat</MenuItem>*/}
                                <MenuItem value={'1d'}>Dan</MenuItem>
                                <MenuItem value={'1w'}>Sedmica</MenuItem>
                                <MenuItem value={'1M'}>Mjesec</MenuItem>
                                <MenuItem value={'1q'}>Kvartal</MenuItem>
                                <MenuItem value={'1y'}>Godina</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} ref={graphContainerRef} style={{width: graphContainerRef.current ? graphContainerRef.current.clientWidth - 80 : 300}}>
                        {data && graphContainerRef && graphContainerRef.current &&
                        <Vega
                            spec={spec}
                            data={data}
                            actions={false}
                            width={graphContainerRef.current ? graphContainerRef.current.clientWidth - 80 : 300}
                        />}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}