import { v4 as uuidv4 } from 'uuid'

export class CreditNoteItemModel {

  constructor (item) {
    this.id = (item && item.id) || uuidv4()
    this.discountAmt = (item && item.discountAmt) || 0.0
    this.totalAmt = (item && item.totalAmt) || 0.0
    this.vatAmt = (item && item.vatAmt) || 0.0
    this.vatRate = (item && item.vatRate) || 0
    this.returnAmt = (item && item.returnAmt) || 0.0
    this.note = ""
    //this.exemptFromVAT = (item && item.exemptFromVAT) || 0.0
  }

  update (updatedInvoiceItem) {
    Object.assign(this, updatedInvoiceItem)
  }

}

