import React, { useCallback, useState } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, Divider,
  FormControl,
  FormControlLabel, FormLabel, Radio,
  RadioGroup, TextField
} from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import moment from 'moment'
import { useApi } from '../../api/api'
import MomentUtils from '@date-io/moment';
import "moment/locale/me";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

const initialValue = {
  ikof: '',
  type: 'CASH',
  issueDateTime: moment(),
  invOrdNum: '',
  softCode: "",
  tcrCode: "",
  totPrice: "",
  idNumber: "",
  businUnitCode: ""
}
const VerifyIKOFDialog = ({
  closeConfirm,
  open,
  subscriberId,
  tcr
}) => {

  const [value, setValue] = useState(initialValue)
  const api = useApi()

  const handleChange = (event) => {
    setValue({
      ...value,
      [event.target.name]: event.target.value
    })
  }

  const handleVerify = useCallback(() => {
    const data =  {
      "header": {
        "subscriberID": subscriberId,
        "tcrId": tcr.id,
        "certificateID": value.type === 'CASH' ? tcr.cacheInvoiceCert.id : tcr.noncacheInvoiceCert.id
      },
      "invoice": {
        "businUnitCode": value.businUnitCode,
        "issueDateTime": moment(value.issueDateTime).tz("Europe/Podgorica").format(),
        "invOrdNum": value.invOrdNum,
        "softCode": value.softCode,
        "tcrCode": value.tcrCode,
        "totPrice": value.totPrice,
        "seller": {
          "idNum": value.idNumber
        }
      }
    }

    api.postVerifyIKOF(subscriberId, tcr.id, data)
      .then(({ data }) => {
        setValue({
          ...value,
          ikof: data.iic
        })
      })
      .catch(err => {
        console.log(err);
      })
  }, [api, subscriberId, tcr.id, value])

  return (
    <Dialog
      disableBackdropClick
      open={open}
      style={{ minWidth: 600 }}
      maxWidth={'md'}
    >
      <DialogTitle>
        Verifikacija IKOF-a
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl component="fieldset" fullWidth>
              <FormLabel component="legend">Tip računa</FormLabel>
              <RadioGroup row aria-label="gender" name="type" value={value.type} onChange={handleChange}>
                <FormControlLabel value="CASH" control={<Radio/>} label="Gotovinski"/>
                <FormControlLabel value="NONCASH" control={<Radio/>} label="Bezgotovinski"/>
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <TextField
              id="idNumber"
              name="idNumber"
              label="PIB"
              variant="outlined"
              value={value.idNumber}
              onChange={handleChange}
              type="number"
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <MuiPickersUtilsProvider locale="me" utils={MomentUtils}>
              <Box p={1} flexGrow={0}>
                <KeyboardDatePicker
                  autoOk
                  disableToolbar
                  size="small"
                  variant="inline"
                  inputVariant="outlined"
                  margin="normal"
                  id="issueDateTime"
                  name="issueDateTime"
                  label="Datum i vrijeme izdavanja računa"
                  format="DD.MM.yyyy. HH:mm:ss"
                  //value={moment(dateFrom)}
                  value={value.issueDateTime}
                  onChange={(issueDateTime) => {
                    setValue({
                      ...value,
                      issueDateTime
                    })
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </Box>
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <TextField
              id="invOrdNum"
              name="invOrdNum"
              label="Broj računa"
              variant="outlined"
              value={value.invOrdNum}
              onChange={handleChange}
              type="number"
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <TextField
              id="businUnitCode"
              name="businUnitCode"
              label="Kod poslovnog prostora"
              variant="outlined"
              value={value.businUnitCode}
              onChange={handleChange}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <TextField
              id="tcrCode"
              name="tcrCode"
              label="Kod ENU"
              variant="outlined"
              value={value.tcrCode}
              onChange={handleChange}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <TextField
              id="softCode"
              name="softCode"
              label="Kod softvera"
              variant="outlined"
              value={value.softCode}
              onChange={handleChange}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <TextField
              id="totPrice"
              name="totPrice"
              label="Iznos"
              variant="outlined"
              type="number"
              value={value.totPrice}
              onChange={handleChange}
              fullWidth
              required
            />
          </Grid>
        </Grid>
        <br/>
        <Divider />
        <br/>
        <Grid container spacing={2}>
          <div style={{fontSize: 30, textAlign: 'center', width: '100%'}} >
            {value.ikof || "IKOF"}
          </div>
        </Grid>

      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={handleVerify}>
          Verifikuj IKOF
        </Button>
        <Button
          color="secondary"
          onClick={() => {
            setValue(initialValue)
          }}>
          Reset
        </Button>
        <div style={{ flex: '1 0 0' }}/>
        <Button
          color="default"
          onClick={() => {
            closeConfirm()
          }}>
          Zatvori
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default VerifyIKOFDialog
