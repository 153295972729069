import React from 'react';
import {Grid} from "@material-ui/core";
import Tile from "./Tile";
import {getCurrentRole, getDashboardReportData} from "../mainReducer";
import {useSelector} from "react-redux";
import {EuroSymbol} from "@material-ui/icons";
import ByStore from "./ByStore";
import {getTheme} from "../Setting/settingsReducer";
import Sales from "./Sales";

function OwnerDashboard() {
    const currentRole = useSelector(getCurrentRole)
    const dashboardReportData = useSelector(getDashboardReportData)
    const theme = useSelector(getTheme)

    return (

        <>
            {['OWNER', 'ACCOUNTANT'].includes(currentRole) && dashboardReportData  ?
                <Grid
                    container
                    spacing={3}
                >
                    <Grid item lg={3} sm={6} xl={3} xs={12}>
                        <Tile text={'POPUST'} value={dashboardReportData.totalDiscount}
                              color={theme.palette.primary.main}
                              icon={<EuroSymbol/>}/>
                    </Grid>
                    <Grid item lg={3} sm={6} xl={3} xs={12}>
                        <Tile text="UKUPNO BEZ PDV-A" value={dashboardReportData.totalPriceWithoutVAT}
                              color={theme.palette.primary.main}
                              icon={<EuroSymbol/>}/>
                    </Grid>
                    <Grid item lg={3} sm={6} xl={3} xs={12}>
                        <Tile text="UKUPNO POREZA" value={dashboardReportData.totalAmountOfVAT}
                              color={theme.palette.primary.main}
                              icon={<EuroSymbol/>}/>
                    </Grid>
                    <Grid item lg={3} sm={6} xl={3} xs={12}>
                        <Tile text="UKUPNO" value={dashboardReportData.totalPriceWithVAT}
                              color={theme.palette.primary.main}
                              icon={<EuroSymbol/>}/>
                    </Grid>
                    <Grid item lg={8} md={12} xl={9} xs={12}>
                        <Sales/>
                    </Grid>
                    {dashboardReportData && dashboardReportData.sumsByStores && dashboardReportData.sumsByStores.length > 0 &&
                        <Grid item lg={4} md={6} xl={3} xs={12}>
                            <ByStore sumsByStores={dashboardReportData.sumsByStores}/>
                        </Grid>}
                </Grid> : null}
        </>

    );
}

export default OwnerDashboard;