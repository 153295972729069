import * as React from 'react'
import {useEffect} from 'react'
import {Redirect, useLocation} from 'react-router-dom'

import {useKeycloak} from '@react-keycloak/web'

const LoginPage = () => {
    const location = useLocation();
    const currentLocationState = location.state || {
        from: {pathname: '/'},
    }

    const {keycloak} = useKeycloak();

    useEffect(() => {
        if (keycloak) {
            if (!keycloak?.authenticated) {
                keycloak.login();
            }
        }
    }, [keycloak]);

    if (keycloak?.authenticated)
        return <Redirect to={currentLocationState ? currentLocationState.from : "/"}/>

    return null;
}

export default LoginPage;
