import React from "react";
import ReactDOM from "react-dom";
import {Provider} from "react-redux";
import * as serviceWorker from './serviceWorker';
import {ReactKeycloakProvider} from '@react-keycloak/web';
import keycloak from './keycloak';
import App from "./App";
import store from "./store";
import './index.css';
import {setCurrentRole, setUser} from "./containers/mainReducer";
import jwt_decode from "jwt-decode";

/*const eventLogger = (event, error) => {
    console.log('onKeycloakEvent', event, error);
}*/

const allowedRoles = ["ADMIN", "OWNER", "ACCOUNTANT", "SELLER"];

const tokenLogger = (tokens) => {
    const token = jwt_decode(tokens.token);
    if (token) {
        const user = {
            auth_time: token.auth_time,
            jti: token.jti,
            sub: token.sub,
            nonce: token.nonce,
            roles: token.realm_access && token.realm_access.roles && token.realm_access.roles.length > 0 ? token.realm_access.roles.filter(r => allowedRoles.includes(r)) : [],
            name: token.name,
            preferred_username: token.preferred_username,
            given_name: token.given_name,
            family_name: token.family_name,
            ...(token?.realm_access?.roles ? {isSuperAdmin: token.realm_access.roles.includes("SUPER_ADMIN")} : {})
        }
        store.dispatch(setUser(user));
        if (user && user.roles.length === 1) {
            store.dispatch(setCurrentRole(user.roles[0]));
        }
    }
}

ReactDOM.render(
    // <React.StrictMode>
    <Provider store={store}>
        <ReactKeycloakProvider
            authClient={keycloak}
            //onEvent={eventLogger}
            onTokens={tokenLogger}
            autoRefreshToken={true}
        >
            <App/>
        </ReactKeycloakProvider>
    </Provider>
    // </React.StrictMode>
    ,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
