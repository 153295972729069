export class CertificateModel{

    constructor() {
        this.id = "";
        this.description = '';
        this.filePassword = '';
        this.fileMimeType = '';
        this.originalFileName = '';
        this.content = '';
        this.alias = "";
        this.details = {};
        this.publicKey = "";
    }

    update(updatedCertificate) {
        this.id = updatedCertificate.id || this.id;
        this.description = updatedCertificate.description || this.description;
        this.filePassword = updatedCertificate.filePassword || this.filePassword;
        this.fileMimeType = updatedCertificate.fileMimeType || this.fileMimeType;
        this.originalFileName = updatedCertificate.originalFileName || this.originalFileName;
        this.content = updatedCertificate.content || this.content;
        this.alias = updatedCertificate.alias || this.alias;
        this.details = updatedCertificate.details || this.details;
        this.publicKey = updatedCertificate.publicKey || this.publicKey;
    }

}

