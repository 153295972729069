import * as React from 'react';
import {
    Dialog,
    DialogTitle,
    Paper
} from "@material-ui/core";
import NewCertificate from "./NewCertificate";
import MaterialTable from "material-table";
import {localization, tableIcons} from "../../utils/material-table";
import {Add} from "@material-ui/icons";

export default function SubscriberCertificates({subscriberId, certificates, addCertificate}) {

    const columns = [
        {field: 'description', title: 'Namjena', width: 250},
        {field: 'originalFileName', title: 'Fajl', flex: 1},
        {field: 'issuer', title: 'Izdavac', width: 200},
        {field: 'validFrom', title: 'Validan od', width: 200},
        {field: 'validTo', title: 'Validan do', width: 200},
    ];

    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Paper elevation={3}>
            <MaterialTable
                title="Sertifikati"
                icons={tableIcons}
                data={certificates.map(c => {
                    return {
                        id: c.id,
                        description: c.description,
                        originalFileName: c.originalFileName,
                        issuer: c.details.issuer,
                        validFrom: c.details.validFrom,
                        validTo: c.details.validTo,
                    }
                })}
                localization={localization}
                options={{
                    pageSize: 10,
                    emptyRowsWhenPaging: true,
                    pageSizeOptions: [5, 10, 25],
                    actionsColumnIndex: -1,
                    exportButton: false
                }}
                columns={columns}
                actions={[
                    {
                        icon: () => <Add/>,
                        tooltip: 'Dodaj novi sertifikat',
                        isFreeAction: true,
                        onClick: () => setOpen(true)
                    }
                ]}
            />

            {open &&
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true}
                    maxWidth={'md'}>
                <DialogTitle id="form-dialog-title">Unos novog sertifikata</DialogTitle>
                <NewCertificate
                    handleClose={handleClose}
                    addCertificate={addCertificate}
                    subscriberId={subscriberId}
                />
            </Dialog>
            }
        </Paper>
    );
}
