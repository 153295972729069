import * as React from 'react';
import {
    Box, Button, DialogActions, DialogContent,
    Paper,
    TextField
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {useCallback, useState} from "react";
import {CertificateModel} from "../../models/CertificateModel";
import {setErrorSnackbar, setIsLoading, setSuccessSnackbar} from "../mainReducer";
import {useDispatch} from "react-redux";
import {useAxios} from "../../utils/hooks";

export default function NewCertificate({handleClose, subscriberId, addCertificate}) {

    const dispatch = useDispatch();
    const axiosInstance = useAxios();

    const [certificate, setCertificate] = useState(new CertificateModel());

    const handleChange = (event) => {
        setCertificate({
            ...certificate,
            [event.target.name]: event.target.value
        });
    };

    const handleFileAdd = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            let regex = /^data:.+\/(.+);base64,(.*)$/;
            let matches = reader.result.match(regex);
            setCertificate({
                ...certificate,
                fileMimeType: matches[1],
                content: matches[2],
                originalFileName: file.name
            })
        };
        reader.onerror = error => alert("Greška prilikom konvertovanja fajla!");
    }

    const postCertificate = useCallback(() => {
        dispatch(setIsLoading(true));
        if (!!axiosInstance.current) {
            axiosInstance.current.post(`/admin/subscriber/${subscriberId}/certificates`, certificate)
                .then(({data}) => {
                    addCertificate(data);
                    dispatch(setSuccessSnackbar("Sertifikat je sačuvan"));
                    handleClose();
                })
                .catch(err => {
                    console.log(err);
                    dispatch(setErrorSnackbar("Greška"));
                })
                .finally(() => {
                    dispatch(setIsLoading(false));
                });
        }
    }, [axiosInstance, dispatch, certificate, subscriberId, addCertificate]);
    
    return (
        <>
            <DialogContent>
                <Paper elevation={3}>
                    <Box p={2}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    id="description"
                                    name="description"
                                    label="Namjena"
                                    fullWidth
                                    value={certificate.description}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    id="filePassword"
                                    name="filePassword"
                                    label="Lozinka"
                                    fullWidth
                                    value={certificate.filePassword}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    label='Sertifikat'>
                                    <input
                                        name="keystores"
                                        accept="application/x-pkcs12"
                                        multiple={false}
                                        type="file"
                                        onChange={handleFileAdd}
                                    />
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant="outlined" color="primary">
                    Zatvori
                </Button>
                <Button onClick={postCertificate} variant="contained" color="primary">
                    Sačuvaj
                </Button>
            </DialogActions>
        </>
    );
}
